import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { ApiService } from '../app.api-service';
import { Observable } from "rxjs";
import { ModuleService } from '../services/module-service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  modules: any[];
  user: any;

  constructor(private app: AppComponent, private router: Router, private moduleService: ModuleService) { }

  private _opened: boolean = false;
  private _modeNum: number = 2;
  private _positionNum: number = 0;
  private _dock: boolean = false;
  private _closeOnClickOutside: boolean = true;
  private _closeOnClickBackdrop: boolean = false;
  private _showBackdrop: boolean = false;
  private _animate: boolean = true;
  private _trapFocus: boolean = true;
  private _autoFocus: boolean = true;
  private _keyClose: boolean = false;
  private _autoCollapseHeight: number = null;
  private _autoCollapseWidth: number = null;
  public _page: string = 'Painel de Controle';

  ngOnInit() {
    this.app.isPrivate = true;
  }

  checkCEIP(){
    return window.location.pathname.indexOf('ceip') > 0;
  }

  logout() {
    this.app.isPrivate = false;
    this.router.navigateByUrl('/');
    //sair
  }

  private _toggleOpened(): void {
    this._opened = !this._opened;
  }

  private _toggleAutoCollapseHeight(): void {
    this._autoCollapseHeight = this._autoCollapseHeight ? null : 500;
  }

  private _toggleAutoCollapseWidth(): void {
    this._autoCollapseWidth = this._autoCollapseWidth ? null : 500;
  }

  private _toggleDock(): void {
    this._dock = !this._dock;
  }

  private _toggleCloseOnClickOutside(): void {
    this._closeOnClickOutside = !this._closeOnClickOutside;
    this._opened = this._closeOnClickOutside;
  }

  private _toggleCloseOnClickBackdrop(): void {
    this._closeOnClickBackdrop = !this._closeOnClickBackdrop;
  }

  private _toggleShowBackdrop(): void {
    this._showBackdrop = !this._showBackdrop;
  }

  private _toggleAnimate(): void {
    this._animate = !this._animate;
  }

  private _toggleTrapFocus(): void {
    this._trapFocus = !this._trapFocus;
  }

  private _toggleAutoFocus(): void {
    this._autoFocus = !this._autoFocus;
  }

  private _toggleKeyClose(): void {
    this._keyClose = !this._keyClose;
  }

  private _onOpenStart(): void {
    // console.info('Sidebar opening');
  }

  private _onOpened(): void {
    // console.info('Sidebar opened');
  }

  private _onCloseStart(): void {
    // console.info('Sidebar closing');
  }

  private _onClosed(): void {
    this._opened = false;
    // console.info('Sidebar closed');
  }

  private _onTransitionEnd(): void {
    // console.info('Transition ended');
  }

  loadModules() {
    this.getUserLogged();
    this.moduleService.getModulesByUserID().subscribe((modules) => {
      if (modules.status) {
        var moduleAllowed = modules.list.filter(mod => {
          return mod.url == window.location.pathname;
        })
        if (moduleAllowed.length == 0)
          this.logout();
          
        this.modules = modules.list;
      } else {
        alert('SEM ACESSO!');
        this.logout();
      }
    })
  }

  getUserLogged() {
    this.user = JSON.parse(localStorage.getItem('ancp-authentication'));
    if (!this.user) {
      alert('SEM ACESSO!');
      this.logout();
    } else
      this.user = this.user.user;
  }

  openPage(module){
    window.location.href = module.url;
  }
}
