import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class ApiService {
  // URL = 'http://157.245.245.110:8090';
  //URL = 'https://ceip-teste.ecolog.com.br';
  //URL = 'https://ceip-teste.ecolog.com.br';
  URL = 'http://localhost:3015';

  constructor(private http: HttpClient) {

  }

}
