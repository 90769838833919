import { Component, OnInit } from '@angular/core';
import { ApiService } from '../app.api-service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { UserService } from '../services/user-service';
import { FarmerService } from '../services/farmer-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ceip-fazendas',
  templateUrl: './ceip-fazendas.component.html',
  styleUrls: ['./ceip-fazendas.component.css']
})
export class CEIPFazendasComponent implements OnInit {

  fazendas: any[] = [{
    NFA: '',
    NOME_FANTASIA: 'Carregando..'
  }];
  categorias: any[] = [{
    CATEGORIA_ID: '',
    DS_CATEGORIA: 'Carregando..'
  }];
  fazenda: any = '';
  categoria: any;

  constructor(private farmerService: FarmerService, private router: Router,
    private app: AppComponent) {

  }

  ngOnInit() {
    this.app.isPrivate = true;
    this.buscarFazendas();
    this.buscarCategorias();
  }

  buscarFazendas() {
    this.app.loading = true;
    this.farmerService.retornarFazendasCeip().subscribe((data: any[]) => {
      this.fazendas = data;
      this.app.loading = false;
    }, (erro) => {
      alert(erro);
      this.app.loading = false;
    })
  }

  buscarCategorias() {
    this.farmerService.retornarCategorias().subscribe((data: any[]) => {
      this.categorias = data;
      console.log(this.categorias);
    }, (erro) => {
      alert(erro);
    })
  }

  buscar() {
    debugger;
    //Check if the user selected ALL
    if (!Array.isArray(this.fazenda))
      this.fazenda = [];
    if (!Array.isArray(this.categoria))
      this.categoria = [];

    var fazendaAll = this.fazenda.filter((data) => {
      return data.NFA == 'all';
    });
    var categoriaAll = this.categoria.filter((data) => {
      return data.CEIP == 'all';
    });
    if (this.categoria.length == 0 && categoriaAll.length == 0) {
      alert('Seleção do CEIP é obrigatório');
    } else {
      if (this.fazenda.length == 0 && fazendaAll.length == 0) {
        alert('Seleção da Fazenda é obrigatório');
      } else {
        if (fazendaAll.length > 0) {
          this.fazenda = this.fazendas.map(function (item) { return { NFA: item.NFA, NOME: item.NOME_FANTASIA } });
          localStorage.setItem('solicitar-fazendas-todas', JSON.stringify(this.fazenda));
        } else {
          if (!this.fazenda) {
            this.fazenda = this.fazendas.map(function (item) { return { NFA: item.NFA, NOME: item.NOME_FANTASIA } });
            localStorage.setItem('solicitar-fazendas-todas', JSON.stringify(this.fazenda));
          } else {
            this.fazenda = this.fazenda.map(function (item) { return { NFA: item.NFA, NOME: item.NOME_FANTASIA } });
          }
        }

        if (categoriaAll.length > 0) {
          this.categoria = this.categorias.map(function (item) { return item.CEIP });
        }

        localStorage.setItem('solicitar-fazendas', JSON.stringify(this.fazenda));
        localStorage.setItem('solicitar-categorias', JSON.stringify(this.categoria));
        this.router.navigate(['/ceip-animais-aptos']);
      }
    }
  }

  voltar() {
    var isFazenda = localStorage.getItem('ancp-isFazenda');
    if (isFazenda) {
      window.location.href = 'http://ancp.org.br';
    } else {
      this.router.navigate(['/']);
    }
  }

}
