import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { FarmerService } from '../services/farmer-service';
import { AnimalService } from '../services/animal-service';
import { ToastrService } from 'ngx-toastr';
import { ModalManager } from 'ngb-modal';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-ceip-avaliador-animais',
  templateUrl: './ceip-avaliador-animais.component.html',
  styleUrls: ['./ceip-avaliador-animais.component.css']
})
export class CEIPAvaliadorAnimaisComponent implements OnInit {

  animais: any[] = [];
  animaisSolicitados: any[];
  animaisTotal: any[];
  animaisFiltrados: any[];
  fazenda: any = '';
  fazendas: any[] = [];
  categoria: any = '';
  page: any = 1;
  pageSize: any = 10;
  search: string = '';
  showTable: boolean = false;
  view: string = 'aptos';
  showFilter: boolean = false;
  filtroAvancado: any = {
    rgnregra: 'Igual',
    rgdregra: 'Igual',
    dataNascimentoregra: 'Igual',
    mgtregra: 'Igual',
    topregra: 'Igual',
    sexo: 'm',
    raca: '',
    variedade: '',
    categoria: '',
    nome_fantasia: '',
    LOGIN: ''
  };
  filtrado: boolean = false;
  regras: string[] = [
    'Igual',
    'Menor',
    'Maior'
  ]
  atualCampoOrdem: string = '';
  contadorOrdem: number = 0;
  nomeAG: string = '';

  //selects filtro avançado
  filtroSexos: any[] = [
    { key: 'Macho', value: 'Macho' },
    { key: 'femea', value: 'Fêmea' },
    { key: 'm', value: 'Ambos' },
  ]
  filtroRacas: any[] = [];
  filtroCategorias: any[] = [];
  filtroFazendas: any[] = [];
  filtroVariedades: any[] = [];
  filtroSolicitantes: any[] = [];
  selecionouTodas: boolean = false;
  filtroAprovadores: any[] = [];
  filtroRacasSolicitado: any[] = [];
  filtroCategoriasSolicitado: any[] = [];
  filtroVariedadesSolicitado: any[] = [];
  filtroFazendasSolicitado: any[] = [];
  filtroSolicitantesSolicitado: any[] = [];
  filtroAprovadoresSolicitado: any[] = [];
  dateDefault: any;
  p: number = 0;

  @ViewChild('myModal') myModal;
  private modalRef;

  keysObject: any = {
    aptos: {
      keys: [
        'NOME', 'Serie', 'RGN', 'RGD', 'DataNascimento', 'MGT', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raca', 'Sexo', 'LOGIN', 'DataSolicitacao', 'DataLimite'],
      header: [
        'Nome', 'Série', 'RGN', 'RGD', 'DT Nasc', 'MGTe', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raça', 'Sexo', 'Solicitante', 'Data da Solicitação', 'Limite Emissão']
    },
    solicitados: {
      keys: [
        'NOME', 'Serie', 'RGN', 'RGD', 'DataNascimento', 'MGT', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raca', 'Sexo', 'LOGIN', 'DataSolicitacao', 'LOGINAPROVADOR', 'DataVistoria', 'DataLimite'],
      header: [
        'Nome', 'Série', 'RGN', 'RGD', 'DT Nasc', 'MGTe', 'TOP',
        'Categ.', 'Varied.', 'NFA', 'Raça', 'Sexo', 'Solicitante', 'Data da Solicitação', 'Consultor', 'Data da Vistoria', 'Limite Emissão']
    }
  };

  colunasApto: any[] = [
    { key: 'Nome', value: false, field: 'NOME', width: 7 }, { key: 'Série', value: false, field: 'Serie', width: 5 }, { key: 'RGN', value: false, field: 'RGN', width: 5 }, { key: 'RGD', value: false, field: 'RGD', width: 5 },
    { key: 'DT Nasc', value: false, field: 'DataNascimento', width: 8 }, { key: 'MGTe', value: false, field: 'MGT', width: 5 }, { key: 'TOP', value: false, field: 'TOP', width: 5 },
    { key: 'Categoria', value: false, field: 'Categoria', width: 7 }, { key: 'Variedade', value: false, field: 'Variedade', width: 8 }, 
    { key: 'NFA', value: false, field: 'NFA', width: 4 }, { key: 'Raça', value: false, field: 'Raca', width: 5 }, { key: 'Sexo', value: false, field: 'Sexo', width: 5 }, 
    { key: 'Solicitante', value: false, field: 'LOGIN', width: 8 }, { key: 'Data da Solicitação', value: false, field: 'DataSolicitacao', width: 8 }, { key: 'Limite Emissão', value: false, field: 'DataLimite', width: 8 }];
  colunasSolicitados: any[] = [
    { key: 'Nome', value: false, field: 'NOME', width: 7 }, { key: 'Série', value: false, field: 'Serie', width: 5 }, { key: 'RGN', value: false, field: 'RGN', width: 5 }, { key: 'RGD', value: false, field: 'RGD', width: 5 },
    { key: 'DT Nasc', value: false, field: 'DataNascimento', width: 8 }, { key: 'MGTe', value: false, field: 'MGT', width: 5 }, { key: 'TOP', value: false, field: 'TOP', width: 4 },
    { key: 'Categ.', value: false, field: 'Categoria', width: 5 }, { key: 'Varied.', value: false, field: 'Variedade', width: 6 }, 
    { key: 'NFA', value: false, field: 'NFA', width: 4 }, { key: 'Raça', value: false, field: 'Raca', width: 5 }, { key: 'Sexo', value: false, field: 'Sexo', width: 5 }, 
    { key: 'Solicitante', value: false, field: 'LOGIN', width: 8 }, { key: 'Data da Solicitação', value: false, field: 'DataSolicitacao', width: 8 },
    { key: 'Consultor', value: false, field: 'LOGINAPROVADOR', width: 8 }, { key: 'Data da Vistoria', value: false, field: 'DataVistoria', width: 8 }, { key: 'Limite Emissão', value: false, field: 'DataLimite', width: 8 }];

  hasNoCL: boolean = false;
  hasCL: boolean = false;
  constructor(private animalService: AnimalService, private toastr: ToastrService,
    private app: AppComponent, private route: ActivatedRoute, private modalService: ModalManager) {

  }

  ngOnInit() {
    this.app.isPrivate = true;

    this.fazendas = JSON.parse(localStorage.getItem('avaliador-fazendas'));
    var todasFazendas = JSON.parse(localStorage.getItem('avaliador-fazendas-todas'));
    this.selecionouTodas = todasFazendas == null ? false : this.fazendas.length == todasFazendas.length;

    this.fazenda = this.fazendas.map(function (item) { return item.NFA; });
    this.categoria = JSON.parse(localStorage.getItem('avaliador-categorias'));
    this.validarFazenda();
    this.buscarNomeAG();
  }

  openModal(type) {
    this.colunasApto.forEach(item => {
      item.value = false;
    });
    this.colunasSolicitados.forEach(item => {
      item.value = false;
    });
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }
  closeModal() {
    this.modalService.close(this.modalRef);
  }

  filtrarPDF() {
    var itemsChecked = this.view == 'aptos' ? this.colunasApto.filter(ani => {
      return ani.value;
    }) : this.colunasSolicitados.filter(ani => {
      return ani.value;
    });
    
      //Agrupar
      var helper = {};
      let result = itemsChecked.map(a => a.key);
      let name = '';
      var animals = [];
      //Aptos não filtrados
      if (this.filtrado) {
        animals = this.animaisFiltrados;
        name = 'animais-aptos-ceip-';
      } else {
        if (this.view == 'aptos') {
          var animalsSelected = this.animais.filter(ani => {
            return ani.checked;
          });
          animals = (animalsSelected.length > 0) ? animalsSelected : this.animais;
          name = 'animais-aptos-ceip-';
        } else {
          animals = this.animaisSolicitados;
          name = 'animais-solicitados-ceip-';
        }
      }


      var result2 = animals.reduce(function (r, o) {
        var title = '';
        itemsChecked.forEach(element => {
          if (element.field.includes('Data')) {
            var date = new Date(o[element.field]);
            let month = String(date.getMonth() + 1);
            let day = String(date.getDate());
            const year = String(date.getFullYear());

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            title = title + `${title == '' ? '' : ' > '} ${element.key}: ${day}/${month}/${year}`;
          } else if (element.field.includes('NFA')) {
            title = title + `${title == '' ? '' : ' > '} ${element.key}: ${o[element.field]} - ${o['nome_fantasia']}`;
          } else
            title = title + `${title == '' ? '' : ' > '} ${element.key}: ${o[element.field]}`;
        });

        if (!helper[title]) {
          var items = []
          items.push(o);
          helper[title] = items;
          r.push(helper[title]);
        } else {
          helper[title].push(o);
        }
        return r;
      }, []);

      this.printEditable(helper, result, name);
  }

  checkItem(item, value) {
    return item == value;
  }

  start = 10;
  printEditable(animals, keysSelected, name) {

    this.app.loading = true;
    setTimeout(() => {

      const doc = new jsPDF('l');
      var titles = Object.keys(animals);

      var indexToSkip = []
      keysSelected.forEach(key => {
        if (this.view == 'aptos')
          indexToSkip.push(this.colunasApto.map(function (e) { return e.key; }).indexOf(key));
        else
          indexToSkip.push(this.colunasSolicitados.map(function (e) { return e.key; }).indexOf(key));
      });


      var removeSolicitacao = false;
      var removeVistoria = false;

      var start = 10;
      titles.forEach(title => {
        var allvalues = [];

        animals[title].forEach(animal => {
          var values = [];
          var countValue = 0;
          if (this.view == 'aptos') {
            this.keysObject.aptos.keys.forEach(key => {
              var itCan = indexToSkip.filter(item => item == countValue);
              if (itCan.length == 0) {
                if (key.includes('LOGIN')) {
                  var date = new Date(animal['DataSolicitacao']);
                  let month = String(date.getMonth() + 1);
                  let day = String(date.getDate());
                  const year = String(date.getFullYear());

                  if (month.length < 2) month = '0' + month;
                  if (day.length < 2) day = '0' + day;
                  values.push(animal[key] + '\n' + `${day}/${month}/${year}`);
                  removeSolicitacao = true;
                } else if (key.includes('Data')) {
                  if (key.includes('DataSolicitacao') && removeSolicitacao)
                    console.log();
                  else {
                    var date = new Date(animal[key]);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(`${day}/${month}/${year}`);
                  }
                }
                else if (key.includes('MGT')) {

                  values.push(parseFloat(animal[key]).toFixed(2));
                } else
                  values.push(animal[key]);
              } else {
                if (key == 'LOGIN')
                  removeSolicitacao = true;
              }
              countValue = countValue + 1;
            });
          } else {
            this.keysObject.solicitados.keys.forEach(key => {

              var itCan = indexToSkip.filter(item => item == countValue);
              if (itCan.length == 0) {
                if (key.includes('LOGIN')) {
                  if (key.includes('LOGINAPROVADOR')) {
                    var date = new Date(animal['DataVistoria']);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(animal[key] + '\n' + `${day}/${month}/${year}`);
                    removeVistoria = true;
                  } else {
                    var date = new Date(animal['DataSolicitacao']);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(animal[key] + '\n' + `${day}/${month}/${year}`);
                    removeSolicitacao = true;
                  }
                } else if (key.includes('Data')) {
                  if ((key.includes('DataVistoria') && removeVistoria) || (key.includes('DataSolicitacao') && removeSolicitacao))
                    console.log();
                  else {
                    var date = new Date(animal[key]);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(`${day}/${month}/${year}`);
                  }
                }
                else if (key.includes('MGT')) {

                  values.push(parseFloat(animal[key]).toFixed(2));
                } else
                  values.push(animal[key]);
              } else {
                if (key == 'LOGIN')
                  removeSolicitacao = true;
                if (key == 'LOGINAPROVADOR')
                  removeVistoria = true;
              }
              countValue = countValue + 1;
            });
          }

          allvalues.push(values);

        });

        // if (doc.lastAutoTable){
        //   doc.addPage();
        // }

        var realHeader = [];
        var countKey = 0;
        if (this.view == 'aptos')
          this.keysObject.aptos.header.forEach(header => {
            var itCan = indexToSkip.filter(item => item == countKey);
            if (itCan.length == 0 && header != 'Data da Solicitação') {
              if (header.includes('MGT'))
                header = this.hasNoCL ? this.hasCL ? header + ' / IGE' : header : this.hasCL ? 'IGE' : '';

              realHeader.push(header);
            }

            countKey = countKey + 1
          })
        else
          this.keysObject.solicitados.header.forEach(header => {
            var itCan = indexToSkip.filter(item => item == countKey);
            if (itCan.length == 0 && header != 'Data da Solicitação' && header != 'Data da Vistoria') {
              if (header.includes('MGT'))
                header = this.hasNoCL ? this.hasCL ? header + ' / IGE' : header : this.hasCL ? 'IGE' : '';

              realHeader.push(header);
            }

            countKey = countKey + 1
          })

        var object = {};
        var totalPixel = 280;
        var totalColunas = 15;
        var colunasRestantes = totalColunas - realHeader.length;
        var totalPixelUtilizado = 0;
        realHeader.forEach((element, index) => {
          var item: any;
          if (element == 'IGE')
            element = 'MGTe';

          if (this.view == 'aptos')
            item = this.colunasApto.filter(item => item.key == element)[0];
          else
            item = this.colunasSolicitados.filter(item => item.key == element)[0];

          item['total'] = item.width * totalPixel / 100;
          totalPixelUtilizado = totalPixelUtilizado + item['total'];
        });
        var totalPixelRestante = totalPixel - totalPixelUtilizado;
        var contadorFinal = 0;
        realHeader.forEach((element, index) => {

          var item: any;
          if (element == 'IGE')
            element = 'MGTe';


          if (this.view == 'aptos')
            item = this.colunasApto.filter(item => item.key == element)[0];
          else
            item = this.colunasSolicitados.filter(item => item.key == element)[0];
          var total = 0;
          if (colunasRestantes > 0) {
            var total = item.width * totalPixelRestante / 100;
            contadorFinal = contadorFinal + total;
            item['total'] = total + item['total'];
          }


          object[index] = { cellWidth: item['total'] };

        });
        console.log(`Utilizado: ${totalPixelUtilizado} + Restante: ${contadorFinal} = ${totalPixelUtilizado + contadorFinal}`);
        // 0: { cellWidth: 15 }, 1: { cellWidth: 13 }, 2: { cellWidth: 13 }, 3: { cellWidth: 13 }, 4: { cellWidth: 22 },
        // 5: { cellWidth: 15 }, 6: { cellWidth: 15 }, 7: { cellWidth: 15 }, 8: { cellWidth: 15 }, 10: { cellWidth: 20 },
        // 9: { cellWidth: 20 }, 11: { cellWidth: 20 }, 12: { cellWidth: 22 }, 15: { cellWidth: 20 }, 13: { cellWidth: 22 }, 14: { cellWidth: 22 }

        this.start = doc.lastAutoTable ? doc.lastAutoTable.cursor.y + 10 : 10;

        doc.text(15, this.start, title ? title : '');
        this.start = this.start + 5;
        doc.autoTable({
          head: [realHeader],
          body: allvalues,
          startY: this.start,
          columnStyles: object,
          margin: { left: 10 }
        })
      });
      doc.save(name + new Date().toISOString().slice(0, 10) + '.pdf');
      this.app.loading = false;
    }, 2000)
  }

  selecionarDateDefault() {
    this.animais.filter(ani => {
      return ani.checked;
    }).forEach(ani => {
      ani.dataVistoria = this.dateDefault;
    });
    console.log(this.dateDefault);
  }

  setarDataDefault(animal) {
    animal.dataVistoria = this.dateDefault;
  }

  cancelarAprovacao(animal) {
    this.toastr.info(`Cancelando aprovação do animal ${animal.NOME}..`);
    this.animalService.cancelarAprovacaoAnimal(animal.CGA).subscribe((data: any) => {
      this.toastr.success(`Aprovação cancelada com sucesso.`);
      this.buscarAnimais();
    }, error => {
      console.log(error);
      this.toastr.error(error.error ? error.error : `Não foi possível cancelar aprovação`);
    })
  }

  buscarNomeAG() {
    this.animalService.retornarNomeAG().subscribe((data: any) => {
      this.nomeAG = data.toUpperCase();
    })
  }

  validarFazenda() {
    //TBD: Validar se essa fazenda está liberada para o usuario logado
    this.buscarAnimais();
  }

  captureScreen() {
    this.showTable = true;
    this.app.loading = true;
    setTimeout(() => {
      const doc = new jsPDF('l');
      if (this.view == 'aptos') {
        if (this.filtrado) {
          doc.autoTable({
            html: '#contentToConvert1', columnStyles: {
              0: { cellWidth: 35 }, 10: { cellWidth: 25 }
            }
          });
        } else {
          doc.autoTable({
            html: '#contentToConvert', columnStyles: {
              0: { cellWidth: 35 }, 10: { cellWidth: 25 }
            }
          });
        }
      }
      else
        doc.autoTable({
          html: '#contentToConvert2', margin: { left: 3, right: 3 }, columnStyles: {
            0: { cellWidth: 15 }, 1: { cellWidth: 13 }, 2: { cellWidth: 13 }, 3: { cellWidth: 13 }, 4: { cellWidth: 22 },
            5: { cellWidth: 15 }, 6: { cellWidth: 15 }, 7: { cellWidth: 15 }, 8: { cellWidth: 15 }, 10: { cellWidth: 20 },
            9: { cellWidth: 20 }, 11: { cellWidth: 20 }, 12: { cellWidth: 22 }, 15: { cellWidth: 20 }, 13: { cellWidth: 22 }, 14: { cellWidth: 22 }
          }
        });

      doc.save('animais-ceip-' + new Date().toISOString().slice(0, 10) + '.pdf');

      setTimeout(() => {
        this.showTable = false;
        this.app.loading = false;
      }, 2000);
    }, 2000)

  }

  captureScreen2() {
    this.showTable = true;
    this.app.loading = true;
    setTimeout(() => {
      const doc = new jsPDF('l');
      doc.autoTable({
        html: '#contentToConvertSelecionados', columnStyles: {
          0: { cellWidth: 35 }, 10: { cellWidth: 25 }
        }
      });

      doc.save('animais-ceip-selecionados-' + new Date().toISOString().slice(0, 10) + '.pdf');

      setTimeout(() => {
        this.showTable = false;
        this.app.loading = false;
      }, 2000);
    }, 2000)

  }

  filtrar() {
    console.log(this.search);
    this.animaisFiltrados = this.animais.filter(ani => {
      return ani.NOME.toLowerCase().includes(this.search.toLowerCase()) || ani.RGN.toLowerCase().includes(this.search.toLowerCase());
    });
    this.filtrado = true;
    this.p = 0;
  }

  limparFiltroAvancado() {
    this.filtroAvancado = {
      rgnregra: 'Igual',
      rgdregra: 'Igual',
      dataNascimentoregra: 'Igual',
      mgtregra: 'Igual',
      topregra: 'Igual',
      sexo: 'm',
      raca: '',
      variedade: '',
      categoria: '',
      nome_fantasia: '',
      LOGIN: ''
    };
    this.showFilter = false;
    this.animaisFiltrados = [];
    this.filtrado = false;
    this.p = 0;
  }

  filtrarAvancado() {
    if (this.view == 'aptos') {
      this.animaisFiltrados = this.animais.filter(ani => {
        return ani.NOME.toLowerCase().includes(this.filtroAvancado.nome ? this.filtroAvancado.nome.toLowerCase() : '') &&
          ani.Serie.toLowerCase().includes(this.filtroAvancado.serie ? this.filtroAvancado.serie.toLowerCase() : '') &&
          ani.RGN.toLowerCase().includes(this.filtroAvancado.rgn ? this.filtroAvancado.rgn.toLowerCase() : '') &&
          // (this.filtroAvancado.rgnregra == 'Igual' && this.filtroAvancado.rgn ? parseInt(ani.RGN) == parseInt(this.filtroAvancado.rgn) :
          //   this.filtroAvancado.rgnregra == 'Maior' && this.filtroAvancado.rgn ? parseInt(ani.RGN) > parseInt(this.filtroAvancado.rgn) :
          //     this.filtroAvancado.rgnregra == 'Menor' && this.filtroAvancado.rgn ? parseInt(ani.RGN) < parseInt(this.filtroAvancado.rgn) :
          //       ani.RGN.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.rgdregra == 'Igual' && this.filtroAvancado.rgd ? parseInt(ani.RGD) == parseInt(this.filtroAvancado.rgd) :
            this.filtroAvancado.rgdregra == 'Maior' && this.filtroAvancado.rgd ? parseInt(ani.RGD) > parseInt(this.filtroAvancado.rgd) :
              this.filtroAvancado.rgdregra == 'Menor' && this.filtroAvancado.rgd ? parseInt(ani.RGD) < parseInt(this.filtroAvancado.rgd) :
                ani.RGD.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.dataNascimentoregra == 'Igual' ?
            ani.dataFormatada.toString().toLowerCase().includes(this.filtroAvancado.dataNascimento ? this.filtroAvancado.dataNascimento.toLowerCase() : '') :
            this.filtroAvancado.dataNascimento == 'Maior' ? ani.dataFormatada >= this.filtroAvancado.dataNascimento :
              this.filtroAvancado.dataNascimento == 'Menor' ? ani.dataFormatada <= this.filtroAvancado.dataNascimento :
                ani.dataFormatada.toString().toLowerCase().includes('')) &&
          // ani.DataNascimento.toLowerCase().includes(this.filtroAvancado.dataNascimento && this.filtroAvancado.dataNascimentoregra == 'Igual' ? this.filtroAvancado.dataNascimento.toLowerCase() : '') &&

          (this.filtroAvancado.mgtregra == 'Igual' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) == parseFloat(this.filtroAvancado.mgt) :
            this.filtroAvancado.mgtregra == 'Maior' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) > parseFloat(this.filtroAvancado.mgt) :
              this.filtroAvancado.mgtregra == 'Menor' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) < parseFloat(this.filtroAvancado.mgt) :
                ani.MGT.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.topregra == 'Igual' && this.filtroAvancado.top ? parseFloat(ani.TOP) == parseFloat(this.filtroAvancado.top) :
            this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top ? parseFloat(ani.TOP) > parseFloat(this.filtroAvancado.top) :
              this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top ? parseFloat(ani.TOP) < parseFloat(this.filtroAvancado.top) :
                ani.TOP.toString().toLowerCase().includes('')) &&
          ani.Sexo.toLowerCase().includes(this.filtroAvancado.sexo ? this.filtroAvancado.sexo.toLowerCase() : '') &&
          ani.Raca.toLowerCase().includes(this.filtroAvancado.raca ? this.filtroAvancado.raca.toLowerCase() : '') &&
          ani.Categoria.toLowerCase().includes(this.filtroAvancado.categoria ? this.filtroAvancado.categoria.toLowerCase() : '') &&
          ani.nome_fantasia.toLowerCase().includes(this.filtroAvancado.nome_fantasia ? this.filtroAvancado.nome_fantasia.toLowerCase() : '') &&
          ani.Variedade.toLowerCase().includes(this.filtroAvancado.variedade ? this.filtroAvancado.variedade.toLowerCase() : '') &&
          ani.LOGIN.toLowerCase().includes(this.filtroAvancado.LOGIN ? this.filtroAvancado.LOGIN.toLowerCase() : '');
      });
      if (this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? 1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? -1 : 0))
      }
      if (this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? -1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? 1 : 0))
      }
    } else {
      this.animaisFiltrados = this.animaisSolicitados.filter(ani => {
        return ani.NOME.toLowerCase().includes(this.filtroAvancado.nome ? this.filtroAvancado.nome.toLowerCase() : '') &&
          ani.Serie.toLowerCase().includes(this.filtroAvancado.serie ? this.filtroAvancado.serie.toLowerCase() : '') &&
          ani.RGN.toLowerCase().includes(this.filtroAvancado.rgn ? this.filtroAvancado.rgn.toLowerCase() : '') &&
          // (this.filtroAvancado.rgnregra == 'Igual' && this.filtroAvancado.rgn ? parseInt(ani.RGN) == parseInt(this.filtroAvancado.rgn) :
          //   this.filtroAvancado.rgnregra == 'Maior' && this.filtroAvancado.rgn ? parseInt(ani.RGN) > parseInt(this.filtroAvancado.rgn) :
          //     this.filtroAvancado.rgnregra == 'Menor' && this.filtroAvancado.rgn ? parseInt(ani.RGN) < parseInt(this.filtroAvancado.rgn) :
          //       ani.RGN.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.rgdregra == 'Igual' && this.filtroAvancado.rgd ? parseInt(ani.RGD) == parseInt(this.filtroAvancado.rgd) :
            this.filtroAvancado.rgdregra == 'Maior' && this.filtroAvancado.rgd ? parseInt(ani.RGD) > parseInt(this.filtroAvancado.rgd) :
              this.filtroAvancado.rgdregra == 'Menor' && this.filtroAvancado.rgd ? parseInt(ani.RGD) < parseInt(this.filtroAvancado.rgd) :
                ani.RGD.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.dataNascimentoregra == 'Igual' ?
            ani.dataFormatada.toString().toLowerCase().includes(this.filtroAvancado.dataNascimento ? this.filtroAvancado.dataNascimento.toLowerCase() : '') :
            this.filtroAvancado.dataNascimento == 'Maior' ? ani.dataFormatada >= this.filtroAvancado.dataNascimento :
              this.filtroAvancado.dataNascimento == 'Menor' ? ani.dataFormatada <= this.filtroAvancado.dataNascimento :
                ani.dataFormatada.toString().toLowerCase().includes('')) &&
          // ani.DataNascimento.toLowerCase().includes(this.filtroAvancado.dataNascimento && this.filtroAvancado.dataNascimentoregra == 'Igual' ? this.filtroAvancado.dataNascimento.toLowerCase() : '') &&

          (this.filtroAvancado.mgtregra == 'Igual' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) == parseFloat(this.filtroAvancado.mgt) :
            this.filtroAvancado.mgtregra == 'Maior' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) > parseFloat(this.filtroAvancado.mgt) :
              this.filtroAvancado.mgtregra == 'Menor' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) < parseFloat(this.filtroAvancado.mgt) :
                ani.MGT.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.topregra == 'Igual' && this.filtroAvancado.top ? parseFloat(ani.TOP) == parseFloat(this.filtroAvancado.top) :
            this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top ? parseFloat(ani.TOP) > parseFloat(this.filtroAvancado.top) :
              this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top ? parseFloat(ani.TOP) < parseFloat(this.filtroAvancado.top) :
                ani.TOP.toString().toLowerCase().includes('')) &&
          ani.Sexo.toLowerCase().includes(this.filtroAvancado.sexo ? this.filtroAvancado.sexo.toLowerCase() : '') &&
          ani.Raca.toLowerCase().includes(this.filtroAvancado.raca ? this.filtroAvancado.raca.toLowerCase() : '') &&
          ani.Categoria.toLowerCase().includes(this.filtroAvancado.categoria ? this.filtroAvancado.categoria.toLowerCase() : '') &&
          ani.nome_fantasia.toLowerCase().includes(this.filtroAvancado.nome_fantasia ? this.filtroAvancado.nome_fantasia.toLowerCase() : '') &&
          ani.Variedade.toLowerCase().includes(this.filtroAvancado.variedade ? this.filtroAvancado.variedade.toLowerCase() : '') &&
          ani.LOGIN.toLowerCase().includes(this.filtroAvancado.LOGIN ? this.filtroAvancado.LOGIN.toLowerCase() : '');
      });
      if (this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? 1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? -1 : 0))
      }
      if (this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? -1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? 1 : 0))
      }
    }

    this.filtrado = true;
    this.p = 0;
  }

  selecionarTodos(selecionar) {
    this.animais.filter(ani => {
      return ani.limite !== undefined && ani.limite !== null;
    }).forEach(ani => {
      ani.checked = selecionar;
    });
    if (selecionar)
      this.animais.sort((a, b) => (a['checked'] > b['checked']) ? -1 : ((b['checked'] > a['checked']) ? 1 : 0));
    else
      this.animais.sort((a, b) => (a['NOME'] > b['NOME']) ? 1 : ((b['NOME'] > a['NOME']) ? -1 : 0));
  }

  buscarSelecionados() {
    return this.animais.filter(ani => {
      return ani.checked;
    }).length
  }

  deselecionarAnimais() {
    this.animais.forEach(ani => {
      ani.checked = false;
    })
  }

  retornarSelecionados() {
    return this.animais.filter(ani => {
      return ani.checked;
    });
  }

  onSort(field) {
    console.log(field);
    if (this.view == 'solicitados') {
      this.animaisSolicitados = this.atualCampoOrdem == field ?
        this.contadorOrdem % 2 == 0 ?
        field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
        field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
        field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
    } else {
      if (this.filtrado) {
        this.animaisFiltrados = this.atualCampoOrdem == field ?
          this.contadorOrdem % 2 == 0 ?
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
      } else {
        this.animais = this.atualCampoOrdem == field ?
          this.contadorOrdem % 2 == 0 ?
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
      }
    }

    this.contadorOrdem = this.atualCampoOrdem == field ? this.contadorOrdem + 1 : 0;

    this.atualCampoOrdem = field;
  }

  solicitar() {
    this.app.loading = true;
    var cgas = [];
    var animais = this.animais.filter(data => {
      return data.checked;
    });
    if (animais.length == 0) {
      this.toastr.error('Nenhum animal selecionado.');
      this.app.loading = false;
    } else {

      var animaisSemVistoria = animais.filter(data => {
        return !data.dataVistoria;
      });

      if (animaisSemVistoria.length != 0) {
        this.toastr.error('Há animais sem data de vistoria selecionada.');
        this.app.loading = false;
      } else {
        var valid = true;
        var message = '';
        debugger;
        animais.forEach(element => {
          if (element.dataVistoria.length == 10 &&
            ((element.dataVistoria.charAt(2) == "/" && element.dataVistoria.charAt(5) == "/") ||
              (element.dataVistoria.charAt(2) == "-" && element.dataVistoria.charAt(5) == "-") ||
              (element.dataVistoria.charAt(4) == "-" && element.dataVistoria.charAt(7) == "-"))) {

            if (element.dataVistoria.includes("/") > 0) {
              element.dataVistoria = element.dataVistoria.substring(6, 10) + '-' + element.dataVistoria.substring(3, 5) + '-' + element.dataVistoria.substring(0, 2)
            }


            if (new Date(element.dataVistoria) < new Date(element.DataSolicitacao)) {
              element.dataVistoria = element.dataVistoria + ' 23:00:00';
              if (new Date(element.dataVistoria) < new Date(element.DataSolicitacao)) {
                valid = false;
                this.toastr.warning(`${element.NOME} - Data de Vistoria não pode ser antes da data de Solicitação.`);
                message = '- há data de vistoria fora do período permitido. ';
              } else
                cgas.push({ cga: element.CGA, dataVistoria: element.dataVistoria });
            } else if (new Date(element.dataVistoria) > new Date()) {
              valid = false;
              this.toastr.warning(`${element.NOME} - Data de Vistoria não pode ser data futura`);
              message = '- há data de vistoria fora do período permitido. ';
            } else if (new Date(element.dataVistoria) > new Date(element.DataLimite)) {
              valid = false;
              this.toastr.warning(`${element.NOME} - Data de Vistoria não pode ser depois da data limite do CEIP`);
              message = '- há data de vistoria fora do período permitido. ';
            } else
              cgas.push({ cga: element.CGA, dataVistoria: element.dataVistoria });

          } else {
            message = '- O formato da data deve ser 00/00/0000 ';
            valid = false;
          }
        });

        if (!valid) {
          this.toastr.error('Data de Vistoria inválida. ' + message);
          this.app.loading = false;
        } else {
          this.animalService.aprovar(cgas).subscribe(data => {
            this.app.loading = false;
            alert('Os animais foram aprovados com sucesso!');
            window.location.reload();
          }, err => {
            this.app.loading = false;
            alert('Ocorreu um erro ao aprovar ao CEIP. Entre em contato com nosso administrativo!');
          });
        }

      }
    }
  }

  redirecionar(url) {
    window.location.href = url;
  }

  buscarAnimais() {
    this.app.loading = true;
    this.animalService.retornarAnimaisAptosCEIPPorFazendaParaAvaliacao(this.fazenda, this.categoria).subscribe((data: any[]) => {
      this.animaisTotal = data;
      this.app.loading = false;
      this.animaisTotal.forEach(element => {
        element['checked'] = false;
        element['able'] = false;




        if (element.DataLimite != null && element.DataLimite != undefined) {
          var dt = new Date(element.DataLimite);
          dt.setHours(dt.getHours() + 5);
          element.DataLimite = dt;
        }
        element['limite'] = element.DataLimite;
        if (element.limite != undefined) {
          element.able = new Date() < element.limite;
        }

        if (element.DataVistoria != null && element.DataVistoria != undefined) {
          var dt = new Date(element.DataVistoria);
          dt.setHours(dt.getHours() + 5);
          element.DataVistoria = dt;
        }

        if (element.DataSolicitacao != null && element.DataSolicitacao != undefined) {
          var dt = new Date(element.DataSolicitacao);
          dt.setHours(dt.getHours() + 5);
          element.DataSolicitacao = dt;
        }

        if (element.DataNascimento != null && element.DataNascimento != undefined) {
          var dt = new Date(element.DataNascimento);
          dt.setHours(dt.getHours() + 5);
          element.DataNascimento = dt;
        }
        const d = new Date(element.DataNascimento);
        const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);
        element['dataFormatada'] = `${da}/${mo}/${ye}`;
      });
      this.gerenciarAnimais();
      this.hasCL = this.animaisTotal.filter(item => {
        return item.Categoria == 'CL';
      }).length > 0;
      this.hasNoCL = this.animaisTotal.filter(item => {
        return item.Categoria != 'CL';
      }).length > 0;
    }, (erro) => {
      this.app.loading = false;
      alert('erro');
    })
  }

  gerenciarAnimais() {
    this.animaisSolicitados = this.animaisTotal.filter(data => {
      return data.Aprovado == 1;
    })
    this.animais = this.animaisTotal.filter(data => {
      return data.Aprovado == 0;
    })
    this.gerenciarSelectFiltros();
  }

  gerenciarSelectFiltros() {
    this.filtroRacas = [];
    this.filtroCategorias = [];
    this.filtroFazendas = [];
    this.filtroVariedades = [];
    this.filtroSolicitantes = [];
    this.filtroAprovadores = [];
    this.filtroRacasSolicitado = [];
    this.filtroCategoriasSolicitado = [];
    this.filtroVariedadesSolicitado = [];
    this.filtroFazendasSolicitado = [];
    this.filtroSolicitantesSolicitado = [];
    this.filtroAprovadoresSolicitado = [];
    //Dando valores para os selects do filtro
    //não solicitados
    var racas = {};
    this.animais.map(x => x.Raca).map(function (a) { if (a in racas) racas[a]++; else racas[a] = 1; });
    this.filtroRacas.push({ key: '', value: 'Todos' });
    Object.keys(racas).forEach(item => {
      this.filtroRacas.push({ key: item, value: item });
    })

    var categorias = {};
    this.animais.map(x => x.Categoria).map(function (a) { if (a in categorias) categorias[a]++; else categorias[a] = 1; });
    this.filtroCategorias.push({ key: '', value: 'Todos' });
    Object.keys(categorias).forEach(item => {
      this.filtroCategorias.push({ key: item, value: item });
    })

    var variedades = {};
    this.animais.map(x => x.Variedade).map(function (a) { if (a in variedades) variedades[a]++; else variedades[a] = 1; });
    this.filtroVariedades.push({ key: '', value: 'Todos' });
    Object.keys(variedades).forEach(item => {
      this.filtroVariedades.push({ key: item, value: item });
    })

    var fazendas = {};
    this.animais.map(x => x.nome_fantasia).map(function (a) { if (a in fazendas) fazendas[a]++; else fazendas[a] = 1; });
    this.filtroFazendas.push({ key: '', value: 'Todos' });
    Object.keys(fazendas).forEach(item => {
      this.filtroFazendas.push({ key: item, value: item });
    })

    var solicitantes = {};
    this.animais.map(x => x.LOGIN).map(function (a) { if (a in solicitantes) solicitantes[a]++; else solicitantes[a] = 1; });
    this.filtroSolicitantes.push({ key: '', value: 'Todos' });
    Object.keys(solicitantes).forEach(item => {
      this.filtroSolicitantes.push({ key: item, value: item });
    })

    var aprovadores = {};
    this.animais.map(x => x.LOGINAPROVADOR).map(function (a) { if (a in aprovadores) aprovadores[a]++; else aprovadores[a] = 1; });
    this.filtroAprovadores.push({ key: '', value: 'Todos' });
    Object.keys(aprovadores).forEach(item => {
      this.filtroAprovadores.push({ key: item, value: item });
    })
    //solicitados
    var racas = {};
    this.animaisSolicitados.map(x => x.Raca).map(function (a) { if (a in racas) racas[a]++; else racas[a] = 1; });
    this.filtroRacasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(racas).forEach(item => {
      this.filtroRacasSolicitado.push({ key: item, value: item });
    })

    var categorias = {};
    this.animaisSolicitados.map(x => x.Categoria).map(function (a) { if (a in categorias) categorias[a]++; else categorias[a] = 1; });
    this.filtroCategoriasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(categorias).forEach(item => {
      this.filtroCategoriasSolicitado.push({ key: item, value: item });
    })

    var variedades = {};
    this.animaisSolicitados.map(x => x.Variedade).map(function (a) { if (a in variedades) variedades[a]++; else variedades[a] = 1; });
    this.filtroVariedadesSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(variedades).forEach(item => {
      this.filtroVariedadesSolicitado.push({ key: item, value: item });
    })

    var fazendas = {};
    this.animaisSolicitados.map(x => x.nome_fantasia).map(function (a) { if (a in fazendas) fazendas[a]++; else fazendas[a] = 1; });
    this.filtroFazendasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(fazendas).forEach(item => {
      this.filtroFazendasSolicitado.push({ key: item, value: item });
    })

    var solicitantes = {};
    this.animaisSolicitados.map(x => x.LOGIN).map(function (a) { if (a in solicitantes) solicitantes[a]++; else solicitantes[a] = 1; });
    this.filtroSolicitantesSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(solicitantes).forEach(item => {
      this.filtroSolicitantesSolicitado.push({ key: item, value: item });
    })

    var aprovadores = {};
    this.animaisSolicitados.map(x => x.LOGINAPROVADOR).map(function (a) { if (a in aprovadores) aprovadores[a]++; else aprovadores[a] = 1; });
    this.filtroAprovadoresSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(aprovadores).forEach(item => {
      this.filtroAprovadoresSolicitado.push({ key: item, value: item });
    })
  }

  sair() {
    localStorage.removeItem('ancp-net-token');
    window.location.href = 'http://ancp.org.br';
  }

}
