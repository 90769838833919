import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { FarmerService } from '../services/farmer-service';
import { AnimalService } from '../services/animal-service';
import { ToastrService } from 'ngx-toastr';
import { ModalManager } from 'ngb-modal';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-ceip-animais-aptos',
  templateUrl: './ceip-animais-aptos.component.html',
  styleUrls: ['./ceip-animais-aptos.component.css']
})
export class CEIPAnimaisAptosComponent implements OnInit {

  animaisgrupo: any[] = [];
  animais: any[] = [];
  animaisSolicitados: any[];
  animaisTotal: any[];
  animaisFiltrados: any[];
  fazenda: any = '';
  fazendas: any[] = [];
  categoria: any = '';
  page: any = 1;
  pageSize: any = 10;
  search: string = '';
  showTable: boolean = false;
  view: string = 'aptos';
  showFilter: boolean = false;
  filtroAvancado: any = {
    rgnregra: 'Igual',
    rgdregra: 'Igual',
    dataNascimentoregra: 'Igual',
    mgtregra: 'Igual',
    topregra: 'Igual',
    sexo: 'm',
    raca: '',
    variedade: '',
    categoria: '',
    nome_fantasia: ''
  };
  filtrado: boolean = false;
  regras: string[] = [
    'Igual',
    'Menor',
    'Maior'
  ]
  atualCampoOrdem: string = '';
  contadorOrdem: number = 0;
  nomeAG: string = '';

  //selects filtro avançado
  filtroSexos: any[] = [
    { key: 'Macho', value: 'Macho' },
    { key: 'femea', value: 'Fêmea' },
    { key: 'm', value: 'Ambos' },
  ]
  filtroRacas: any[] = [];
  filtroCategorias: any[] = [];
  filtroFazendas: any[] = [];
  filtroVariedades: any[] = [];
  selecionouTodas: boolean = false;
  filtroRacasSolicitado: any[] = [];
  filtroCategoriasSolicitado: any[] = [];
  filtroVariedadesSolicitado: any[] = [];
  filtroFazendasSolicitado: any[] = [];
  p: number = 0;



  @ViewChild('myModal') myModal;
  private modalRef;

  @ViewChild('excelModal') excelModal;
  private excelModalRef;


  keysObject: any = {
    aptos: {
      keys: ['NOME', 'Serie', 'RGN', 'RGD', 'DataNascimento', 'MGT', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raca', 'Sexo', 'DataLimite'],
      header: ['Nome', 'Série', 'RGN', 'RGD', 'DT Nasc', 'MGTe', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raça', 'Sexo', 'Limite Emissão']
    },
    solicitados: {
      keys: ['NOME', 'Serie', 'RGN', 'RGD', 'DataNascimento', 'MGT', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raca', 'Sexo', 'LOGIN', 'DataLimite'],
      header: ['Nome', 'Série', 'RGN', 'RGD', 'DT Nasc', 'MGTe', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raça', 'Sexo', 'Solicitante', 'Limite Emissão']
    }
  };

  colunasApto: any[] = [{ key: 'Nome', value: false, field: 'NOME' }, { key: 'Série', value: false, field: 'Serie' }, { key: 'RGN', value: false, field: 'RGN' }, { key: 'RGD', value: false, field: 'RGD' },
  { key: 'Data Nascimento', value: false, field: 'DataNascimento' }, { key: 'MGTe', value: false, field: 'MGT' }, { key: 'TOP', value: false, field: 'TOP' },
  { key: 'Categoria', value: false, field: 'Categoria' }, { key: 'Variedade', value: false, field: 'Variedade' },
  { key: 'NFA', value: false, field: 'NFA' }, { key: 'Raça', value: false, field: 'Raca' }, { key: 'Sexo', value: false, field: 'Sexo' },
  { key: 'Limite Emissão', value: false, field: 'DataLimite' }];
  colunasSolicitados: any[] = [{ key: 'Nome', value: false, field: 'NOME' }, { key: 'Série', value: false, field: 'Serie' }, { key: 'RGN', value: false, field: 'RGN' }, { key: 'RGD', value: false, field: 'RGD' },
  { key: 'Data Nascimento', value: false, field: 'DataNascimento' }, { key: 'MGTe', value: false, field: 'MGT' }, { key: 'TOP', value: false, field: 'TOP' },
  { key: 'Categoria', value: false, field: 'Categoria' }, { key: 'Variedade', value: false, field: 'Variedade' },
  { key: 'NFA', value: false, field: 'NFA' }, { key: 'Raça', value: false, field: 'Raca' }, { key: 'Sexo', value: false, field: 'Sexo' },
  { key: 'Solicitante', value: false, field: 'LOGIN' }, { key: 'Limite Emissão', value: false, field: 'DataLimite' }];

  hasNoCL: boolean = false;
  hasCL: boolean = false;

  constructor(private animalService: AnimalService, private toastr: ToastrService,
    private app: AppComponent, private route: ActivatedRoute, private router: Router, private modalService: ModalManager, private farmerService: FarmerService) {

  }

  ngOnInit() {
    this.app.isPrivate = true;
    this.fazendas = JSON.parse(localStorage.getItem('solicitar-fazendas'));
    var todasFazendas = JSON.parse(localStorage.getItem('solicitar-fazendas-todas'));
    this.selecionouTodas = todasFazendas == null ? false : this.fazendas.length == todasFazendas.length;
    this.fazenda = this.fazendas.map(function (item) { return item.NFA; });
    this.categoria = JSON.parse(localStorage.getItem('solicitar-categorias'));

    this.validarFazenda();
    this.buscarNomeAG();
  }

  openModal(type) {
    if (type == 'pdf') {
      this.colunasApto.forEach(item => {
        item.value = false;
      });
      this.colunasSolicitados.forEach(item => {
        item.value = false;
      });
      this.modalRef = this.modalService.open(this.myModal, {
        size: "lg",
        modalClass: 'mymodal',
        hideCloseButton: false,
        centered: false,
        backdrop: true,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: "modal-backdrop"
      })
    }
    if (type == 'excel') {
      this.colunasApto.forEach(item => {
        item.value = false;
      });
      this.colunasSolicitados.forEach(item => {
        item.value = false;
      });
      this.modalRef = this.modalService.open(this.excelModal, {
        size: "lg",
        modalClass: 'mymodal',
        hideCloseButton: false,
        centered: false,
        backdrop: true,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: "modal-backdrop"
      })
    }
  }
  closeModal(type = "pdf") {
    if (type === 'pdf') {
      this.modalService.close(this.modalRef);
    }
    if (type === 'excel') {
      this.modalService.close(this.excelModalRef);
    }
  }

  public gerarRelatorioExcel(tipo): void {
    this.gerarRelatorioExcelAsync(tipo)
  }

  async gerarRelatorioExcelAsync(tipo) {

    try {
      this.app.loading = true;
      const data = await this.farmerService.accredited(tipo)
      const avaliacaoAnimal = await this.farmerService.getAnimaisAvaliados(tipo)

      const sheetProdutores = this.createSheetProdutores(data)
      const sheetDistribuicao = this.createSheetDistribuicao(data)
      const sheetAvaliacaoAnimal = this.createSheetCaracteristicas(avaliacaoAnimal)


      const wb: XLSX.WorkBook = { Sheets: { 'PRODUTORES': sheetProdutores, 'DISTRIBUICAO': sheetDistribuicao, 'AVALIACAO': sheetAvaliacaoAnimal }, SheetNames: ['PRODUTORES', 'DISTRIBUICAO', 'AVALIACAO'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, 'produtores_ceip.xlsx');
    } catch (error) {

    }
    finally {
      this.app.loading = false;
    }
  }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';



  public createSheetProdutores(data): XLSX.WorkSheet {

    const ws: XLSX.WorkSheet = {
      "!ref": "A1",
      "!cols": [
        {
          width: 8,
        },
        {
          width: 26
        },
        {
          width: 61
        },
      ],
      "!rows": [{ hpx: 20 },],
      A1: {
        t: 's', v: 'Listagem completa e atualizada com todos os produtores credenciados, em ordem de credenciamento',
      },
      "!merges": [
        { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } } /* A1:A3*/
      ]
    }

    XLSX.utils.sheet_add_aoa(ws, [
      ["UF", "Nº do Credenciamento CEIP", "Produtor Credenciado",]], { origin: { c: 0, r: 1 } })
    const produtores = data.map(i => { return { uf: i.ESTADO, credenciamento: i.N_CREDENCIAMENTO, produtor: i.PRODUTOR_CREDENCIADO } })
    XLSX.utils.sheet_add_json(ws, produtores, { origin: -1, skipHeader: true })

    return ws
  }

  public createSheetDistribuicao(data): XLSX.WorkSheet {

    const ws: XLSX.WorkSheet = {
      "!ref": "A1;A2;G2",
      "!cols": [
        {
          width: 26,
        },
        {
          width: 56
        },
        {
          width: 42
        },
        {
          width: 9
        }
      ],
      "!rows": [{ hpx: 30 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 30 }],
      A1: {
        t: 's', v: `Nº total de animais, machos e fêmeas avaliados, candidatos ao CEIP, certificados e
        percentagem de animais certificados no Projeto`,
      },
      A2: {
        t: 's', v: "Dados Totais"
      },
      D2: {
        t: "s", v: "Animais Avaliados"
      },
      G2: {
        t: "s", v: "Candidatos ao CEIP"
      },
      J2: {
        t: "s", v: "Animais Certificados"
      },
      M2: {
        t: "s", v: "Animais Certificados(%)"
      },
      A6: {
        t: "s", v: `Nº total de animais, machos e fêmeas avaliados, candidatos ao CEIP, certificados e
        percentagem de animais certificados no Projeto e por propriedade`
      },
      A7: {
        t: "s", v: "UF"
      },
      B7: {
        t: "s", v: "Nº do Credenciamento CEIP"
      },
      C7: {
        t: "s", v: "Produtor Credenciado"
      },
      D7: {
        t: "s", v: "Animais Avaliados"
      },
      G7: {
        t: "s", v: "Candidatos ao CEIP"
      },
      J7: {
        t: "s", v: "Animais Certificados"
      },
      M7: {
        t: "s", v: "Animais Certificados(%)"
      },
      "!merges": [ //coordenadas das celulas mescladas
        { s: { c: 0, r: 0 }, e: { c: 14, r: 0 } }, /* A1:O1 */
        { s: { c: 0, r: 1 }, e: { c: 2, r: 3 } }, /* A2:C4 */
        { s: { c: 3, r: 1 }, e: { c: 5, r: 1 } }, /* D2:F2 */
        { s: { c: 6, r: 1 }, e: { c: 8, r: 1 } }, /* G2:I2 */
        { s: { c: 9, r: 1 }, e: { c: 11, r: 1 } }, /* J2:L2 */
        { s: { c: 12, r: 1 }, e: { c: 14, r: 1 } }, /* M2:O2 */
        { s: { c: 0, r: 5 }, e: { c: 14, r: 5 } }, /* A6:O6 */
        { s: { c: 0, r: 6 }, e: { c: 0, r: 7 } }, /* A6:A7 */
        { s: { c: 1, r: 6 }, e: { c: 1, r: 7 } }, /* B6:B7 */
        { s: { c: 2, r: 6 }, e: { c: 2, r: 7 } }, /* C6:C7 */
        { s: { c: 3, r: 6 }, e: { c: 5, r: 6 } }, /* D2:F2 */
        { s: { c: 6, r: 6 }, e: { c: 8, r: 6 } }, /* G2:I2 */
        { s: { c: 9, r: 6 }, e: { c: 11, r: 6 } }, /* J2:L2 */
        { s: { c: 12, r: 6 }, e: { c: 14, r: 6 } }, /* M2:O2 */
      ]
    }

    //calcula e insere os totais
    const totais = new Array(12).fill(0);

    const mapped = data.map(e => {
      totais[0] += e.ANIMAIS
      totais[1] += e.ANIMAIS_MACHOS
      totais[2] += e.ANIMAIS_FEMEAS
      totais[3] += e.ANIMAIS_CANDIDATOS
      totais[4] += e.ANIMAIS_CANDIDATOS_MACHOS
      totais[5] += e.ANIMAIS_CANDIDATOS_FEMEAS
      totais[6] += e.ANIMAIS_CERTIFICADOS
      totais[7] += e.ANIMAIS_CERTIFICADOS_MACHOS
      totais[8] += e.ANIMAIS_CERTIFICADOS_FEMEAS

      //já monta obj de resultados
      const r = {
        estado: e.ESTADO,
        credenciamento: e.N_CREDENCIAMENTO,
        produtor: e.PRODUTOR_CREDENCIADO,
        animais: e.ANIMAIS,
        animaisMachos: e.ANIMAIS_MACHOS,
        animaisFemeas: e.ANIMAIS_FEMEAS,
        candidatos: e.ANIMAIS_CANDIDATOS,
        candidatosMachos: e.ANIMAIS_CANDIDATOS_MACHOS,
        candidatosFemeas: e.ANIMAIS_CANDIDATOS_FEMEAS,
        cert: e.ANIMAIS_CERTIFICADOS,
        certMachos: e.ANIMAIS_CERTIFICADOS_MACHOS,
        certFemeas: e.ANIMAIS_CERTIFICADOS_FEMEAS,
        percAnimais: "",
        percMachos: "",
        percFemeas: "",
      }

      if (r.candidatos && r.cert) {
        r.percAnimais += (r.cert / r.candidatos).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      }
      if (r.certMachos && r.candidatosMachos) {
        r.percMachos += (r.certMachos / r.candidatosMachos).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      }
      if (r.certFemeas && r.candidatosFemeas) {
        r.percFemeas += (r.certFemeas / r.candidatosFemeas).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      }

      return r;
    })

    //obtem porcentagem de certificados totais
    if (totais[3] && totais[6]) {
      totais[9] += (totais[3] / totais[6]).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
    if (totais[4] && totais[7]) {
      totais[10] += (totais[4] / totais[7]).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
    if (totais[5] && totais[8]) {
      totais[11] += (totais[5] / totais[8]).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }

    XLSX.utils.sheet_add_aoa(ws, [
      ["Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea"],
      totais],
      { origin: { c: 3, r: 2 } })
    XLSX.utils.sheet_add_aoa(ws, [["Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea"]],
      { origin: { c: 3, r: 7 } })

    //insere as informações
    XLSX.utils.sheet_add_json(ws, mapped, { origin: { c: 0, r: 8 }, skipHeader: true })

    return ws;

    // const wb: XLSX.WorkBook = { Sheets: { 'GERAL': ws }, SheetNames: ['GERAL'] };
    // const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // this.saveExcelFile(excelBuffer, 'distribuicao_animais_ceip.xlsx');
  }

  public createSheetCaracteristicas(data): XLSX.WorkSheet {

    const ws: XLSX.WorkSheet = {
      "!ref": "A1;A2;G2;E2;G2;C3;E3;G3;I3;K3M3;O3",
      "!cols": [
        {
          width: 31,
        },        
      ],
      "!rows": [{ hpx: 31 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 30 }],
      A1: {
        t: 's', v: `Média das DEP e do índice específico de cada Projeto para o total de animais avaliados, total
        de animais certificados e machos e fêmeas certificados`,
        s: { foreground: [{ color: "#0055dd" }] }
      },
      B2: {
        t: 's',
        v: 'DEP'
      },
      A5: {
        t: 's',
        v: 'Dados Totais'
      },
      C2: {
        t: 's',
        v: '   Animais Avaliados'
      },
      E2: {
        t: 's',
        v: '   Candidatos ao CEIP'
      },
      K2: {
        t: 's',
        v: '   Animais Certificados'
      },
      C3: {t: 's', v:'Total'},
      E3: {t: 's', v:'Total'},
      G3: {t: 's', v:'Machos'},
      I3: {t: 's', v:'Femeas'},
      K3: {t: 's', v:'Total'},
      M3: {t: 's', v:'Machos'},
      O3: {t: 's', v:'Femeas'},
      "!merges": [ //coordenadas das celulas mescladas
        { s: { c: 0, r: 0 }, e: { c: 15, r: 0 } }, /* A1:P1 */
        { s: { c: 0, r: 1 }, e: { c: 0, r: 3 } }, /* A2:A4 */
        { s: { c: 1, r: 1 }, e: { c: 1, r: 3 } }, /* A2:A4 */
        { s: { c: 0, r: 4 }, e: { c: 0, r: 14 } },
        { s: { c: 2, r: 1 }, e: { c: 3, r: 1 } },
        { s: { c: 4, r: 1 }, e: { c: 9, r: 1 } },
        { s: { c: 10, r: 1 }, e: { c: 15, r: 1 } },        
        { s: { c: 2, r: 2 }, e: { c: 3, r: 2 } },  
        { s: { c: 4, r: 2 }, e: { c: 5, r: 2 } },  
        { s: { c: 6, r: 2 }, e: { c: 7, r: 2 } },  
        { s: { c: 8, r: 2 }, e: { c: 9, r: 2 } },  
        { s: { c: 10, r: 2 }, e: { c: 11, r: 2 } },  
        { s: { c: 12, r: 2 }, e: { c: 13, r: 2 } },  
        { s: { c: 14, r: 2 }, e: { c: 15, r: 2 } },          
      ]
    }

    XLSX.utils.sheet_add_aoa(ws, [['Media','Desvio','Media','Desvio','Media','Desvio','Media','Desvio','Media','Desvio','Media','Desvio','Media','Desvio',]], { origin: { c: 2, r: 3 }, })    
    //insere as informações
    XLSX.utils.sheet_add_json(ws, data['animaisAvaliados'], { origin: { c: 1, r: 4 }, skipHeader: true })
    XLSX.utils.sheet_add_aoa(ws, data['avaliados_total'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 4, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['avaliados_machos'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 6, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['avaliados_femeas'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 8, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['certificados_total'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 10, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['certificados_machos'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 12, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['certificados_femeas'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 14, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, [[' *Mérito Genético Total Econômico']], { origin: -1 })  
    return ws
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  filtrarPDF() {
    var itemsChecked = this.view == 'aptos' ? this.colunasApto.filter(ani => {
      return ani.value;
    }) : this.colunasSolicitados.filter(ani => {
      return ani.value;
    });
    //Agrupar
    var helper = {};
    let result = itemsChecked.map(a => a.key);
    let name = '';
    var animals = [];
    //Aptos não filtrados
    if (this.filtrado) {
      animals = this.animaisFiltrados;
      name = 'animais-aptos-ceip-';
    } else {
      if (this.view == 'aptos') {
        var animalsSelected = this.animais.filter(ani => {
          return ani.checked;
        });
        animals = (animalsSelected.length > 0) ? animalsSelected : this.animais;
        name = 'animais-aptos-ceip-';
      } else {
        animals = this.animaisSolicitados;
        name = 'animais-solicitados-ceip-';
      }
    }


    var result2 = animals.reduce(function (r, o) {
      var title = '';
      itemsChecked.forEach(element => {
        if (element.field.includes('Data')) {
          var date = new Date(o[element.field]);
          let month = String(date.getMonth() + 1);
          let day = String(date.getDate());
          const year = String(date.getFullYear());

          if (month.length < 2) month = '0' + month;
          if (day.length < 2) day = '0' + day;

          title = title + `${title == '' ? '' : ' > '} ${element.key}: ${day}/${month}/${year}`;
        } else if (element.field.includes('NFA')) {
          title = title + `${title == '' ? '' : ' > '} ${element.key}: ${o[element.field]} - ${o['nome_fantasia']}`;
        } else
          title = title + `${title == '' ? '' : ' > '} ${element.key}: ${o[element.field]}`;
      });

      if (!helper[title]) {
        var items = []
        items.push(o);
        helper[title] = items;
        r.push(helper[title]);
      } else {
        helper[title].push(o);
      }
      return r;
    }, []);


    this.printEditable(helper, result, name);
  }

  checkItem(item, value) {
    return item == value;
  }

  start = 10;
  printEditable(animals, keysSelected, name) {

    this.app.loading = true;
    setTimeout(() => {

      const doc = new jsPDF('l');
      var titles = Object.keys(animals);

      var indexToSkip = []
      keysSelected.forEach(key => {
        if (this.view == 'aptos')
          indexToSkip.push(this.colunasApto.map(function (e) { return e.key; }).indexOf(key));
        else
          indexToSkip.push(this.colunasSolicitados.map(function (e) { return e.key; }).indexOf(key));
      });

      var realHeader = [];
      var countKey = 0;
      if (this.view == 'aptos')
        this.keysObject.aptos.header.forEach(header => {
          var itCan = indexToSkip.filter(item => item == countKey);
          if (itCan.length == 0) {
            if (header.includes('MGT'))
              header = this.hasNoCL ? this.hasCL ? header + ' / IGE' : header : this.hasCL ? 'IGE' : '';

            realHeader.push(header);
          }
          countKey = countKey + 1
        })
      else
        this.keysObject.solicitados.header.forEach(header => {
          var itCan = indexToSkip.filter(item => item == countKey);
          if (itCan.length == 0) {
            if (header.includes('MGT'))
              header = this.hasNoCL ? this.hasCL ? header + ' / IGE' : header : this.hasCL ? 'IGE' : '';

            realHeader.push(header);
          }
          countKey = countKey + 1
        })


      var start = 10;
      titles.forEach(title => {
        var allvalues = [];

        animals[title].forEach(animal => {
          var values = [];
          var countValue = 0;
          if (this.view == 'aptos') {
            this.keysObject.aptos.keys.forEach(key => {

              var itCan = indexToSkip.filter(item => item == countValue);
              if (itCan.length == 0) {
                if (key.includes('Data')) {
                  var date = new Date(animal[key]);
                  let month = String(date.getMonth() + 1);
                  let day = String(date.getDate());
                  const year = String(date.getFullYear());

                  if (month.length < 2) month = '0' + month;
                  if (day.length < 2) day = '0' + day;
                  values.push(`${day}/${month}/${year}`);
                }
                else if (key.includes('MGT')) {
                  values.push(parseFloat(animal[key]).toFixed(2));
                } else
                  values.push(animal[key]);
              }
              countValue = countValue + 1;
            });
          } else {
            this.keysObject.solicitados.keys.forEach(key => {

              var itCan = indexToSkip.filter(item => item == countValue);
              if (itCan.length == 0) {
                if (key.includes('Data')) {
                  var date = new Date(animal[key]);
                  let month = String(date.getMonth() + 1);
                  let day = String(date.getDate());
                  const year = String(date.getFullYear());

                  if (month.length < 2) month = '0' + month;
                  if (day.length < 2) day = '0' + day;
                  values.push(`${day}/${month}/${year}`);
                }
                else if (key.includes('MGT')) {

                  values.push(parseFloat(animal[key]).toFixed(2));
                } else
                  values.push(animal[key]);
              }
              countValue = countValue + 1;
            });
          }

          allvalues.push(values);

        });

        // if (doc.lastAutoTable)
        //   doc.addPage();
        this.start = doc.lastAutoTable ? doc.lastAutoTable.cursor.y + 10 : 10;

        doc.text(15, this.start, title ? title : '');
        this.start = this.start + 5;
        doc.autoTable({
          head: [realHeader],
          body: allvalues,
          startY: this.start,
        })
      });
      doc.save(name + new Date().toISOString().slice(0, 10) + '.pdf');
      this.app.loading = false;
    }, 2000)
  }

  validIndex(index) {
    return this.colunasApto[index].value == false;
  }

  retornarSelecionados() {
    return this.animais.filter(ani => {
      return ani.checked;
    });
  }

  cancelarSolicitacao(animal) {
    if (animal.DataAprovacao != null || animal.UsuarioAprovacao != null) {
      this.toastr.error(`Animal já foi aprovado pelo consultor`);
    } else {
      this.toastr.info(`Cancelando solicitação do animal ${animal.NOME}..`);
      this.animalService.cancelarSolicitacaoAnimal(animal.CGA).subscribe((data: any) => {
        this.toastr.success(`Solicitação cancelada com sucesso.`);
        this.buscarAnimais();
      }, error => {
        this.toastr.error(`Não foi possível.`);
      })
    }
    console.log(animal);
  }

  buscarNomeAG() {
    this.animalService.retornarNomeAG().subscribe((data: any) => {
      this.nomeAG = data.toUpperCase();
    })
  }

  validarFazenda() {
    //TBD: Validar se essa fazenda está liberada para o usuario logado
    this.buscarAnimais();
  }

  captureScreen(title) {
    this.showTable = true;
    this.app.loading = true;
    setTimeout(() => {
      const doc = new jsPDF('l');
      if (this.view == 'aptos') {
        if (this.filtrado) {
          doc.autoTable({ html: '#contentToConvert1' });
        } else {
          doc.text(15, 10, title ? title : '');
          doc.autoTable({ html: '#pdfAptosGeral' });
        }
      }
      else
        doc.autoTable({
          html: '#contentToConvert2', columnStyles: {
            0: { cellWidth: 35 }, 10: { cellWidth: 25 }
          }
        });

      doc.save('animais-ceip-' + new Date().toISOString().slice(0, 10) + '.pdf');

      setTimeout(() => {
        this.showTable = false;
        this.app.loading = false;
      }, 2000);
    }, 2000)

  }

  captureScreen2() {
    this.showTable = true;
    this.app.loading = true;
    setTimeout(() => {
      const doc = new jsPDF('l');
      doc.autoTable({ html: '#contentToConvertSelecionados', });

      doc.save('animais-ceip-selecionados-' + new Date().toISOString().slice(0, 10) + '.pdf');

      setTimeout(() => {
        this.showTable = false;
        this.app.loading = false;
      }, 2000);
    }, 2000)

  }

  filtrar() {
    console.log(this.search);
    this.animaisFiltrados = this.animais.filter(ani => {
      return ani.NOME.toLowerCase().includes(this.search.toLowerCase()) || ani.RGN.toLowerCase().includes(this.search.toLowerCase());
    });
    this.filtrado = true;
    this.p = 0;
  }

  limparFiltroAvancado() {
    this.filtroAvancado = {
      rgnregra: 'Igual',
      rgdregra: 'Igual',
      dataNascimentoregra: 'Igual',
      mgtregra: 'Igual',
      topregra: 'Igual',
      sexo: 'm',
      raca: '',
      variedade: '',
      categoria: '',
      nome_fantasia: ''
    };
    this.showFilter = false;
    this.animaisFiltrados = [];
    this.filtrado = false;
    this.p = 0;
  }

  redirecionar(url) {
    window.location.href = url;
  }

  filtrarAvancado() {
    if (this.view == 'aptos') {
      this.animaisFiltrados = this.animais.filter(ani => {
        return ani.NOME.toLowerCase().includes(this.filtroAvancado.nome ? this.filtroAvancado.nome.toLowerCase() : '') &&
          ani.Serie.toLowerCase().includes(this.filtroAvancado.serie ? this.filtroAvancado.serie.toLowerCase() : '') &&
          ani.RGN.toLowerCase().includes(this.filtroAvancado.rgn ? this.filtroAvancado.rgn.toLowerCase() : '') &&
          // (this.filtroAvancado.rgnregra == 'Igual' && this.filtroAvancado.rgn ? parseInt(ani.RGN) == parseInt(this.filtroAvancado.rgn) :
          //   this.filtroAvancado.rgnregra == 'Maior' && this.filtroAvancado.rgn ? parseInt(ani.RGN) > parseInt(this.filtroAvancado.rgn) :
          //     this.filtroAvancado.rgnregra == 'Menor' && this.filtroAvancado.rgn ? parseInt(ani.RGN) < parseInt(this.filtroAvancado.rgn) :
          //       ani.RGN.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.rgdregra == 'Igual' && this.filtroAvancado.rgd ? parseInt(ani.RGD) == parseInt(this.filtroAvancado.rgd) :
            this.filtroAvancado.rgdregra == 'Maior' && this.filtroAvancado.rgd ? parseInt(ani.RGD) > parseInt(this.filtroAvancado.rgd) :
              this.filtroAvancado.rgdregra == 'Menor' && this.filtroAvancado.rgd ? parseInt(ani.RGD) < parseInt(this.filtroAvancado.rgd) :
                ani.RGD.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.dataNascimentoregra == 'Igual' ?
            ani.dataFormatada.toString().toLowerCase().includes(this.filtroAvancado.dataNascimento ? this.filtroAvancado.dataNascimento.toLowerCase() : '') :
            this.filtroAvancado.dataNascimento == 'Maior' ? ani.dataFormatada >= this.filtroAvancado.dataNascimento :
              this.filtroAvancado.dataNascimento == 'Menor' ? ani.dataFormatada <= this.filtroAvancado.dataNascimento :
                ani.dataFormatada.toString().toLowerCase().includes('')) &&
          // ani.DataNascimento.toLowerCase().includes(this.filtroAvancado.dataNascimento && this.filtroAvancado.dataNascimentoregra == 'Igual' ? this.filtroAvancado.dataNascimento.toLowerCase() : '') &&

          (this.filtroAvancado.mgtregra == 'Igual' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) == parseFloat(this.filtroAvancado.mgt) :
            this.filtroAvancado.mgtregra == 'Maior' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) > parseFloat(this.filtroAvancado.mgt) :
              this.filtroAvancado.mgtregra == 'Menor' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) < parseFloat(this.filtroAvancado.mgt) :
                ani.MGT.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.topregra == 'Igual' && this.filtroAvancado.top ? parseFloat(ani.TOP) == parseFloat(this.filtroAvancado.top) :
            this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top ? parseFloat(ani.TOP) > parseFloat(this.filtroAvancado.top) :
              this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top ? parseFloat(ani.TOP) < parseFloat(this.filtroAvancado.top) :
                ani.TOP.toString().toLowerCase().includes('')) &&
          ani.Sexo.toLowerCase().includes(this.filtroAvancado.sexo ? this.filtroAvancado.sexo.toLowerCase() : '') &&
          ani.Raca.toLowerCase().includes(this.filtroAvancado.raca ? this.filtroAvancado.raca.toLowerCase() : '') &&
          ani.Categoria.toLowerCase().includes(this.filtroAvancado.categoria ? this.filtroAvancado.categoria.toLowerCase() : '') &&
          ani.nome_fantasia.toLowerCase().includes(this.filtroAvancado.nome_fantasia ? this.filtroAvancado.nome_fantasia.toLowerCase() : '') &&
          ani.Variedade.toLowerCase().includes(this.filtroAvancado.variedade ? this.filtroAvancado.variedade.toLowerCase() : '');
      });
      if (this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? 1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? -1 : 0))
      }
      if (this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? -1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? 1 : 0))
      }
    } else {
      this.animaisFiltrados = this.animaisSolicitados.filter(ani => {
        return ani.NOME.toLowerCase().includes(this.filtroAvancado.nome ? this.filtroAvancado.nome.toLowerCase() : '') &&
          ani.Serie.toLowerCase().includes(this.filtroAvancado.serie ? this.filtroAvancado.serie.toLowerCase() : '') &&
          ani.RGN.toLowerCase().includes(this.filtroAvancado.rgn ? this.filtroAvancado.rgn.toLowerCase() : '') &&
          // (this.filtroAvancado.rgnregra == 'Igual' && this.filtroAvancado.rgn ? parseInt(ani.RGN) == parseInt(this.filtroAvancado.rgn) :
          //   this.filtroAvancado.rgnregra == 'Maior' && this.filtroAvancado.rgn ? parseInt(ani.RGN) > parseInt(this.filtroAvancado.rgn) :
          //     this.filtroAvancado.rgnregra == 'Menor' && this.filtroAvancado.rgn ? parseInt(ani.RGN) < parseInt(this.filtroAvancado.rgn) :
          //       ani.RGN.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.rgdregra == 'Igual' && this.filtroAvancado.rgd ? parseInt(ani.RGD) == parseInt(this.filtroAvancado.rgd) :
            this.filtroAvancado.rgdregra == 'Maior' && this.filtroAvancado.rgd ? parseInt(ani.RGD) > parseInt(this.filtroAvancado.rgd) :
              this.filtroAvancado.rgdregra == 'Menor' && this.filtroAvancado.rgd ? parseInt(ani.RGD) < parseInt(this.filtroAvancado.rgd) :
                ani.RGD.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.dataNascimentoregra == 'Igual' ?
            ani.dataFormatada.toString().toLowerCase().includes(this.filtroAvancado.dataNascimento ? this.filtroAvancado.dataNascimento.toLowerCase() : '') :
            this.filtroAvancado.dataNascimento == 'Maior' ? ani.dataFormatada >= this.filtroAvancado.dataNascimento :
              this.filtroAvancado.dataNascimento == 'Menor' ? ani.dataFormatada <= this.filtroAvancado.dataNascimento :
                ani.dataFormatada.toString().toLowerCase().includes('')) &&
          // ani.DataNascimento.toLowerCase().includes(this.filtroAvancado.dataNascimento && this.filtroAvancado.dataNascimentoregra == 'Igual' ? this.filtroAvancado.dataNascimento.toLowerCase() : '') &&

          (this.filtroAvancado.mgtregra == 'Igual' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) == parseFloat(this.filtroAvancado.mgt) :
            this.filtroAvancado.mgtregra == 'Maior' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) > parseFloat(this.filtroAvancado.mgt) :
              this.filtroAvancado.mgtregra == 'Menor' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) < parseFloat(this.filtroAvancado.mgt) :
                ani.MGT.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.topregra == 'Igual' && this.filtroAvancado.top ? parseFloat(ani.TOP) == parseFloat(this.filtroAvancado.top) :
            this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top ? parseFloat(ani.TOP) > parseFloat(this.filtroAvancado.top) :
              this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top ? parseFloat(ani.TOP) < parseFloat(this.filtroAvancado.top) :
                ani.TOP.toString().toLowerCase().includes('')) &&
          ani.Sexo.toLowerCase().includes(this.filtroAvancado.sexo ? this.filtroAvancado.sexo.toLowerCase() : '') &&
          ani.Raca.toLowerCase().includes(this.filtroAvancado.raca ? this.filtroAvancado.raca.toLowerCase() : '') &&
          ani.Categoria.toLowerCase().includes(this.filtroAvancado.categoria ? this.filtroAvancado.categoria.toLowerCase() : '') &&
          ani.nome_fantasia.toLowerCase().includes(this.filtroAvancado.nome_fantasia ? this.filtroAvancado.nome_fantasia.toLowerCase() : '') &&
          ani.Variedade.toLowerCase().includes(this.filtroAvancado.variedade ? this.filtroAvancado.variedade.toLowerCase() : '');
      });
      if (this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? 1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? -1 : 0))
      }
      if (this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? -1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? 1 : 0))
      }
    }
    this.filtrado = true;
    this.p = 0;
  }

  selecionarTodos(selecionar) {
    this.animais.filter(ani => {
      return ani.limite !== undefined && ani.limite !== null;
    }).forEach(ani => {
      ani.checked = selecionar;
    });
    if (selecionar)
      this.animais.sort((a, b) => (a['checked'] > b['checked']) ? -1 : ((b['checked'] > a['checked']) ? 1 : 0));
    else
      this.animais.sort((a, b) => (a['NOME'] > b['NOME']) ? 1 : ((b['NOME'] > a['NOME']) ? -1 : 0));
  }

  buscarSelecionados() {
    return this.animais.filter(ani => {
      return ani.checked;
    }).length
  }

  onSort(field) {
    console.log(field);
    if (this.view == 'solicitados') {
      this.animaisSolicitados = this.atualCampoOrdem == field ?
        this.contadorOrdem % 2 == 0 ?
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
        field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
    } else {
      if (this.filtrado) {
        this.animaisFiltrados = this.atualCampoOrdem == field ?
          this.contadorOrdem % 2 == 0 ?
            field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
            field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
      } else {
        this.animais = this.atualCampoOrdem == field ?
          this.contadorOrdem % 2 == 0 ?
            field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
            field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
      }
    }

    this.contadorOrdem = this.atualCampoOrdem == field ? this.contadorOrdem + 1 : 0;

    this.atualCampoOrdem = field;
  }

  solicitar() {
    this.app.loading = true;
    var cgas = [];
    var animais = this.animais.filter(data => {
      return data.checked;
    });
    if (animais.length == 0) {
      alert('Nenhum animal selecionado.');
    } else {
      animais.forEach(element => {
        cgas.push(element.CGA);
      });
      this.animalService.solicitar(cgas).subscribe(data => {
        this.app.loading = false;
        alert('Os animais foram solicitados com sucesso!');
        window.location.reload();
      }, err => {
        this.app.loading = false;
        alert('Ocorreu um erro ao solicitar ao CEIP. Entre em contato com nosso administrativo!');
      });
    }

  }

  buscarAnimais() {
    this.app.loading = true;
    this.animalService.retornarAnimaisAptosCEIPPorFazenda(this.fazenda, this.categoria).subscribe((data: any[]) => {
      this.animaisTotal = data;
      this.app.loading = false;
      this.animaisTotal.forEach(element => {
        element['checked'] = false;
        element['able'] = false;
        element['limite'] = element.DataLimite;
        if (element.limite != undefined) {
          element.able = new Date() < new Date(element.limite);
        }

        const d = new Date(element.DataNascimento);
        const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);
        element['dataFormatada'] = `${da}/${mo}/${ye}`;
      });
      this.gerenciarAnimais();
      this.hasCL = this.animaisTotal.filter(item => {
        return item.Categoria == 'CL';
      }).length > 0;
      this.hasNoCL = this.animaisTotal.filter(item => {
        return item.Categoria != 'CL';
      }).length > 0;
    }, (erro) => {
      this.app.loading = false;
      alert('erro');
    })
  }

  gerenciarAnimais() {
    this.animaisSolicitados = this.animaisTotal.filter(data => {
      return data.Solicitados == 1;
    })
    this.animais = this.animaisTotal.filter(data => {
      return data.Solicitados == 0;
    })
    this.gerenciarSelectFiltros();
  }

  gerenciarSelectFiltros() {
    this.filtroRacas = [];
    this.filtroCategorias = [];
    this.filtroFazendas = [];
    this.filtroVariedades = [];
    this.filtroRacasSolicitado = [];
    this.filtroCategoriasSolicitado = [];
    this.filtroVariedadesSolicitado = [];
    this.filtroFazendasSolicitado = [];
    //Dando valores para os selects do filtro
    //não solicitados
    var racas = {};
    this.animais.map(x => x.Raca).map(function (a) { if (a in racas) racas[a]++; else racas[a] = 1; });
    this.filtroRacas.push({ key: '', value: 'Todos' });
    Object.keys(racas).forEach(item => {
      this.filtroRacas.push({ key: item, value: item });
    })

    var categorias = {};
    this.animais.map(x => x.Categoria).map(function (a) { if (a in categorias) categorias[a]++; else categorias[a] = 1; });
    this.filtroCategorias.push({ key: '', value: 'Todos' });
    Object.keys(categorias).forEach(item => {
      this.filtroCategorias.push({ key: item, value: item });
    })

    var variedades = {};
    this.animais.map(x => x.Variedade).map(function (a) { if (a in variedades) variedades[a]++; else variedades[a] = 1; });
    this.filtroVariedades.push({ key: '', value: 'Todos' });
    Object.keys(variedades).forEach(item => {
      this.filtroVariedades.push({ key: item, value: item });
    })

    var fazendas = {};
    this.animais.map(x => x.nome_fantasia).map(function (a) { if (a in fazendas) fazendas[a]++; else fazendas[a] = 1; });
    this.filtroFazendas.push({ key: '', value: 'Todos' });
    Object.keys(fazendas).forEach(item => {
      this.filtroFazendas.push({ key: item, value: item });
    })
    //solicitados
    var racas = {};
    this.animaisSolicitados.map(x => x.Raca).map(function (a) { if (a in racas) racas[a]++; else racas[a] = 1; });
    this.filtroRacasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(racas).forEach(item => {
      this.filtroRacasSolicitado.push({ key: item, value: item });
    })

    var categorias = {};
    this.animaisSolicitados.map(x => x.Categoria).map(function (a) { if (a in categorias) categorias[a]++; else categorias[a] = 1; });
    this.filtroCategoriasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(categorias).forEach(item => {
      this.filtroCategoriasSolicitado.push({ key: item, value: item });
    })

    var variedades = {};
    this.animaisSolicitados.map(x => x.Variedade).map(function (a) { if (a in variedades) variedades[a]++; else variedades[a] = 1; });
    this.filtroVariedadesSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(variedades).forEach(item => {
      this.filtroVariedadesSolicitado.push({ key: item, value: item });
    })

    var fazendas = {};
    this.animaisSolicitados.map(x => x.nome_fantasia).map(function (a) { if (a in fazendas) fazendas[a]++; else fazendas[a] = 1; });
    this.filtroFazendasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(fazendas).forEach(item => {
      this.filtroFazendasSolicitado.push({ key: item, value: item });
    })
  }

  sair() {
    localStorage.removeItem('ancp-net-token');
    window.location.href = 'http://ancp.org.br';
  }

}
