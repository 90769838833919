import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";

@Injectable()
export class UserService {

  constructor(private http: HttpClient, private app: AppComponent) {

  }

  checkAdmin(token): Observable<any> {    
    return this.http.get(this.app.URL + "/api/users/check-admin", {
      headers: new HttpHeaders({
        "authorization": token
      })
    })
  }

  checkAvaliador(token): Observable<any> {    
    return this.http.get(this.app.URL + "/api/users/check-avaliador", {
      headers: new HttpHeaders({
        "authorization": token
      })
    })
  }

  authenticateCode(email, token): Observable<any> {
    return this.http.post(this.app.URL + "/api/users/activate", { email: email }, {
      headers: new HttpHeaders({
        "authorization": token
      })
    })
  }

  register(data: any): Observable<any> {
    return this.http.post(this.app.URL + "/api/users", data);
  }

  confirmCode(data: any): Observable<any> {
    return this.http.post(this.app.URL + "/api/users/confirm-code", data);
  }

  rememberPassword(email: string): Observable<any> {
    return this.http.get(this.app.URL + "/api/users/forgot-password/" + email);
  }

  signIn(data: any): Observable<any> {
    return this.http.post(this.app.URL + "/api/users/authenticate", data);
  }

  getAll(): Observable<any> {
    var token = localStorage.getItem('ancp-token');
    if (token) {
      return this.http.get(this.app.URL + "/api/users", {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    } else {
      return this.http.get(this.app.URL + "/api/not-found");
    }
  }

  getDashboard(): Observable<any> {
    var token = localStorage.getItem('ancp-token');
    if (token) {
      return this.http.get(this.app.URL + "/api/users/dashboard", {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    } else {
      return this.http.get(this.app.URL + "/api/not-found");
    }
  }

  updatePassword(passwordObject): Observable<any> {
    var token = localStorage.getItem('ancp-token');
    if (token) {
      return this.http.post(this.app.URL + "/api/users/update-password", passwordObject, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    } else {
      return this.http.get(this.app.URL + "/api/not-found");
    }
  }
}
