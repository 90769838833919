import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { ModalManager } from 'ngb-modal';

@Component({
  selector: 'app-processamento-online',
  templateUrl: './processamento-online.component.html',
  styleUrls: ['./processamento-online.component.css']
})
export class ProcessamentoOnlineComponent implements OnInit {
  @ViewChild('genealogiaModal') genealogiaModal;
  private modalRef;
  private: any;
  tab: string = '';
  quantidadePaginas: any[] = [15,25,50,100,200,500];
  acoes: any[] = ["Alteração da Situação do Animal"];
  fazendas: any[] = [{
    nome: 'Estância São Luiz dos Coqueiros',
    nfa: '26'
  },{
    nome: 'Genética Auditiva',
    nfa: '27'
  },{
    nome: 'Fazenda Gairova',
    nfa: '728'
  }];
  opcoesFiltro: any[] = [
    "Somente envios com pendências",
    "Somente envios do ano atual (2019)",
    "Ultimos envios",
    "Todos envios",
  ]
  items: any[] = [{
    dataEnvio: '21/09/2019 08:29',
    protocolo:'12345',
    numeroAnimais: '123',
    identicos: '11',
    aceitos: '22',
    pendentes: '33'
  },{
    dataEnvio: '21/09/2019 08:29',
    protocolo:'12345',
    numeroAnimais: '123',
    identicos: '11',
    aceitos: '22',
    pendentes: '33'
  },{
    dataEnvio: '21/09/2019 08:29',
    protocolo:'12345',
    numeroAnimais: '123',
    identicos: '11',
    aceitos: '22',
    pendentes: '33'
  }];
  error: string;
  success: string;
  section: string = '';

  constructor(private app: AppComponent, private router: Router, private admin: AdminComponent, 
    private modalService: ModalManager) {
    // this.app.isPrivate = true;
  }
  
  openModalGenealogia(animal){
    this.modalRef = this.modalService.open(this.genealogiaModal, {
        size: "xl",
        modalClass: 'genealogiaModal',
        hideCloseButton: false,
        centered: false,
        backdrop: true,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: "modal-backdrop"
    })
}
closeModal(){
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();
}

  ngOnInit() {
    // this.admin.loadModules();
    this.admin._page = 'Processamento Online';
  }

  selecionarView(view) {
    this.section = view;
  }

  selecionarFazenda(fazenda){
    this.tab = 'Status'
  }

}
