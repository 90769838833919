import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";

@Injectable()
export class AnimalService {
  
 

  constructor(private http: HttpClient, private app: AppComponent) {

  }

  checkPDFNFa(nfa){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.get(this.app.URL+"/api/animals/check-pdf/"+nfa, {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  imprimir(list: any[]) {
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.post(this.app.URL+"/api/animals/pdf", list, {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    }).toPromise();
  }

  buscarTodosAnimais(){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.get(this.app.URL+"/api/animals/todos", {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  cancelarSolicitacaoAnimal(CGA){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.get(this.app.URL+"/api/animals/cancelar-solicitacao/"+CGA, {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  cancelarAprovacaoAnimal(CGA){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.get(this.app.URL+"/api/animals/cancelar-aprovacao/"+CGA, {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  retornarNomeAG() {
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.get(this.app.URL+"/api/animals/retornar-ag", {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  retornarDetalhesImpressao(animalId){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.get(this.app.URL+"/api/animals/detalhes-impressao/"+animalId, {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  retornarAnimaisAptosCEIPPorFazendaParaAvaliacao(fazendaId, categoriaId){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.post(this.app.URL+"/api/animals/avaliar-aptos-ceip/filtro",{fazenda: fazendaId, categoria: categoriaId}, {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  retornarAnimaisAptosCEIPPorFazenda(fazendaId, categoriaId){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.post(this.app.URL+"/api/animals/aptos-ceip/filtro",{fazenda: fazendaId, categoria: categoriaId}, {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  retornarAnimaisSolicitadosCEIP(){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.get(this.app.URL+"/api/animals/solicitados-ceip", {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  solicitar(cgas){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.post(this.app.URL+"/api/animals/solicitar",cgas ,{
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  aprovar(cgas){
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));
    
    return this.http.post(this.app.URL+"/api/animals/aprovar",cgas ,{
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }
}
