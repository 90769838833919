import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from 'ngb-modal';

@Component({
    selector: 'ngbd-modal-content',
    template: `
  <modal #myModal>
  <modal-header>
      Modal header content goes there.
  </modal-header>

  <modal-content>
      Modal body content goes there.
      {{name}}
  </modal-content>

  <modal-footer>
      Modal footer content goes there.
  </modal-footer>
</modal>
  `
})
export class ModalGenealogiaComponent {
    @ViewChild(ModalComponent) ModalComponent;
    @Input() name;

    constructor() { }
}