import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './app.api-service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SidebarModule } from 'ng-sidebar';
import { AcessoComponent } from './acesso/acesso.component';
import { ProcessamentoOnlineComponent } from './processamento-online/processamento-online.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngb-modal';
import { ModalGenealogiaComponent } from './processamento-online/modal-genealogia.component';
import { UserService } from './services/user-service';
import { ModuleService } from './services/module-service';
import { FarmerService } from './services/farmer-service';
import { FazendaComponent } from './fazendas/fazenda.component';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { ConfigService } from './services/config-service';
import { MeusDadosComponent } from './meus-dados/meus-dados.component';
import { UsuarioConfirmacaoComponent } from './usuario-confirmacao/usuario-confirmacao.component';
import { LogService } from './services/log-service';
import { CEIPFazendasComponent } from './ceip-fazendas/ceip-fazendas.component';
import { CEIPAnimaisAptosComponent } from './ceip-animais-aptos/ceip-animais-aptos.component';
import { AnimalService } from './services/animal-service';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { CEIPAdminComponent } from './ceip-admin/ceip-admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ToastrModule } from 'ngx-toastr';
import { CEIPAvaliadorAnimaisComponent } from './ceip-avaliador-animais/ceip-avaliador-animais.component';
import { CEIPAvaliadorFazendasComponent } from './ceip-avaliador-fazendas/ceip-avaliador-fazendas.component';
import { ManutencaoOnlineAnimaisComponent } from './manutencao-online-animais/manutencao-online-animais.component';

@NgModule({
  declarations: [
    AppComponent, LoginComponent, AdminComponent, DashboardComponent, AcessoComponent,
    ProcessamentoOnlineComponent, ModalGenealogiaComponent, FazendaComponent, ConfiguracoesComponent,
    MeusDadosComponent, UsuarioConfirmacaoComponent, CEIPFazendasComponent, CEIPAnimaisAptosComponent,CEIPAdminComponent, 
    CEIPAvaliadorAnimaisComponent,CEIPAvaliadorFazendasComponent, ManutencaoOnlineAnimaisComponent
  ],
  imports: [
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    SidebarModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger'
    }),
    NgxLoadingModule.forRoot({}),
    ModalModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [ApiService, AdminComponent, AppComponent, UserService, ModuleService, FarmerService, ConfigService, LogService, AnimalService,DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [ModalGenealogiaComponent]
})
export class AppModule { }
