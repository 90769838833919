import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";

@Injectable()
export class FarmerService {

  constructor(private http: HttpClient, private app: AppComponent) {

  }

  filter(filter): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    if (user)
      return this.http.post(this.app.URL + "/api/farmers/filter", filter, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL + "/api/not-found");
  }



  save(farmer): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    if (user)
      return this.http.post(this.app.URL + "/api/farmers", farmer, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL + "/api/not-found");
  }

  getAll(): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    if (user)
      return this.http.get(this.app.URL + "/api/farmers", {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL + "/api/not-found");
  }

  getFarmersByUserID(id?: string): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');

    if (user) {
      id = id ? id : user.user._id;
      return this.http.get(this.app.URL + "/api/users/" + id + "/farmers", {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    }
    else
      return this.http.get(this.app.URL + "/api/not-found");
  }

  getUsersByFarmerID(id?: string): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');

    if (user) {
      id = id ? id : user.user._id;
      return this.http.get(this.app.URL + "/api/farmers/" + id + "/users", {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    }
    else
      return this.http.get(this.app.URL + "/api/not-found");
  }

  cancelEdit(farmerId): Observable<any> {
    return this.http.get(this.app.URL + "/api/farmers/" + farmerId + "/cancel-edit");
  }

  saveFarmersToUser(userID: any, modules: any[]): Observable<any> {
    var token = localStorage.getItem('ancp-token');
    if (token)
      return this.http.post(this.app.URL + "/api/users/" + userID + "/farmers", modules, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL + "/api/not-found");
  }

  getNfa(): Observable<any> {
    return this.http.get(this.app.URL + "/api/farmers/new-nfa");
  }

  saveRacasToFarmer(farmerId, racaIds): Observable<any> {
    return this.http.post(this.app.URL + "/api/farmers/" + farmerId + "/racas", racaIds);
  }

  getFarmersByOwnerID(ownerID): Observable<any> {
    return this.http.get(this.app.URL + "/api/users/owner/" + ownerID + "/farmers");
  }

  getPendingsByFarmerID(farmerID): Observable<any> {
    return this.http.get(this.app.URL + "/api/farmers/" + farmerID + "/pendings");
  }

  changeDetailsFarmer(farmerPending): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    if (user)
      return this.http.post(this.app.URL + "/api/farmers/" + farmerPending.farmerId + "/edit", farmerPending, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL + "/api/not-found");
  }

  //NEW
  uploadImage(formData, farmerId): Observable<any> {
    return this.http.post(this.app.URL + '/api/farmers/' + farmerId + '/image', formData);
  }
  //ENDNEW


  //#region ANCP Net Atual

  retornarFazendasCeip() {
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));

    return this.http.get(this.app.URL + "/api/farmers/ceip", {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  retornarCategorias() {
    var user = JSON.parse(localStorage.getItem('ancp-net-token'));

    return this.http.get(this.app.URL + "/api/users/categories", {
      headers: new HttpHeaders({
        "authorization": user.token
      })
    });
  }

  /**obtem lista de produtores credenciados */
  accredited(filter) {
    try {
      var user = JSON.parse(localStorage.getItem('ancp-net-token'));      
      return this.http.get(this.app.URL + `/api/dados/credenciamento/${filter}`, {
        headers: new HttpHeaders({
          "authorization": user.token
        })
      }).toPromise();
    } catch (error) {
      console.log(error)
    }
  }

   /**obtem lista de credenciados */
   getAnimaisAvaliados(filter) {
    try {
      var user = JSON.parse(localStorage.getItem('ancp-net-token'));      
      return this.http.get(this.app.URL + `/api/dados/animais_avaliados/${filter}`, {
        headers: new HttpHeaders({
          "authorization": user.token
        })
      }).toPromise();
    } catch (error) {
      console.log(error)
    }
  }

  //#endregion
}
