import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";

@Injectable()
export class ModuleService {

  constructor(private http: HttpClient, private app: AppComponent) {

  }

  getAll(): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    if (user)
      return this.http.get(this.app.URL+"/api/modules", {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL+"/api/not-found");
  }

  getModulesByUserID(id?: string): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    
    if (user){
      id = id ? id : user.user._id;
      return this.http.get(this.app.URL+"/api/users/" + id + "/modules", {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    }      
    else
      return this.http.get(this.app.URL+"/api/not-found");
  }

  saveModules(userID: any, modules: any[]): Observable<any> {
    var token = localStorage.getItem('ancp-token');
    if (token)
    return this.http.post(this.app.URL+"/api/users/"+userID+"/modules", modules, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL+"/api/not-found");    
  }

}
