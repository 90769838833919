import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { FarmerService } from '../services/farmer-service';
import { UserService } from '../services/user-service';
import { ConfigService } from '../services/config-service';
@Component({
  selector: 'app-meus-dados',
  templateUrl: './meus-dados.component.html',
  styleUrls: ['./meus-dados.component.css']
})
export class MeusDadosComponent implements OnInit {

  racas: any[];
  softwares: any[];
  fontesIndicacoes: any[];
  parceiros: any[];
  tiposEnderecos: any[];
  cronogramas: any[];
  combos: any[];

  private: any;
  user: any;
  users: any[];
  farmers: any[];
  logs: any[];

  error: string;
  errorPassword: string;
  successPassword: string;
  success: string;
  tab: string = 'Dados Cadastrais';
  showList: boolean = true;

  filter: any = {
    nfa: '',
    atividade: '',
    criador: '',
    nome: '',
    racaId: '0',
    tipoEnderecoId: '0',
    cidade: '',//
    uf: '',//
    pais: '',//
    ordenarPor: '0',//
    cronogramaId: '0',
    parceiroId: '0',
    ingressoDe: '',
    ingressoAte: '',
    implantacaoDe: '',
    implantacaoAte: '',
    saidaDe: '',
    saidaAte: '',
    tempoSemImplantacao: ''//
  }

  farmer: any = {
    razaoSocial: '',
    nomeFantasia: '',
    cpf: '',
    cnpj: '',
    cep: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    site: '',
    indicacao: '',
    softwareId: '',
    fonteIndicacaoId: '0',
    parceiroId: '0',
    tipoEnderecoId: '0',
    cronogramaId: '0',
    comboId: '0',
    racas: '',
    addresses: [],
    status: 1,
    ownerId: ''
  }

  address = {
    tipoEnderecoId: '',
    cidade: '',
    cep: '',
    estado: '',
    cxPostal: '',
    endereco: '',
    coordenadas: '',
    pais: '',
    fax: '',
    bairro: '',
    telefone: ''
  }

  password = {
    current: '',
    new: '',
    confirm: ''
  }

  constructor(private app: AppComponent, private router: Router, private admin: AdminComponent,
    private farmerService: FarmerService, private userService: UserService, private configService: ConfigService) {
    this.app.isPrivate = true;
  }

  ngOnInit() {
    this.getCombos();
    this.getCronogramas();
    this.getFontesIndicacoes();
    this.getParceiros();
    this.getRacas();
    this.getSoftwares();
    this.getTiposEnderecos();
    this.getUserLogged();
    this.getNfa();
    this.admin.loadModules();
    this.admin._page = 'Dados Cadastrais';
  }

  getFarmers() {
    this.farmerService.getFarmersByOwnerID(this.user._id).subscribe((data: any) => {
      this.farmers = data.data;
    })
  }

  getUserLogged() {
    let _user = JSON.parse(localStorage.getItem('ancp-authentication'));
    this.user = _user.user;
    this.getFarmers();
  }

  updateUser() {
    if (!this.user.name)
      this.error = 'Nome é obrigatório!';
    else {
      this.userService.register(this.user).subscribe((data: any) => {
        if (data.status) {
          this.error = '';
          this.success = data.message;
          localStorage.setItem('ancp-user', this.user.name);
          let token = localStorage.getItem('ancp-token');
          localStorage.setItem('ancp-authentication', JSON.stringify({ token: token, user: this.user }));
        } else {
          this.success = '';
          this.error = data.message;
        }
      })
    }
  }

  updatePassword() {
    if (!this.password.confirm || !this.password.current || !this.password.new) {
      this.error = 'Todos os campos de password são obrigatórios!';
    } else {
      if (!this.password.confirm || !this.password.new) {
        this.error = 'A nova senha precisa ser igual de confirmação!';
      } else {
        this.userService.updatePassword({ new: this.password.new, current: this.password.current }).subscribe((data: any) => {
          if (data.status) {
            this.successPassword = data.message;
            this.errorPassword = '';
            this.password = {
              current: '',
              new: '',
              confirm: ''
            };
          } else {
            this.successPassword = '';
            this.errorPassword = data.message;
          }
        })
      }
    }
  }

  save() {
    let _racasChecked = this.racas.filter(ra => {
      return ra.checked;
    });
    this.farmer.racas = _racasChecked.map(mod => { return mod._id; });

    if (!this.farmer._id)
      this.farmer.addresses.push(this.address);

    this.farmer.ownerId = this.user._id;
    this.farmer.softwareId = null;
    this.farmerService.save(this.farmer).subscribe((data: any) => {

      if (data.status) {
        this.error = '';
        this.success = data.message;
        this.showList = true;
        this.getFarmers();
        if (data.object)
          this.farmer = data.object;
      } else {
        this.success = '';
        this.error = data.message;
      }
    })
  }

  cleanFarmer() {
    this.farmer = {
      nfa: this.farmer.nfa,
      razaoSocial: '',
      nomeFantasia: '',
      cpf: '',
      cnpj: '',
      cep: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      site: '',
      indicacao: '',
      softwareId: '',
      fonteIndicacaoId: '0',
      parceiroId: '0',
      tipoEnderecoId: '0',
      cronogramaId: '0',
      comboId: '0',
      racas: '',
      addresses: [],
      status: 1,
      ownerId: ''
    }
  }

  getLogsFarmers(){
    this.farmerService.getPendingsByFarmerID(this.farmer._id).subscribe((data:any) => {
      this.logs = data.list;
    })
  }

  openDetails(farmer) {
    this.farmer = farmer;
    this.address = farmer.addresses[0];
    this.racas.forEach(ra1 => {
      farmer.racas.forEach(ra2 => {
        if (ra1._id == ra2)
          ra1.checked = true;
      });
    });
    this.showList = false;
    this.success = '';
    this.error = '';
    this.getLogsFarmers();
  }

  getRacas() {
    this.configService.getAll('racas').subscribe((data: any) => {
      this.racas = data.data;
      this.racas.forEach(raca => {
        raca['checked'] = false;
      });
    })
  }

  getSoftwares() {
    this.configService.getAll('softwares').subscribe((data: any) => {
      this.softwares = data.data;
    })
  }

  getFontesIndicacoes() {
    this.configService.getAll('fonteIndicacoes').subscribe((data: any) => {
      this.fontesIndicacoes = data.data;
    })
  }

  getParceiros() {
    this.configService.getAll('parceiros').subscribe((data: any) => {
      this.parceiros = data.data;
    })
  }

  getTiposEnderecos() {
    this.configService.getAll('tipoEnderecos').subscribe((data: any) => {
      this.tiposEnderecos = data.data;
    })
  }

  getCronogramas() {
    this.configService.getAll('cronogramas').subscribe((data: any) => {
      this.cronogramas = data.data;
    })
  }

  getCombos() {
    this.configService.getAll('combos').subscribe((data: any) => {
      this.combos = data.data;
    })
  }

  getNfa() {
    this.farmerService.getNfa().subscribe((data: any) => {
      this.farmer.nfa = data.data;
    })
  }
}
