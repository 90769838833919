import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { FarmerService } from '../services/farmer-service';
import { AnimalService } from '../services/animal-service';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { ModalManager } from 'ngb-modal';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';

// import * as XLSX from 'xlsx';
@Component({
  selector: 'app-ceip-admin',
  templateUrl: './ceip-admin.component.html',
  styleUrls: ['./ceip-admin.component.css']
})

export class CEIPAdminComponent implements OnInit {

  allchecked: boolean = false;
  todosAnimais: any[] = []
  doc2 = new jsPDF();
  filtrado: boolean = false;
  temG: boolean = false;
  animais: any[] = [];
  animaisSolicitados: any[] = [];
  animalSelecionado: any;
  animaisTotal: any[] = [];
  animaisFiltrados: any[] = [];
  fazenda: any = '';
  page: any = 1;
  pageSize: any = 10;
  search: string = '';
  showTable: boolean = false;
  showTableTodos: boolean = false;
  view: string = 'aptos';
  impressao: any = {
    ceip: '',
    animal: {
      nome: '',
      raca: '',
      dataNascimento: '',
      sexo: '',
      baseGenetica: '',
      sistemaAvaliacao: ''
    },
    valores: {
      dippg: '',
      dippgtop: '',
      d3pg: '',
      d3pgtop: '',
      mp120g: '',
      mp120gtop: '',
      mp210g: '',
      mp210gtop: '',
      dp210g: '',
      dp210gtop: '',
      dp450g: '',
      dp450gtop: '',
      dstayg: '',
      dstaygtop: '',
      dpe365g: '',
      dpe365gtop: '',
      dpe450g: '',
      dpe450gtop: '',
      daolg: '',
      daolgtop: '',
      mgte: '',
      mgtetop: ''
    },
    avoPaterno: {
      serie: '---',
      rgn: '---',
      rgd: '---',
      nome: '---',
      mgte: '',
      top: ''
    },
    avomPaterno: {
      serie: '---',
      rgn: '---',
      rgd: '---',
      nome: '---',
      mgte: '',
      top: ''
    },
    avoMaterno: {
      serie: '---',
      rgn: '---',
      rgd: '---',
      nome: '---',
      mgte: '',
      top: ''
    },
    avomMaterno: {
      serie: '---',
      rgn: '---',
      rgd: '---',
      nome: '---',
      mgte: '',
      top: ''
    },
    pai: {
      serie: '---',
      rgn: '---',
      rgd: '---',
      nome: '---',
      mgte: '',
      top: '',
      raca: '---'
    },
    mae: {
      serie: '---',
      rgn: '---',
      rgd: '---',
      nome: '---',
      mgte: '',
      top: '',
      raca: '---'
    },
    dataEmissao: null,
    localEmissao: 'Ribeirão Preto, estado de São Paulo',
    rodape: 'A RAÇA OU O PERCENTUAL DA COMPOSIÇÃO RACIAL DESTE ANIMAL SOMENTE SERÁ COMPROVADA POR MEIO DOS CERTIFICADOS DE REGISTRO GENEALÓGICO OU CERTIFICADO DE CONTROLE DE GENEALOGIA, EMITIDOS PELA RESPECTIVA ASSOCIAÇÃO DE CRIADORES DA RAÇA.'
  }

  filtroAvancado: any = {
    rgnregra: 'Igual',
    rgdregra: 'Igual',
    dataNascimentoregra: 'Igual',
    mgtregra: 'Igual',
    topregra: 'Igual',
    sexo: 'm',
    raca: '',
    variedade: '',
    categoria: '',
    nfa: '',
    LOGIN: '',
    aprovador: ''
  };
  showFilter: boolean = false;
  regras: string[] = [
    'Igual',
    'Menor',
    'Maior'
  ]
  atualCampoOrdem: string = '';
  contadorOrdem: number = 0;

  //selects filtro avançado
  filtroSexos: any[] = [
    { key: 'Macho', value: 'Macho' },
    { key: 'femea', value: 'Fêmea' },
    { key: 'm', value: 'Ambos' },
  ]
  filtroRacas: any[] = [];
  filtroCategorias: any[] = [];
  filtroFazendas: any[] = [];
  filtroVariedades: any[] = [];
  filtroSolicitantes: any[] = [];
  filtroAprovadores: any[] = [];
  filtroRacasSolicitado: any[] = [];
  filtroCategoriasSolicitado: any[] = [];
  filtroVariedadesSolicitado: any[] = [];
  filtroFazendasSolicitado: any[] = [];
  filtroSolicitantesSolicitado: any[] = [];
  filtroAprovadoresSolicitado: any[] = [];
  p: number = 0;

  @ViewChild('myModal') myModal;
  private modalRef;

  keysObject: any = {
    aptos: {
      keys: ['NOME', 'Serie', 'RGN', 'RGD', 'DataNascimento', 'MGT', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raca', 'Sexo', 'LOGIN', 'DataSolicitacao', 'LOGIN_APROVACAO', 'DataVistoria', 'DataLimite'],
      header: ['Nome', 'Série', 'RGN', 'RGD', 'DT Nasc', 'MGTe', 'TOP',
        'Categ.', 'Varied.', 'NFA', 'Raça', 'Sexo', 'Solicitante', 'Data da Solicitação', 'Consultor', 'Data da Vistoria', 'Limite Emissão']
    },
    solicitados: {
      keys: ['NOME', 'Serie', 'RGN', 'RGD', 'DataNascimento', 'MGT', 'TOP',
        'Categoria', 'Variedade', 'NFA', 'Raca', 'Sexo', 'LOGIN', 'DataSolicitacao', 'LOGIN_APROVACAO', 'DataVistoria', 'DataLimite', 'UltimaImpressao'],
      header: ['Nome', 'Série', 'RGN', 'RGD', 'DT Nasc', 'MGTe', 'TOP',
        'Categ.', 'Varied.', 'NFA', 'Raça', 'Sexo', 'Solicitante', 'Data da Solicitação', 'Consultor', 'Data da Vistoria', 'Limite Emissão', 'Impressão']
    }
  };

  colunasApto: any[] = [
    { key: 'Nome', value: false, field: 'NOME', width: 7 }, { key: 'Série', value: false, field: 'Serie', width: 5 }, { key: 'RGN', value: false, field: 'RGN', width: 5 }, { key: 'RGD', value: false, field: 'RGD', width: 5 },
    { key: 'DT Nasc', value: false, field: 'DataNascimento', width: 8 }, { key: 'MGTe/IGE', value: false, field: 'MGT', width: 5 }, { key: 'TOP', value: false, field: 'TOP', width: 4 },
    { key: 'Categ.', value: false, field: 'Categoria', width: 5 }, { key: 'Varied.', value: false, field: 'Variedade', width: 6 },
    { key: 'NFA', value: false, field: 'NFA', width: 4 }, { key: 'Raça', value: false, field: 'Raca', width: 5 }, { key: 'Sexo', value: false, field: 'Sexo', width: 5 },
    { key: 'Solicitante', value: false, field: 'LOGIN', width: 8 }, { key: 'Data da Solicitação', value: false, field: 'DataSolicitacao', width: 8 },
    { key: 'Consultor', value: false, field: 'LOGIN_APROVACAO', width: 8 }, { key: 'Data da Vistoria', value: false, field: 'DataVistoria', width: 8 }, { key: 'Limite Emissão', value: false, field: 'DataLimite', width: 8 }];
  colunasSolicitados: any[] = [
    { key: 'Nome', value: false, field: 'NOME', width: 7 }, { key: 'Série', value: false, field: 'Serie', width: 5 }, { key: 'RGN', value: false, field: 'RGN', width: 5 }, { key: 'RGD', value: false, field: 'RGD', width: 5 },
    { key: 'DT Nasc', value: false, field: 'DataNascimento', width: 8 }, { key: 'MGTe/IGE', value: false, field: 'MGT', width: 5 }, { key: 'TOP', value: false, field: 'TOP', width: 4 },
    { key: 'Categ.', value: false, field: 'Categoria', width: 5 }, { key: 'Varied.', value: false, field: 'Variedade', width: 6 },
    { key: 'NFA', value: false, field: 'NFA', width: 4 }, { key: 'Raça', value: false, field: 'Raca', width: 5 }, { key: 'Sexo', value: false, field: 'Sexo', width: 5 },
    { key: 'Solicitante', value: false, field: 'LOGIN', width: 8 }, { key: 'Data da Solicitação', value: false, field: 'DataSolicitacao', width: 8 },
    { key: 'Consultor', value: false, field: 'LOGIN_APROVACAO', width: 8 }, { key: 'Data da Vistoria', value: false, field: 'DataVistoria', width: 8 }, { key: 'Limite Emissão', value: false, field: 'DataLimite', width: 8 },
    { key: 'Impressão', value: false, field: 'UltimaImpressao', width: 8 }];

  constructor(private animalService: AnimalService, private modalService: ModalManager, private datePipe: DatePipe,
    private app: AppComponent, private route: ActivatedRoute, private toastr: ToastrService, private farmerService: FarmerService) {

  }

  ngOnInit() {
    this.app.isPrivate = true;
    this.fazenda = this.route.snapshot.queryParams.fazenda;
    this.validarFazenda();
  }

  impressao2() {
    var doc = new jsPDF();
    doc.setFont("Verdana, Geneva, Tahoma, sans-serif");
    doc.setFontSize(10);
    doc.setFontStyle("bold");
    doc.text("CEIP: ", 95, 40, null, null, "center");
    doc.rect(100, 35, 30, 7);
    //doc.setFontStyle("normal");
    doc.text(this.impressao.ceip, 110, 40, null, null, "center");
    doc.rect(18, 45, 185, 17);
    doc.setFontStyle("bold");
    doc.text("Animal: ", 20, 50, null, null, "left");
    doc.text("Data de Nascimento: ", 20, 55, null, null, "left");
    doc.text("Base Genética Utilizada: ", 20, 60, null, null, "left");
    doc.text("Raça ou Composição Racial: ", 115, 50, null, null, "left");
    doc.text("Sexo: ", 115, 55, null, null, "left");
    doc.text("Sistema de Avaliação: ", 115, 60, null, null, "left");
    doc.setFontStyle("normal");
    doc.text(`${this.impressao.animal.Serie ? this.impressao.animal.Serie : '---'} / ${this.impressao.animal.RGN ? this.impressao.animal.RGN : '---'} / ${this.impressao.animal.RGD ? this.impressao.animal.RGD : '---'} / ${this.impressao.animal.nome ? this.impressao.animal.nome : '---'}`, 33, 50, null, null, "left");
    doc.text(`${this.datePipe.transform(this.impressao.animal.dataNascimento, 'dd/MM/yyyy')}`, 52, 55, null, null, "left");
    doc.text(`${this.impressao.animal.baseGenetica}`, 58, 60, null, null, "left");
    doc.text(`${this.impressao.animal.raca}`, 159, 50, null, null, "left");
    doc.text(`${this.impressao.animal.sexo}`, 124, 55, null, null, "left");
    doc.text(`${this.impressao.animal.sistemaAvaliacao}`, 149, 60, null, null, "left");

    doc.setFontStyle("bold");
    doc.setFontSize(8);
    if (this.animalSelecionado.Categoria == 'CL') {
      doc.text(`D3P${this.impressao.temG ? 'G' : ''}`, 26, 66, null, null, "center");
      doc.text(`MP120${this.impressao.temG ? 'G' : ''}`, 45, 66, null, null, "center");
      doc.text(`MP210${this.impressao.temG ? 'G' : ''}`, 64, 66, null, null, "center");
      doc.text(`DP210${this.impressao.temG ? 'G' : ''}`, 83, 66, null, null, "center");
      doc.text(`DP450${this.impressao.temG ? 'G' : ''}`, 102, 66, null, null, "center");
      doc.text(`DSTAY${this.impressao.temG ? 'G' : ''}`, 121, 66, null, null, "center");
      // doc.text(`DPE365${this.impressao.temG ? 'G' : ''}`, 127, 66, null, null, "center");
      doc.text(`DPE450${this.impressao.temG ? 'G' : ''}`, 140, 66, null, null, "center");
      doc.text(`DAOL${this.impressao.temG ? 'G' : ''}`, 159, 66, null, null, "center");
      doc.text(`DACAB${this.impressao.temG ? 'G' : ''}`, 177, 66, null, null, "center");
      doc.text(`IGE`, 195, 66, null, null, "center");
      doc.rect(19, 67, 15, 8);
      doc.rect(38, 67, 15, 8);
      doc.rect(57, 67, 15, 8);
      doc.rect(76, 67, 15, 8);
      doc.rect(95, 67, 15, 8);
      doc.rect(114, 67, 15, 8);
      // doc.rect(120, 67, 14, 8);
      doc.rect(133, 67, 15, 8);
      doc.rect(152, 67, 15, 8);
      doc.rect(170, 67, 15, 8);
      doc.setDrawColor(0);
      doc.setFillColor(180, 180, 180);
      doc.rect(188, 67, 15, 8, "F");
      doc.setFontSize(10);
      // doc.setFontStyle("normal");
      doc.text(`${parseFloat(this.impressao.valores.D3P).toFixed(2)}`, 26, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.MP120).toFixed(2)}`, 45, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.MP210).toFixed(2)}`, 64, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DP210).toFixed(2)}`, 83, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DP450).toFixed(2)}`, 102, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DSTAY).toFixed(2)}`, 121, 71, null, null, "center");
      // doc.text(`${parseFloat(this.impressao.valores.DPE365).toFixed(2)}`, 127, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DPE450).toFixed(2)}`, 140, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DAOL).toFixed(2)}`, 159, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DACAB).toFixed(2)}`, 177, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.MGT).toFixed(2)}`, 195, 71, null, null, "center");

      doc.setFontSize(7);
      doc.text(`TOP: ${this.impressao.valores.D3Ptop}%`, 27, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.MP120top}%`, 46, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.MP210top}%`, 65, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DP210top}%`, 84, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DP450top}%`, 103, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DSTAYtop}%`, 122, 74, null, null, "center");
      // doc.text(`TOP: ${this.impressao.valores.DPE365top}%`, 127, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DPE450top}%`, 141, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DAOLtop}%`, 160, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DACABtop}%`, 178, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.MGTtop}%`, 196, 74, null, null, "center");
    } else {
      doc.text(`D3P${this.impressao.temG ? 'G' : ''}`, 26, 66, null, null, "center");
      doc.text(`MP120${this.impressao.temG ? 'G' : ''}`, 47, 66, null, null, "center");
      doc.text(`DP210${this.impressao.temG ? 'G' : ''}`, 68, 66, null, null, "center");
      doc.text(`DP450${this.impressao.temG ? 'G' : ''}`, 89, 66, null, null, "center");
      doc.text(`DSTAY${this.impressao.temG ? 'G' : ''}`, 110, 66, null, null, "center");
      doc.text(`DPE365${this.impressao.temG ? 'G' : ''}`, 131, 66, null, null, "center");
      doc.text(`DAOL${this.impressao.temG ? 'G' : ''}`, 152, 66, null, null, "center");
      doc.text(`DACAB${this.impressao.temG ? 'G' : ''}`, 173, 66, null, null, "center");
      doc.text(`MGTe`, 194, 66, null, null, "center");

      doc.rect(18, 67, 17, 8);
      doc.rect(39, 67, 17, 8);
      doc.rect(60, 67, 17, 8);
      doc.rect(81, 67, 17, 8);
      doc.rect(102, 67, 17, 8);
      doc.rect(123, 67, 17, 8);
      doc.rect(144, 67, 17, 8);
      doc.rect(165, 67, 17, 8);
      doc.setDrawColor(0);
      doc.setFillColor(180, 180, 180);
      doc.rect(186, 67, 17, 8, "F");
      doc.setFontSize(10);
      // doc.setFontStyle("normal");

      doc.text(`${parseFloat(this.impressao.valores.D3P).toFixed(2)}`, 26, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.MP120).toFixed(2)}`, 47, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DP210).toFixed(2)}`, 68, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DP450).toFixed(2)}`, 89, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DSTAY).toFixed(2)}`, 110, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DPE365).toFixed(2)}`, 131, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DAOL).toFixed(2)}`, 152, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.DACAB).toFixed(2)}`, 173, 71, null, null, "center");
      doc.text(`${parseFloat(this.impressao.valores.MGT).toFixed(2)}`, 194, 71, null, null, "center");

      doc.setFontSize(7);
      doc.text(`TOP: ${this.impressao.valores.D3Ptop}%`, 26, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.MP120top}%`, 47, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DP210top}%`, 68, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DP450top}%`, 89, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DSTAYtop}%`, 110, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DPE365top}%`, 131, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DAOLtop}%`, 152, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.DACABtop}%`, 173, 74, null, null, "center");
      doc.text(`TOP: ${this.impressao.valores.MGTtop}%`, 194, 74, null, null, "center");
    }


    doc.setFontSize(10);
    doc.setFontStyle("bold");
    doc.text("Avós Paternos ", 45, 79, null, null, "left");
    doc.rect(18, 80, 77, 10);
    doc.text("Avós Maternos ", 150, 79, null, null, "left");
    doc.rect(126, 80, 77, 10);
    doc.setFontStyle("normal");
    doc.setFontSize(9);
    if (this.impressao.avoPaterno == null) {
      doc.text('---', 55, 88, null, null, "center");
    } else {
      doc.text(`${this.impressao.avoPaterno.serie ? this.impressao.avoPaterno.serie : '---'} / ${this.impressao.avoPaterno.rgn ? this.impressao.avoPaterno.rgn : '---'} / ${this.impressao.avoPaterno.rgd ? this.impressao.avoPaterno.rgd : '---'} - ${this.impressao.avoPaterno.nome}`, 56, 84, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.avoPaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avoPaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avoPaterno.top}%</p>`, 40, 81, null, null, "center");
    }
    debugger;
    if (this.impressao.avoMaterno == null) {
      doc.text('---', 168, 88, null, null, "center");
    } else {
      doc.setFontStyle("normal");
      doc.setFontSize(9);
      doc.text(`${this.impressao.avoMaterno.serie ? this.impressao.avoMaterno.serie : '---'} / ${this.impressao.avoMaterno.rgn ? this.impressao.avoMaterno.rgn : '---'} / ${this.impressao.avoMaterno.rgd ? this.impressao.avoMaterno.rgd : '---'} - ${this.impressao.avoMaterno.nome}`, 164, 84, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.avoMaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avoMaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avoMaterno.top}%</p>`, 147, 81, null, null, "center");
    }

    doc.setFontStyle("bold");
    doc.setFontSize(10);
    doc.text("Pai", 108, 89, null, null, "right");
    doc.rect(32, 90, 76, 14);
    doc.text("Mãe", 111, 89, null, null, "left");
    doc.rect(110, 90, 76, 14);
    doc.setFontStyle("normal");
    doc.setFontSize(9);
    // if (this.impressao.pai == null) {
    //   doc.text('---', 72, 103, null, null, "center");
    // } else {
    //   doc.text(`${this.impressao.pai.serie ? this.impressao.pai.serie : '---'} / ${this.impressao.pai.rgn ? this.impressao.pai.rgn : '---'} / ${this.impressao.pai.rgd ? this.impressao.pai.rgd : '---'} - ${this.impressao.pai.nome}`, 106, 98, null, null, "right");
    //   doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.pai.cat != 'CL' ? 'MGTe' : 'IGE'}:</b> ${parseFloat(this.impressao.pai.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.pai.top}%</p>`, 73, 96, null, null, "right");
    //   doc.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.pai.raca}</p>`, 48, 101, null, null, "right");
    // }

    // if (this.impressao.mae == null) {
    //   doc.text('---', 150, 103, null, null, "center");
    // } else {
    //   doc.setFontStyle("normal");
    //   doc.setFontSize(9);
    //   doc.text(`${this.impressao.mae.serie ? this.impressao.mae.serie : '---'} / ${this.impressao.mae.rgn ? this.impressao.mae.rgn : '---'} / ${this.impressao.mae.rgd ? this.impressao.mae.rgd : '---'} - ${this.impressao.mae.nome}`, 112, 98, null, null, "left");
    //   doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.mae.cat != 'CL' ? 'MGTe' : 'IGE'}:</b> ${parseFloat(this.impressao.mae.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.mae.top}%</p>`, 112, 96, null, null, "right");
    //   doc.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.mae.raca}</p>`, 112, 101, null, null, "right");
    // }

    if (this.impressao.pai == null) {
      doc.text('---', 72, 99, null, null, "center");
    } else {
      doc.text(`${this.impressao.pai.serie ? this.impressao.pai.serie : '---'} / ${this.impressao.pai.rgn ? this.impressao.pai.rgn : '---'} / ${this.impressao.pai.rgd ? this.impressao.pai.rgd : '---'} - ${this.impressao.pai.nome}`, 70, 94, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.pai.nomecaracteristica}:</b> ${parseFloat(this.impressao.pai.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.pai.top}%</p>`, 56, 91, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.pai.raca}</p>`, 42, 95, null, null, "center");
    }

    if (this.impressao.mae == null) {
      doc.text('---', 150, 99, null, null, "center");
    } else {
      doc.setFontStyle("normal");
      doc.setFontSize(9);
      doc.text(`${this.impressao.mae.serie ? this.impressao.mae.serie : '---'} / ${this.impressao.mae.rgn ? this.impressao.mae.rgn : '---'} / ${this.impressao.mae.rgd ? this.impressao.mae.rgd : '---'} - ${this.impressao.mae.nome}`, 149, 94, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.mae.nomecaracteristica}:</b> ${parseFloat(this.impressao.mae.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.mae.top}%</p>`, 134, 91, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.mae.raca}</p>`, 120, 95, null, null, "center");
    }


    doc.rect(18, 104, 77, 10);
    doc.rect(126, 104, 77, 10);
    doc.setFontStyle("normal");
    doc.setFontSize(9);
    if (this.impressao.avomPaterno == null) {
      doc.text('---', 55, 111, null, null, "center");
    } else {
      doc.text(`${this.impressao.avomPaterno.serie ? this.impressao.avomPaterno.serie : '---'} / ${this.impressao.avomPaterno.rgn ? this.impressao.avomPaterno.rgn : '---'} / ${this.impressao.avomPaterno.rgd ? this.impressao.avomPaterno.rgd : '---'} - ${this.impressao.avomPaterno.nome}`, 56, 108, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.avomPaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avomPaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avomPaterno.top}%</p>`, 40, 105, null, null, "center");
    }

    if (this.impressao.avomMaterno == null) {
      doc.text('---', 168, 111, null, null, "right");

    } else {
      doc.setFontStyle("normal");
      doc.setFontSize(9);
      doc.text(`${this.impressao.avomMaterno.serie ? this.impressao.avomMaterno.serie : '---'} / ${this.impressao.avomMaterno.rgn ? this.impressao.avomMaterno.rgn : '---'} / ${this.impressao.avomMaterno.rgd ? this.impressao.avomMaterno.rgd : '---'} - ${this.impressao.avomMaterno.nome}`, 164, 108, null, null, "center");
      doc.fromHTML(`<p style="font-size:12"><b>${this.impressao.avomMaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avomMaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avomMaterno.top}%</p>`, 147, 105, null, null, "center");
    }


    doc.rect(18, 115, 185, 19);
    doc.text("________________________________________", 109, 125, null, null, "center");
    doc.setFontStyle("italic");
    doc.text(`${this.animalSelecionado.Categoria != 'CL' ? 'Raysildo Barbosa Lôbo' : 'Letícia Mendes de Castro'}`, 111, 129, null, null, "center");
    doc.setFontStyle("bold");
    doc.fromHTML(`<p style="font-size:10"><b>Responsável Técnico</b></p>`, 82, 126, null, null, "center");
    doc.fromHTML(`<p style="font-size:10"><b>${this.animalSelecionado.Categoria != 'CL' ? 'CRMV: 4 - 1967' : 'CRMV: GO 04943'}</b></p>`, 115, 126, null, null, "center");
    // doc.text("Responsável Técnico", 90, 136, null, null, "center");
    // doc.text(`${this.animalSelecionado.Categoria != 'CL' ? 'CRMV/CREA: 4 - 1967' : 'CRMV: GO 04943'}`, 135, 136, null, null, "center");
    doc.setFontSize(8);
    doc.fromHTML(`<p style="font-size:10"><b>Data de Emissão: </b> ${this.datePipe.transform(this.impressao.dataEmissao, 'dd/MM/yyyy')}</p>`, 40, 130, null, null, "center");
    doc.fromHTML(`<p style="font-size:10"><b>Local de Emissão: </b> Ribeirão Preto / SP</p>`, 135, 130, null, null, "center");
    // doc.text(":", 50, 140, null, null, "center");
    // doc.text("Local de Emissão:", 145, 140, null, null, "center");
    // doc.setFontStyle("normal");
    // doc.text(``, 70, 140, null, null, "center");
    // doc.text("Ribeirão Preto / SP", 170, 140, null, null, "center");
    doc.setFontSize(6);
    doc.setFontStyle("bold");
    doc.text("A RAÇA OU O PERCENTUAL DA COMPOSIÇÃO RACIAL DESTE ANIMAL SOMENTE SERÁ COMPROVADA POR MEIO DOS CERTIFICADOS DE REGISTRO GENEALÓGICO  ", 110, 141, null, null, "center");
    doc.text("OU CERTIFICADO DE CONTROLE DE GENEALOGIA, EMITIDOS PELA RESPECTIVA ASSOCIAÇÃO DE CRIADORES DA RAÇA.", 110, 144, null, null, "center");
    //  
    doc.save('certificado-ancp.pdf');
  }

  openModal() {
    this.colunasApto.forEach(item => {
      item.value = false;
    });
    this.colunasSolicitados.forEach(item => {
      item.value = false;
    });
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'mymodal',
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }
  closeModal() {
    this.modalService.close(this.modalRef);
  }

  filtrarPDF() {
    var itemsChecked = this.view == 'aptos' ? this.colunasApto.filter(ani => {
      return ani.value;
    }) : this.colunasSolicitados.filter(ani => {
      return ani.value;
    });

    //Agrupar
    var helper = {};
    let result = itemsChecked.map(a => a.key);
    let name = '';
    var animals = [];
    //Aptos não filtrados
    if (this.filtrado) {
      animals = this.animaisFiltrados;
    } else {
      if (this.view == 'aptos') {
        var animalsSelected = this.animais.filter(ani => {
          return ani.checked;
        });
        animals = (animalsSelected.length > 0) ? animalsSelected : this.animais;
      } else {
        animals = this.animaisSolicitados;

      }
    }
    name = 'animais-aptos-ceip-';

    var result2 = animals.reduce(function (r, o) {
      var title = '';
      itemsChecked.forEach(element => {
        if (element.field.includes('Data') || element.field.includes('UltimaImpressao')) {
          var date = new Date(o[element.field]);
          let month = String(date.getMonth() + 1);
          let day = String(date.getDate());
          const year = String(date.getFullYear());

          if (month.length < 2) month = '0' + month;
          if (day.length < 2) day = '0' + day;

          title = title + `${title == '' ? '' : ' > '} ${element.key}: ${day}/${month}/${year}`;
        } else if (element.field.includes('NFA')) {
          title = title + `${title == '' ? '' : ' > '} ${element.key}: ${o[element.field]} - ${o['NOME_FANTASIA']}`;
        } else
          title = title + `${title == '' ? '' : ' > '} ${element.key}: ${o[element.field]}`;
      });

      if (!helper[title]) {
        var items = []
        items.push(o);
        helper[title] = items;
        r.push(helper[title]);
      } else {
        helper[title].push(o);
      }
      return r;
    }, []);

    this.printEditable(helper, result, name);

  }

  checkItem(item, value) {
    return item == value;
  }
  start = 10;
  printEditable(animals, keysSelected, name) {

    this.app.loading = true;
    setTimeout(() => {

      const doc = new jsPDF('l');
      var titles = Object.keys(animals);

      var indexToSkip = []
      keysSelected.forEach(key => {
        if (this.view == 'aptos')
          indexToSkip.push(this.colunasApto.map(function (e) { return e.key; }).indexOf(key));
        else
          indexToSkip.push(this.colunasSolicitados.map(function (e) { return e.key; }).indexOf(key));
      });



      titles.forEach(title => {
        var allvalues = [];

        animals[title].forEach(animal => {
          var values = [];
          var countValue = 0;
          var removeSolicitacao = false;
          var removeVistoria = false;
          if (this.view == 'aptos') {
            this.keysObject.aptos.keys.forEach(key => {
              var itCan = indexToSkip.filter(item => item == countValue);
              if (itCan.length == 0) {
                if (key.includes('LOGIN')) {
                  if (key.includes('LOGIN_APROVACAO')) {
                    var date = new Date(animal['DataVistoria']);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(animal[key] + '\n' + `${day}/${month}/${year}`);
                    removeVistoria = true;
                  } else {
                    var date = new Date(animal['DataSolicitacao']);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(animal[key] + '\n' + `${day}/${month}/${year}`);
                    removeSolicitacao = true;
                  }
                } else if (key.includes('Data')) {
                  if ((key.includes('DataVistoria') && removeVistoria) || (key.includes('DataSolicitacao') && removeSolicitacao))
                    console.log();
                  else {
                    var date = new Date(animal[key]);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(`${day}/${month}/${year}`);
                  }
                }
                else if (key.includes('MGT')) {

                  values.push(parseFloat(animal[key]).toFixed(2));
                } else
                  values.push(animal[key]);
              } else {
                if (key == 'LOGIN')
                  removeSolicitacao = true;
                if (key == 'LOGIN_APROVACAO')
                  removeVistoria = true;
              }
              countValue = countValue + 1;
            });
          } else {
            this.keysObject.solicitados.keys.forEach(key => {

              var itCan = indexToSkip.filter(item => item == countValue);
              if (itCan.length == 0) {
                if (key.includes('LOGIN')) {
                  if (key.includes('LOGIN_APROVACAO')) {
                    var date = new Date(animal['DataVistoria']);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(animal[key] + '\n' + `${day}/${month}/${year}`);
                    removeVistoria = true;
                  } else {
                    var date = new Date(animal['DataSolicitacao']);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(animal[key] + '\n' + `${day}/${month}/${year}`);
                    removeSolicitacao = true;
                  }
                } else if (key.includes('Data') || key.includes('UltimaImpressao')) {
                  if ((key.includes('DataVistoria') && removeVistoria) || (key.includes('DataSolicitacao') && removeSolicitacao))
                    console.log();
                  else {
                    var date = new Date(animal[key]);
                    let month = String(date.getMonth() + 1);
                    let day = String(date.getDate());
                    const year = String(date.getFullYear());

                    if (month.length < 2) month = '0' + month;
                    if (day.length < 2) day = '0' + day;
                    values.push(`${day}/${month}/${year}`);
                  }
                }
                else if (key.includes('MGT')) {

                  values.push(parseFloat(animal[key]).toFixed(2));
                } else
                  values.push(animal[key]);
              } else {
                if (key == 'LOGIN')
                  removeSolicitacao = true;
                if (key == 'LOGIN_APROVACAO')
                  removeVistoria = true;
              }
              countValue = countValue + 1;
            });
          }

          allvalues.push(values);

        });

        // if (doc.lastAutoTable) {
        //   debugger;
        //   doc.addPage();
        // }


        var realHeader = [];
        var countKey = 0;
        if (this.view == 'aptos')
          this.keysObject.aptos.header.forEach(header => {
            var itCan = indexToSkip.filter(item => item == countKey);
            if (itCan.length == 0 && header != 'Data da Solicitação' && header != 'Data da Vistoria') {
              if (header.includes('MGT'))
                header = 'MGTe/IGE';

              realHeader.push(header);
            }
            countKey = countKey + 1;
          })
        else
          this.keysObject.solicitados.header.forEach(header => {
            var itCan = indexToSkip.filter(item => item == countKey);
            if (itCan.length == 0 && header != 'Data da Solicitação' && header != 'Data da Vistoria') {
              if (header.includes('MGT'))
                header = 'MGTe/IGE';

              realHeader.push(header);
            }
            countKey = countKey + 1;
          })

        var object = {};
        var totalPixel = 280;
        var totalColunas = 15;
        var colunasRestantes = totalColunas - realHeader.length;
        var totalPixelUtilizado = 0;
        realHeader.forEach((element, index) => {
          var item: any;
          if (this.view == 'aptos')
            item = this.colunasApto.filter(item => item.key == element)[0];
          else
            item = this.colunasSolicitados.filter(item => item.key == element)[0];

          item['total'] = item.width * totalPixel / 100;
          totalPixelUtilizado = totalPixelUtilizado + item['total'];
        });
        var totalPixelRestante = totalPixel - totalPixelUtilizado;
        var contadorFinal = 0;
        realHeader.forEach((element, index) => {

          var item: any;
          if (this.view == 'aptos')
            item = this.colunasApto.filter(item => item.key == element)[0];
          else
            item = this.colunasSolicitados.filter(item => item.key == element)[0];
          var total = 0;
          if (colunasRestantes > 0) {
            var total = item.width * totalPixelRestante / 100;
            contadorFinal = contadorFinal + total;
            item['total'] = total + item['total'];
          }
          object[index] = { cellWidth: item['total'] };
        });

        this.start = doc.lastAutoTable ? doc.lastAutoTable.cursor.y + 10 : 10;
        console.log(this.start);

        doc.text(15, this.start, title ? title : '');
        this.start = this.start + 5;
        doc.autoTable({
          head: [realHeader],
          body: allvalues,
          startY: this.start,
          columnStyles: object,
          margin: { left: 10 }
        })
      });
      doc.save(name + new Date().toISOString().slice(0, 10) + '.pdf');
      this.app.loading = false;
    }, 2000)
  }

  validarFazenda() {
    this.buscarAnimais();
    this.buscarTodosAnimais();
  }

  limparFiltroAvancado() {
    this.filtroAvancado = {
      rgnregra: 'Igual',
      rgdregra: 'Igual',
      dataNascimentoregra: 'Igual',
      mgtregra: 'Igual',
      topregra: 'Igual',
      sexo: 'm',
      raca: '',
      variedade: '',
      categoria: '',
      nfa: '',
      LOGIN: '',
      aprovador: ''
    };
    this.showFilter = false;
    this.animaisFiltrados = [];
    this.filtrado = false;
    this.p = 0;
  }

  filtrarAvancado() {
    if (this.view == 'aptos') {
      this.animaisFiltrados = this.animais.filter(ani => {
        return ani.NOME.toLowerCase().includes(this.filtroAvancado.nome ? this.filtroAvancado.nome.toLowerCase() : '') &&
          ani.Serie.toLowerCase().includes(this.filtroAvancado.serie ? this.filtroAvancado.serie.toLowerCase() : '') &&
          ani.RGN.toLowerCase().includes(this.filtroAvancado.rgn ? this.filtroAvancado.rgn.toLowerCase() : '') &&
          // (this.filtroAvancado.rgnregra == 'Igual' && this.filtroAvancado.rgn ? parseInt(ani.RGN) == parseInt(this.filtroAvancado.rgn) :
          //   this.filtroAvancado.rgnregra == 'Maior' && this.filtroAvancado.rgn ? parseInt(ani.RGN) > parseInt(this.filtroAvancado.rgn) :
          //     this.filtroAvancado.rgnregra == 'Menor' && this.filtroAvancado.rgn ? parseInt(ani.RGN) < parseInt(this.filtroAvancado.rgn) :
          //       ani.RGN.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.rgdregra == 'Igual' && this.filtroAvancado.rgd ? parseInt(ani.RGD) == parseInt(this.filtroAvancado.rgd) :
            this.filtroAvancado.rgdregra == 'Maior' && this.filtroAvancado.rgd ? parseInt(ani.RGD) > parseInt(this.filtroAvancado.rgd) :
              this.filtroAvancado.rgdregra == 'Menor' && this.filtroAvancado.rgd ? parseInt(ani.RGD) < parseInt(this.filtroAvancado.rgd) :
                ani.RGD.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.dataNascimentoregra == 'Igual' ?
            ani.dataFormatada.toString().toLowerCase().includes(this.filtroAvancado.dataNascimento ? this.filtroAvancado.dataNascimento.toLowerCase() : '') :
            this.filtroAvancado.dataNascimento == 'Maior' ? ani.dataFormatada >= this.filtroAvancado.dataNascimento :
              this.filtroAvancado.dataNascimento == 'Menor' ? ani.dataFormatada <= this.filtroAvancado.dataNascimento :
                ani.dataFormatada.toString().toLowerCase().includes('')) &&
          //ani.DataNascimento.toLowerCase().includes(this.filtroAvancado.dataNascimento && this.filtroAvancado.dataNascimentoregra == 'Igual' ? this.filtroAvancado.dataNascimento.toLowerCase() : '') &&
          (this.filtroAvancado.mgtregra == 'Igual' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) == parseFloat(this.filtroAvancado.mgt) :
            this.filtroAvancado.mgtregra == 'Maior' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) > parseFloat(this.filtroAvancado.mgt) :
              this.filtroAvancado.mgtregra == 'Menor' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) < parseFloat(this.filtroAvancado.mgt) :
                ani.MGT.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.topregra == 'Igual' && this.filtroAvancado.top ? parseFloat(ani.TOP) == parseFloat(this.filtroAvancado.top) :
            this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top ? parseFloat(ani.TOP) > parseFloat(this.filtroAvancado.top) :
              this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top ? parseFloat(ani.TOP) < parseFloat(this.filtroAvancado.top) :
                ani.TOP.toString().toLowerCase().includes('')) &&
          ani.Sexo.toLowerCase().includes(this.filtroAvancado.sexo ? this.filtroAvancado.sexo.toLowerCase() : '') &&
          ani.Raca.toLowerCase().includes(this.filtroAvancado.raca ? this.filtroAvancado.raca.toLowerCase() : '') &&
          ani.Categoria.toLowerCase().includes(this.filtroAvancado.categoria ? this.filtroAvancado.categoria.toLowerCase() : '') &&
          ani.Variedade.toLowerCase().includes(this.filtroAvancado.variedade ? this.filtroAvancado.variedade.toLowerCase() : '') &&
          ani.NFA.toString().toLowerCase().includes(this.filtroAvancado.nfa ? this.filtroAvancado.nfa.toLowerCase() : '') &&
          ani.LOGIN.toLowerCase().includes(this.filtroAvancado.LOGIN ? this.filtroAvancado.LOGIN.toLowerCase() : '') &&
          ani.LOGIN_APROVACAO.toLowerCase().includes(this.filtroAvancado.aprovador ? this.filtroAvancado.aprovador.toLowerCase() : '');
      });
      if (this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? 1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? -1 : 0))
      }
      if (this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? -1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? 1 : 0))
      }
      this.filtrado = true;
    } else {
      this.animaisFiltrados = this.animaisSolicitados.filter(ani => {
        return ani.NOME.toLowerCase().includes(this.filtroAvancado.nome ? this.filtroAvancado.nome.toLowerCase() : '') &&
          ani.Serie.toLowerCase().includes(this.filtroAvancado.serie ? this.filtroAvancado.serie.toLowerCase() : '') &&
          ani.RGN.toLowerCase().includes(this.filtroAvancado.rgn ? this.filtroAvancado.rgn.toLowerCase() : '') &&
          // (this.filtroAvancado.rgnregra == 'Igual' && this.filtroAvancado.rgn ? parseInt(ani.RGN) == parseInt(this.filtroAvancado.rgn) :
          //   this.filtroAvancado.rgnregra == 'Maior' && this.filtroAvancado.rgn ? parseInt(ani.RGN) > parseInt(this.filtroAvancado.rgn) :
          //     this.filtroAvancado.rgnregra == 'Menor' && this.filtroAvancado.rgn ? parseInt(ani.RGN) < parseInt(this.filtroAvancado.rgn) :
          //       ani.RGN.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.rgdregra == 'Igual' && this.filtroAvancado.rgd ? parseInt(ani.RGD) == parseInt(this.filtroAvancado.rgd) :
            this.filtroAvancado.rgdregra == 'Maior' && this.filtroAvancado.rgd ? parseInt(ani.RGD) > parseInt(this.filtroAvancado.rgd) :
              this.filtroAvancado.rgdregra == 'Menor' && this.filtroAvancado.rgd ? parseInt(ani.RGD) < parseInt(this.filtroAvancado.rgd) :
                ani.RGD.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.dataNascimentoregra == 'Igual' ?
            ani.dataFormatada.toString().toLowerCase().includes(this.filtroAvancado.dataNascimento ? this.filtroAvancado.dataNascimento.toLowerCase() : '') :
            this.filtroAvancado.dataNascimento == 'Maior' ? ani.dataFormatada >= this.filtroAvancado.dataNascimento :
              this.filtroAvancado.dataNascimento == 'Menor' ? ani.dataFormatada <= this.filtroAvancado.dataNascimento :
                ani.dataFormatada.toString().toLowerCase().includes('')) &&
          //ani.DataNascimento.toLowerCase().includes(this.filtroAvancado.dataNascimento && this.filtroAvancado.dataNascimentoregra == 'Igual' ? this.filtroAvancado.dataNascimento.toLowerCase() : '') &&
          (this.filtroAvancado.mgtregra == 'Igual' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) == parseFloat(this.filtroAvancado.mgt) :
            this.filtroAvancado.mgtregra == 'Maior' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) > parseFloat(this.filtroAvancado.mgt) :
              this.filtroAvancado.mgtregra == 'Menor' && this.filtroAvancado.mgt ? parseFloat(ani.MGT) < parseFloat(this.filtroAvancado.mgt) :
                ani.MGT.toString().toLowerCase().includes('')) &&
          (this.filtroAvancado.topregra == 'Igual' && this.filtroAvancado.top ? parseFloat(ani.TOP) == parseFloat(this.filtroAvancado.top) :
            this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top ? parseFloat(ani.TOP) > parseFloat(this.filtroAvancado.top) :
              this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top ? parseFloat(ani.TOP) < parseFloat(this.filtroAvancado.top) :
                ani.TOP.toString().toLowerCase().includes('')) &&
          ani.Sexo.toLowerCase().includes(this.filtroAvancado.sexo ? this.filtroAvancado.sexo.toLowerCase() : '') &&
          ani.Raca.toLowerCase().includes(this.filtroAvancado.raca ? this.filtroAvancado.raca.toLowerCase() : '') &&
          ani.Categoria.toLowerCase().includes(this.filtroAvancado.categoria ? this.filtroAvancado.categoria.toLowerCase() : '') &&
          ani.Variedade.toLowerCase().includes(this.filtroAvancado.variedade ? this.filtroAvancado.variedade.toLowerCase() : '') &&
          ani.NFA.toString().toLowerCase().includes(this.filtroAvancado.nfa ? this.filtroAvancado.nfa.toLowerCase() : '') &&
          ani.LOGIN.toLowerCase().includes(this.filtroAvancado.LOGIN ? this.filtroAvancado.LOGIN.toLowerCase() : '') &&
          ani.LOGIN_APROVACAO.toLowerCase().includes(this.filtroAvancado.aprovador ? this.filtroAvancado.aprovador.toLowerCase() : '');
      });
      if (this.filtroAvancado.topregra == 'Maior' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? 1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? -1 : 0))
      }
      if (this.filtroAvancado.topregra == 'Menor' && this.filtroAvancado.top) {
        this.animaisFiltrados.sort((a, b) => (parseInt(a['TOP']) > parseInt(b['TOP'])) ? -1 : ((parseInt(b['TOP']) > parseInt(a['TOP'])) ? 1 : 0))
      }
      this.filtrado = true;
    }
    this.p = 0;

  }

  redirecionar(url) {
    window.location.href = url;
  }

  pegarDados(animal) {
    this.showTable = true;
    this.app.loading = true;

    this.toastr.info(animal.NOME + ' - Buscando dados para impressão...');
    this.animalSelecionado = animal;
    console.log('pegar dadosaqui')
    this.animalService.retornarDetalhesImpressao(animal.CGA).subscribe((data: any) => {
      this.toastr.success('Dados Carregados! Gerando impressão...');
      console.log('pegou os dados do detalhe impressao')
      if (data.impresso.UltimaImpressao != null && data.impresso.UltimaImpressao != undefined) {
        var dt = new Date(data.impresso.UltimaImpressao);
        dt.setHours(5);
        data.impresso.UltimaImpressao = dt;
      }

      this.impressao.ceip = data.impresso.Numero + ' / ' + data.impresso.NumeroCEIP
      this.impressao.temG = data.cpmvp;
      this.impressao.animal.Serie = animal.Serie;
      this.impressao.animal.nomecaracteristica = animal.nomecaracteristica;
      this.impressao.animal.RGD = animal.RGD;
      this.impressao.animal.RGN = animal.RGN;
      this.impressao.animal.nome = animal.NOME;
      this.impressao.animal.raca = animal.Raca + ' ' + animal.Variedade;
      this.impressao.animal.dataNascimento = animal.DataNascimento;
      this.impressao.animal.sexo = animal.Sexo;
      this.impressao.animal.baseGenetica = data.baseGenetica;
      this.impressao.animal.sistemaAvaliacao = data.sistemaAvaliacao;
      this.impressao.valores = data.valores;
      this.impressao.dataEmissao = data.impresso.UltimaImpressao;

      this.impressao.pai = (data.pai != null) ? {
        serie: data.pai.SERIE,
        nomecaracteristica: data.pai.nomecaracteristica,
        rgn: data.pai.RGN,
        rgd: data.pai.RGD,
        nome: data.pai.NOME,
        mgte: data.pai.MGT,
        top: data.pai.TOP,
        cat: data.pai.CAT,
        raca: data.pai.raca ? data.pai.raca.DS_RACA + ' ' + (data.pai.variedade ? data.pai.variedade.DS_VARIEDADE : '') : '-'
      } : null;

      this.impressao.mae = (data.mae != null) ? {
        serie: data.mae.SERIE,
        rgn: data.mae.RGN,
        nomecaracteristica: data.mae.nomecaracteristica,
        rgd: data.mae.RGD,
        nome: data.mae.NOME,
        mgte: data.mae.MGT,
        top: data.mae.TOP,
        cat: data.mae.CAT,
        raca: data.mae.raca ? data.mae.raca.DS_RACA + ' ' + (data.mae.variedade ? data.mae.variedade.DS_VARIEDADE : '') : '-'
      } : null;

      this.impressao.avoPaterno = (data.paiPai != null) ? {
        serie: data.paiPai.SERIE,
        rgn: data.paiPai.RGN,
        rgd: data.paiPai.RGD,
        nomecaracteristica: data.paiPai.nomecaracteristica,
        nome: data.paiPai.NOME,
        mgte: data.paiPai.MGT,
        top: data.paiPai.TOP,
        cat: data.paiPai.CAT,
        raca: data.paiPai.raca ? data.paiPai.raca.DS_RACA : '-'
      } : null;

      this.impressao.avomPaterno = (data.maePai != null) ? {
        serie: data.maePai.SERIE,
        rgn: data.maePai.RGN,
        rgd: data.maePai.RGD,
        nomecaracteristica: data.maePai.nomecaracteristica,
        nome: data.maePai.NOME,
        mgte: data.maePai.MGT,
        top: data.maePai.TOP,
        cat: data.maePai.CAT,
        raca: data.maePai.raca ? data.maePai.raca.DS_RACA : '-'
      } : null;

      this.impressao.avoMaterno = (data.paiMae != null) ? {
        serie: data.paiMae.SERIE,
        rgn: data.paiMae.RGN,
        rgd: data.paiMae.RGD,
        nome: data.paiMae.NOME,
        nomecaracteristica: data.paiMae.nomecaracteristica,
        mgte: data.paiMae.MGT,
        top: data.paiMae.TOP,
        cat: data.paiMae.CAT,
        raca: data.paiMae.raca ? data.paiMae.raca.DS_RACA : '-'
      } : null;

      this.impressao.avomMaterno = (data.maeMae != null) ? {
        serie: data.maeMae.SERIE,
        rgn: data.maeMae.RGN,
        rgd: data.maeMae.RGD,
        nomecaracteristica: data.maeMae.nomecaracteristica,
        nome: data.maeMae.NOME,
        mgte: data.maeMae.MGT,
        top: data.maeMae.TOP,
        cat: data.maeMae.CAT,
        raca: data.maeMae.raca ? data.maeMae.raca.DS_RACA : '-'
      } : null;

      this.showTable = false;
      this.app.loading = false;
      console.log(data);
      //this.imprimir(animal.NOME);
      this.impressao2();
      animal.UltimaImpressao = new Date();
      this.gerenciarAnimais();
    })
  }

  gerenciarAnimais() {
    this.animaisSolicitados = this.animaisTotal.filter(data => {
      return data.UltimaImpressao != null;
    })
    this.animais = this.animaisTotal.filter(data => {
      return !data.UltimaImpressao;
    })
    this.gerenciarSelectFiltros();
  }

  buscarSelecionados() {
    return this.view == 'aptos' ? this.animais.filter(ani => {
      return ani.checked;
    }).length : this.animaisSolicitados.filter(ani => {
      return ani.checked;
    }).length
  }

  retornarSelecionados() {
    return this.view == 'aptos' ? this.animais.filter(ani => {
      return ani.checked;
    }) : this.animaisSolicitados.filter(ani => {
      return ani.checked;
    });
  }

  gerenciarSelectFiltros() {
    this.filtroRacas = [];
    this.filtroCategorias = [];
    this.filtroFazendas = [];
    this.filtroVariedades = [];
    this.filtroSolicitantes = [];
    this.filtroAprovadores = [];
    this.filtroRacasSolicitado = [];
    this.filtroCategoriasSolicitado = [];
    this.filtroVariedadesSolicitado = [];
    this.filtroFazendasSolicitado = [];
    this.filtroSolicitantesSolicitado = [];
    this.filtroAprovadoresSolicitado = [];
    //Dando valores para os selects do filtro
    //não solicitados
    var racas = {};
    this.animais.map(x => x.Raca).map(function (a) { if (a in racas) racas[a]++; else racas[a] = 1; });
    this.filtroRacas.push({ key: '', value: 'Todos' });
    Object.keys(racas).forEach(item => {
      this.filtroRacas.push({ key: item, value: item });
    })

    var categorias = {};
    this.animais.map(x => x.Categoria).map(function (a) { if (a in categorias) categorias[a]++; else categorias[a] = 1; });
    this.filtroCategorias.push({ key: '', value: 'Todos' });
    Object.keys(categorias).forEach(item => {
      this.filtroCategorias.push({ key: item, value: item });
    })

    var variedades = {};
    this.animais.map(x => x.Variedade).map(function (a) { if (a in variedades) variedades[a]++; else variedades[a] = 1; });
    this.filtroVariedades.push({ key: '', value: 'Todos' });
    Object.keys(variedades).forEach(item => {
      this.filtroVariedades.push({ key: item, value: item });
    })

    var fazendas = {};
    this.animais.map(x => x.NFA).map(function (a) { if (a in fazendas) fazendas[a]++; else fazendas[a] = 1; });
    this.filtroFazendas.push({ key: '', value: 'Todos' });
    Object.keys(fazendas).forEach(item => {
      this.filtroFazendas.push({ key: item, value: item });
    })

    var solicitantes = {};
    this.animais.map(x => x.LOGIN).map(function (a) { if (a in solicitantes) solicitantes[a]++; else solicitantes[a] = 1; });
    this.filtroSolicitantes.push({ key: '', value: 'Todos' });
    Object.keys(solicitantes).forEach(item => {
      this.filtroSolicitantes.push({ key: item, value: item });
    })

    var aprovadores = {};
    this.animais.map(x => x.LOGIN_APROVACAO).map(function (a) { if (a in aprovadores) aprovadores[a]++; else aprovadores[a] = 1; });
    this.filtroAprovadores.push({ key: '', value: 'Todos' });
    Object.keys(aprovadores).forEach(item => {
      this.filtroAprovadores.push({ key: item, value: item });
    })
    //solicitados
    var racas = {};
    this.animaisSolicitados.map(x => x.Raca).map(function (a) { if (a in racas) racas[a]++; else racas[a] = 1; });
    this.filtroRacasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(racas).forEach(item => {
      this.filtroRacasSolicitado.push({ key: item, value: item });
    })

    var categorias = {};
    this.animaisSolicitados.map(x => x.Categoria).map(function (a) { if (a in categorias) categorias[a]++; else categorias[a] = 1; });
    this.filtroCategoriasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(categorias).forEach(item => {
      this.filtroCategoriasSolicitado.push({ key: item, value: item });
    })

    var variedades = {};
    this.animaisSolicitados.map(x => x.Variedade).map(function (a) { if (a in variedades) variedades[a]++; else variedades[a] = 1; });
    this.filtroVariedadesSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(variedades).forEach(item => {
      this.filtroVariedadesSolicitado.push({ key: item, value: item });
    })

    var fazendas = {};
    this.animaisSolicitados.map(x => x.NFA).map(function (a) { if (a in fazendas) fazendas[a]++; else fazendas[a] = 1; });
    this.filtroFazendasSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(fazendas).forEach(item => {
      this.filtroFazendasSolicitado.push({ key: item, value: item });
    })

    var solicitantes = {};
    this.animaisSolicitados.map(x => x.LOGIN).map(function (a) { if (a in solicitantes) solicitantes[a]++; else solicitantes[a] = 1; });
    this.filtroSolicitantesSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(solicitantes).forEach(item => {
      this.filtroSolicitantesSolicitado.push({ key: item, value: item });
    })

    var aprovadores = {};
    this.animaisSolicitados.map(x => x.LOGIN_APROVACAO).map(function (a) { if (a in aprovadores) aprovadores[a]++; else aprovadores[a] = 1; });
    this.filtroAprovadoresSolicitado.push({ key: '', value: 'Todos' });
    Object.keys(aprovadores).forEach(item => {
      this.filtroAprovadoresSolicitado.push({ key: item, value: item });
    })
  }

  width: number = 0;
  height: number = 0;
  imprimir(nome) {
    console.log(document.body.offsetWidth);
    console.log(document.body.offsetHeight);
    this.width = document.body.offsetWidth;
    this.height = document.body.offsetHeight;

    this.showTable = true;
    this.app.loading = true;
    setTimeout(() => {

      const div = document.body.offsetHeight > 680 ? document.getElementById('contentToConvert') : document.getElementById('contentToConvertSmall');
      const options = {
        background: 'white',
        scale: 3
      };

      html2canvas(div, options).then((canvas) => {

        var img = canvas.toDataURL("image/PNG");
        var doc = new jsPDF('p', 'mm', 'a4', 1);

        // Add image Canvas to PDF
        const bufferX = 15;
        const bufferY = 35;
        const imgProps = (<any>doc).getImageProperties(img);
        const pdfWidth = (doc.internal.pageSize.getWidth() - 2 * bufferX) + bufferX;
        const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) + bufferY;
        doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

        return doc;
      }).then((doc) => {
        doc.save('certificado-ancp-' + nome + '.pdf');
        this.showTable = false;
        this.app.loading = false;
      });
    }, 1000)
  }

  sair() {
    localStorage.removeItem('ancp-net-token');
    window.location.href = 'http://ancp.org.br';
  }

  captureScreen2() {
    this.showTable = true;
    this.app.loading = true;
    setTimeout(() => {
      const doc = new jsPDF('l');
      if (this.view == 'aptos') {
        doc.autoTable({
          html: '#contentToConvertSelecionados', margin: { left: 10 }, columnStyles: {
            0: { cellWidth: 20 }, 1: { cellWidth: 13 }, 2: { cellWidth: 13 }, 3: { cellWidth: 13 }, 4: { cellWidth: 22 },
            5: { cellWidth: 15 }, 6: { cellWidth: 15 }, 7: { cellWidth: 15 }, 8: { cellWidth: 15 }, 10: { cellWidth: 20 },
            9: { cellWidth: 20 }, 11: { cellWidth: 13 }, 12: { cellWidth: 25 }, 15: { cellWidth: 22 }, 13: { cellWidth: 25 }, 14: { cellWidth: 22 }
          }
        });
      } else {
        doc.autoTable({
          html: '#contentToConvertSelecionados2', margin: { left: 3, right: 3 }, columnStyles: {
            0: { cellWidth: 20 }, 1: { cellWidth: 13 }, 2: { cellWidth: 13 }, 3: { cellWidth: 13 }, 4: { cellWidth: 22 },
            5: { cellWidth: 15 }, 6: { cellWidth: 15 }, 7: { cellWidth: 15 }, 8: { cellWidth: 15 }, 10: { cellWidth: 20 },
            9: { cellWidth: 20 }, 11: { cellWidth: 13 }, 12: { cellWidth: 22 }, 15: { cellWidth: 22 }, 16: { cellWidth: 22 }, 13: { cellWidth: 22 }, 14: { cellWidth: 22 }
          }
        });
      }

      doc.save('animais-ceip-selecionados-' + new Date().toISOString().slice(0, 10) + '.pdf');

      setTimeout(() => {
        this.showTable = false;
        this.app.loading = false;
      }, 2000);
    }, 2000)

  }


  captureScreen() {
    this.showTable = true;
    this.app.loading = true;
    setTimeout(() => {

      const doc = new jsPDF('l');
      if (this.view == 'aptos') {
        if (this.filtrado) {
          doc.autoTable({
            html: '#contentToConvert1', margin: { left: 10 }, columnStyles: {
              0: { cellWidth: 20 }, 1: { cellWidth: 13 }, 2: { cellWidth: 13 }, 3: { cellWidth: 13 }, 4: { cellWidth: 22 },
              5: { cellWidth: 15 }, 6: { cellWidth: 15 }, 7: { cellWidth: 15 }, 8: { cellWidth: 15 }, 10: { cellWidth: 20 },
              9: { cellWidth: 20 }, 11: { cellWidth: 13 }, 12: { cellWidth: 25 }, 15: { cellWidth: 22 }, 13: { cellWidth: 25 }, 14: { cellWidth: 22 }
            }
          });
        } else {
          doc.autoTable({
            html: '#contentToConvert2', margin: { left: 10 }, columnStyles: {
              0: { cellWidth: 25 }, 1: { cellWidth: 13 }, 2: { cellWidth: 13 }, 3: { cellWidth: 13 }, 4: { cellWidth: 22 },
              5: { cellWidth: 15 }, 6: { cellWidth: 15 }, 7: { cellWidth: 15 }, 8: { cellWidth: 15 }, 10: { cellWidth: 20 },
              9: { cellWidth: 20 }, 11: { cellWidth: 13 }, 12: { cellWidth: 25 }, 15: { cellWidth: 22 }, 13: { cellWidth: 25 }, 14: { cellWidth: 22 }
            }
          });
        }
      } else
        doc.autoTable({
          html: '#contentToConvert3', margin: { left: 3, right: 3 }, columnStyles: {
            0: { cellWidth: 20 }, 1: { cellWidth: 13 }, 2: { cellWidth: 13 }, 3: { cellWidth: 13 }, 4: { cellWidth: 22 },
            5: { cellWidth: 15 }, 6: { cellWidth: 15 }, 7: { cellWidth: 15 }, 8: { cellWidth: 15 }, 10: { cellWidth: 20 },
            9: { cellWidth: 20 }, 11: { cellWidth: 13 }, 12: { cellWidth: 22 }, 15: { cellWidth: 22 }, 16: { cellWidth: 22 }, 13: { cellWidth: 22 }, 14: { cellWidth: 22 }
          }
        });

      doc.save('animais-admin-solicitadosceip-' + new Date().toISOString().slice(0, 10) + '.pdf');

      setTimeout(() => {
        this.showTable = false;
        this.app.loading = false;
      }, 2000);
    }, 1000)

  }

  filtrar() {
    console.log(this.search);
    this.animaisFiltrados = this.animais.filter(ani => {
      return ani.NOME.toLowerCase().includes(this.search.toLowerCase()) || ani.RGN.toLowerCase().includes(this.search.toLowerCase());
    })

    this.filtrado = this.search != '';
    this.p = 0;
  }

  public exportExcel(): void {
    debugger;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.todosAnimais);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, 'fileName');
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  imprimirExcel() {
    this.showTableTodos = true;
    this.app.loading = true;
    setTimeout(() => {
      var fileName = `ANCP-CEIP-TodosAnimais-${new Date().toISOString().slice(0, 10)}.xlsx`;
      let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      XLSX.writeFile(wb, fileName, { bookType: 'xlsx', bookSST: false, type: 'binary' });
      setTimeout(() => {
        // this.showTableTodos = false;
        this.app.loading = false;
      }, 2000);
    }, 2000);

  }

  buscarTodosAnimais() {
    this.animalService.buscarTodosAnimais().subscribe((data: any) => {
      data.forEach(element => {

        if (element.DataVistoria != null && element.DataVistoria != undefined) {
          var dt = new Date(element.DataVistoria);
          dt.setHours(4);
          element.DataVistoria = dt;
        }

        if (element.DataSolicitacao != null && element.DataSolicitacao != undefined) {
          var dt = new Date(element.DataSolicitacao);
          dt.setHours(4);
          element.DataSolicitacao = dt;
        }

        if (element.DataNascimento != null && element.DataNascimento != undefined) {
          var dt = new Date(element.DataNascimento);
          dt.setHours(4);
          element.DataNascimento = dt;
        }

        if (element.UltimaImpressao != null && element.UltimaImpressao != undefined) {
          var dt = new Date(element.UltimaImpressao);
          dt.setHours(4);
          element.UltimaImpressao = dt;
        }

        element['checked'] = false;
        element['able'] = false;
        if (element.DataLimite != null && element.DataLimite != undefined) {
          var dt = new Date(element.DataLimite);
          dt.setHours(8);
          element.DataLimite = dt;
        }
        element['limite'] = element.DataLimite;
        element['dataFormatada'] = element.DataNascimento;

        this.todosAnimais.push({
          Nome: element.NOME,
          CGA: element.CGA,
          'Série': element.Serie,
          RGN: element.RGN,
          RGD: element.RGD,
          'Data de Nascimento': element.DataNascimento,
          'MGTe/IGE': element.MGT,
          TOP: element.TOP,
          Sexo: element.Sexo,
          'Raça': element.Raca,
          Categoria: element.Categoria,
          Variedade: element.Variedade,
          NFA: element.NFA,
          NFO: element.NFO,
          Solicitante: element.LOGIN,
          'Data Solicitação': element.DataSolicitacao,
          Consultor: element.LOGIN_APROVACAO,
          'Data Vistoria': element.DataVistoria,
          'Data Limite CEIP': element.DataLimite,
          'Data da Impressão': element.UltimaImpressao
        })
      });
    })
  }

  onSort(field) {
    if (this.filtrado) {
      this.animaisFiltrados = this.atualCampoOrdem == field ?
        this.contadorOrdem % 2 == 0 ?
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
        field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
    } else {
      this.animais = this.atualCampoOrdem == field ?
        this.contadorOrdem % 2 == 0 ?
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? -1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? 1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? -1 : ((b[field] > a[field]) ? 1 : 0)) :
          field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)) :
        field == 'TOP' ? this.animais.sort((a, b) => (parseFloat(a[field]) > parseFloat(b[field])) ? 1 : ((parseFloat(b[field]) > parseFloat(a[field])) ? -1 : 0)) : this.animais.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
    }

    this.contadorOrdem = this.atualCampoOrdem == field ? this.contadorOrdem + 1 : 0;

    this.atualCampoOrdem = field;
  }

  imprimirVarios() {
    var animaisImprimir = this.view == 'aptos' ? this.animais.filter(ani => {
      return ani.checked;
    }) : this.animaisSolicitados.filter(ani => {
      return ani.checked;
    });
    animaisImprimir.concat(this.animaisFiltrados.filter(ani => {
      return ani.checked;
    }));
    if (animaisImprimir.length > 0) {
      this.toastr.info(`Imprimindo ${animaisImprimir.length} animais...`);
      //Agrupar Fazendas
      var animaisFazendas = animaisImprimir.reduce(function (r, a) {
        r[a.NFA] = r[a.NFA] || [];
        r[a.NFA].push(a);
        return r;
      }, Object.create(null));

      this.toastr.info(`Animais agrupados em ${Object.keys(animaisFazendas).length} fazendas...`);

      this.imprimirPorFazenda(animaisFazendas, false, false, 0);

    } else {
      this.toastr.error(`Selecione, no mínimo, um animal para imprimir.`);
    }

  }

  imprimirVarios2() {
    var animaisImprimir = this.view == 'aptos' ? this.animais.filter(ani => {
      return ani.checked;
    }) : this.animaisSolicitados.filter(ani => {
      return ani.checked;
    });
    animaisImprimir.concat(this.animaisFiltrados.filter(ani => {
      return ani.checked;
    }));
    if (animaisImprimir.length > 0) {
      var list = [];
      animaisImprimir.forEach(item => {
        list.push({ CGA: item.CGA, MGT: item.MGT, NFA: item.NFA });
      });
      // var animaisFazendas = list.reduce(function (r, a) {
      //   r[a.NFA] = r[a.NFA] || [];
      //   r[a.NFA].push(a);
      //   return r;
      // }, Object.create(null));
      var animaisFazendas = {
        136: list.slice(0, 40),
        137: list.slice(40, 84)
      }
      animaisFazendas['137'].forEach(item => {
        item.NFA = 137;
      });
      debugger;
      this.imprimirPorFazenda2(animaisFazendas, 0, false, 0);

      // var nfas = Object.keys(animaisFazendas);
      // var animaisFazendaAtual = animaisFazendas[nfas[0]].sort((a,b) => (a.MGT > b.MGT) ? -1 : ((b.MGT > a.MGT) ? 1 : 0));;
      // console.log(JSON.stringify(animaisFazendaAtual));
      // this.app.loading = true;
      // this.toastr.info('Animais estão sendo gerados para impressão..');
      // this.animalService.imprimir(animaisFazendaAtual).subscribe(data => {
      //   this.toastr.info(`PDF pronto a ser impresso`);
      //   this.app.loading = false;
      //   window.open(this.app.URL+'/pdf/'+data, '_blank');
      // }, error => {
      //   console.log(error);
      //   this.toastr.error(`Error`);
      // })

    } else {
      this.toastr.error(`Selecione, no mínimo, um animal para imprimir.`);
    }

  }

  removeAllSelect() {
    this.animais.forEach(ani => {
      ani.checked = false;
    })
    this.animaisSolicitados.forEach(ani => {
      ani.checked = false;
    })
  }

  selectAll() {
    if (this.view == 'aptos') {
      if (this.filtrado) {
        this.animaisFiltrados.forEach(ani => {
          ani.checked = !this.allchecked;
        })
      } else {
        this.animais.forEach(ani => {
          ani.checked = !this.allchecked;
        })
      }
    } else {
      if (this.filtrado) {
        this.animaisFiltrados.forEach(ani => {
          ani.checked = !this.allchecked;
        })
      } else {
        this.animaisSolicitados.forEach(ani => {
          ani.checked = !this.allchecked;
        })
      }

    }
  }

  imprimirPorFazenda(fazendas, contador, total, contFazenda) {

    var nfas = Object.keys(fazendas);
    if (contFazenda >= nfas.length) {
      this.toastr.success(`Fim Impressao.`);
      this.buscarAnimais();
    } else {
      var contAnimal = !contador ? 0 : contador;

      this.app.loading = true;
      var animaisFazendaAtual = fazendas[nfas[contFazenda]].sort((a, b) => (a.MGT > b.MGT) ? -1 : ((b.MGT > a.MGT) ? 1 : 0));;

      if (contAnimal < animaisFazendaAtual.length) {

        this.imprimirAnimalPorFazenda(fazendas, animaisFazendaAtual[0].NOME_FANTASIA, animaisFazendaAtual[contAnimal], contAnimal, animaisFazendaAtual.length, contFazenda);
      } else {
        contFazenda = contFazenda + 1;
        this.doc2 = new jsPDF();
        contador = undefined;
        this.app.loading = false;
        this.imprimirPorFazenda(fazendas, contador, total, contFazenda);

      }
    }

  }

  imprimirPorFazenda2(animaisFazendas, contador, total, contFazenda) {
    var nfas = Object.keys(animaisFazendas);
    if (contador >= nfas.length) {
      this.toastr.success(`Fim Impressao.`);
      this.app.loading = false;
    } else {

      this.app.loading = true;
      var animaisFazendaAtual = animaisFazendas[nfas[contador]].sort((a, b) => (a.MGT > b.MGT) ? -1 : ((b.MGT > a.MGT) ? 1 : 0));;

      if (contador < animaisFazendaAtual.length) {

        this.imprimirAnimalPorFazenda2(animaisFazendas, animaisFazendaAtual, contador, animaisFazendaAtual.length, contFazenda);
      } else {
        this.toastr.success(`Fim Impressao.`);
        this.app.loading = false;
      }
    }
  }

  startServiceCheckPDF(nfa, animaisFazendaAtual, animais, contador, total, contFazenda) {

    this.animalService.checkPDFNFa(nfa).subscribe((data: any) => {
      if (data.key === true) {
        this.toastr.info(`PDF da fazenda ${animais[0].NFA} pronto a ser impresso`);
        this.app.loading = false;
        window.open(this.app.URL + '/pdf/' + data.value, '_blank');
        contFazenda = contFazenda + 1;
        contador = contador + 1;
        this.imprimirPorFazenda2(animaisFazendaAtual, contador, total, contFazenda);
      } else {
        setTimeout(() => {
          this.toastr.info(data.value.toString());
          this.startServiceCheckPDF(nfa, animaisFazendaAtual, animais, contador, total, contFazenda);

        }, 5000);
      }

    }, error => {
      this.toastr.error(`Erro`);
    })

  }

  imprimirAnimalPorFazenda(fazendas, nomeFazenda, animal, contador, total, contFazenda) {
    this.showTable = true;
    this.toastr.info(`Imprimindo dados da fazenda ${nomeFazenda} - ${contador + 1}/${total} `);
    this.animalSelecionado = animal;
    this.animalService.retornarDetalhesImpressao(animal.CGA).subscribe((data: any) => {


      this.toastr.success(`Dados Carregados! ${contador + 1 == total ? 'Gerando impressão...' : `${contador + 1}/${total}`}`);
      if (data.impresso.UltimaImpressao != null && data.impresso.UltimaImpressao != undefined) {
        var dt = new Date(data.impresso.UltimaImpressao);
        dt.setHours(5);
        data.impresso.UltimaImpressao = dt;
      }
      this.impressao.ceip = data.impresso.Numero + ' / ' + data.impresso.NumeroCEIP
      this.impressao.temG = data.cpmvp;
      this.impressao.animal.Serie = animal.Serie;
      this.impressao.animal.nomecaracteristica = animal.nomecaracteristica;
      this.impressao.animal.RGD = animal.RGD;
      this.impressao.animal.RGN = animal.RGN;
      this.impressao.animal.nome = animal.NOME;
      this.impressao.animal.raca = animal.Raca + ' ' + animal.Variedade;
      this.impressao.animal.dataNascimento = animal.DataNascimento;
      this.impressao.animal.sexo = animal.Sexo;
      this.impressao.animal.baseGenetica = data.baseGenetica;
      this.impressao.animal.sistemaAvaliacao = data.sistemaAvaliacao;
      this.impressao.valores = data.valores;
      this.impressao.dataEmissao = data.impresso.UltimaImpressao;

      this.impressao.pai = (data.pai != null) ? {
        serie: data.pai.SERIE,
        nomecaracteristica: data.pai.nomecaracteristica,
        rgn: data.pai.RGN,
        rgd: data.pai.RGD,
        nome: data.pai.NOME,
        mgte: data.pai.MGT,
        top: data.pai.TOP,
        cat: data.pai.CAT,
        raca: data.pai.raca ? data.pai.raca.DS_RACA + ' ' + (data.pai.variedade ? data.pai.variedade.DS_VARIEDADE : '') : '-'
      } : null;

      this.impressao.mae = (data.mae != null) ? {
        serie: data.mae.SERIE,
        rgn: data.mae.RGN,
        nomecaracteristica: data.mae.nomecaracteristica,
        rgd: data.mae.RGD,
        nome: data.mae.NOME,
        mgte: data.mae.MGT,
        top: data.mae.TOP,
        cat: data.mae.CAT,
        raca: data.mae.raca ? data.mae.raca.DS_RACA + ' ' + (data.mae.variedade ? data.mae.variedade.DS_VARIEDADE : '') : '-'
      } : null;

      this.impressao.avoPaterno = (data.paiPai != null) ? {
        serie: data.paiPai.SERIE,
        rgn: data.paiPai.RGN,
        rgd: data.paiPai.RGD,
        nomecaracteristica: data.paiPai.nomecaracteristica,
        nome: data.paiPai.NOME,
        mgte: data.paiPai.MGT,
        top: data.paiPai.TOP,
        cat: data.paiPai.CAT,
        raca: data.paiPai.raca ? data.paiPai.raca.DS_RACA : '-'
      } : null;

      this.impressao.avomPaterno = (data.maePai != null) ? {
        serie: data.maePai.SERIE,
        rgn: data.maePai.RGN,
        rgd: data.maePai.RGD,
        nomecaracteristica: data.maePai.nomecaracteristica,
        nome: data.maePai.NOME,
        mgte: data.maePai.MGT,
        top: data.maePai.TOP,
        cat: data.maePai.CAT,
        raca: data.maePai.raca ? data.maePai.raca.DS_RACA : '-'
      } : null;

      this.impressao.avoMaterno = (data.paiMae != null) ? {
        serie: data.paiMae.SERIE,
        rgn: data.paiMae.RGN,
        rgd: data.paiMae.RGD,
        nome: data.paiMae.NOME,
        nomecaracteristica: data.paiMae.nomecaracteristica,
        mgte: data.paiMae.MGT,
        top: data.paiMae.TOP,
        cat: data.paiMae.CAT,
        raca: data.paiMae.raca ? data.paiMae.raca.DS_RACA : '-'
      } : null;

      this.impressao.avomMaterno = (data.maeMae != null) ? {
        serie: data.maeMae.SERIE,
        rgn: data.maeMae.RGN,
        rgd: data.maeMae.RGD,
        nomecaracteristica: data.maeMae.nomecaracteristica,
        nome: data.maeMae.NOME,
        mgte: data.maeMae.MGT,
        top: data.maeMae.TOP,
        cat: data.maeMae.CAT,
        raca: data.maeMae.raca ? data.maeMae.raca.DS_RACA : '-'
      } : null;

      this.showTable = false;
      this.app.loading = false;

      // var doc = new jsPDF();
      this.doc2.setFont("Verdana, Geneva, Tahoma, sans-serif");
      this.doc2.setFontSize(10);
      this.doc2.setFontStyle("bold");
      this.doc2.text("CEIP: ", 95, 40, null, null, "center");
      this.doc2.rect(100, 35, 30, 7);
      //doc.setFontStyle("normal");
      this.doc2.text(this.impressao.ceip, 110, 40, null, null, "center");
      this.doc2.rect(18, 45, 185, 17);
      this.doc2.setFontStyle("bold");
      this.doc2.text("Animal: ", 20, 50, null, null, "left");
      this.doc2.text("Data de Nascimento: ", 20, 55, null, null, "left");
      this.doc2.text("Base Genética Utilizada: ", 20, 60, null, null, "left");
      this.doc2.text("Raça ou Composição Racial: ", 115, 50, null, null, "left");
      this.doc2.text("Sexo: ", 115, 55, null, null, "left");
      this.doc2.text("Sistema de Avaliação: ", 115, 60, null, null, "left");
      this.doc2.setFontStyle("normal");
      this.doc2.text(`${this.impressao.animal.Serie ? this.impressao.animal.Serie : '---'} / ${this.impressao.animal.RGN ? this.impressao.animal.RGN : '---'} / ${this.impressao.animal.RGD ? this.impressao.animal.RGD : '---'} / ${this.impressao.animal.nome ? this.impressao.animal.nome : '---'}`, 33, 50, null, null, "left");
      this.doc2.text(`${this.datePipe.transform(this.impressao.animal.dataNascimento, 'dd/MM/yyyy')}`, 52, 55, null, null, "left");
      this.doc2.text(`${this.impressao.animal.baseGenetica}`, 58, 60, null, null, "left");
      this.doc2.text(`${this.impressao.animal.raca}`, 159, 50, null, null, "left");
      this.doc2.text(`${this.impressao.animal.sexo}`, 124, 55, null, null, "left");
      this.doc2.text(`${this.impressao.animal.sistemaAvaliacao}`, 149, 60, null, null, "left");

      this.doc2.setFontStyle("bold");
      this.doc2.setFontSize(8);
      if (this.animalSelecionado.Categoria == 'CL') {
        this.doc2.text(`D3P${this.impressao.temG ? 'G' : ''}`, 26, 66, null, null, "center");
        this.doc2.text(`MP120${this.impressao.temG ? 'G' : ''}`, 45, 66, null, null, "center");
        this.doc2.text(`MP210${this.impressao.temG ? 'G' : ''}`, 64, 66, null, null, "center");
        this.doc2.text(`DP210${this.impressao.temG ? 'G' : ''}`, 83, 66, null, null, "center");
        this.doc2.text(`DP450${this.impressao.temG ? 'G' : ''}`, 102, 66, null, null, "center");
        this.doc2.text(`DSTAY${this.impressao.temG ? 'G' : ''}`, 121, 66, null, null, "center");
        // doc.text(`DPE365${this.impressao.temG ? 'G' : ''}`, 127, 66, null, null, "center");
        this.doc2.text(`DPE450${this.impressao.temG ? 'G' : ''}`, 140, 66, null, null, "center");
        this.doc2.text(`DAOL${this.impressao.temG ? 'G' : ''}`, 159, 66, null, null, "center");
        this.doc2.text(`DACAB${this.impressao.temG ? 'G' : ''}`, 177, 66, null, null, "center");
        this.doc2.text(`IGE`, 195, 66, null, null, "center");
        this.doc2.rect(19, 67, 15, 8);
        this.doc2.rect(38, 67, 15, 8);
        this.doc2.rect(57, 67, 15, 8);
        this.doc2.rect(76, 67, 15, 8);
        this.doc2.rect(95, 67, 15, 8);
        this.doc2.rect(114, 67, 15, 8);
        // doc.rect(120, 67, 14, 8);
        this.doc2.rect(133, 67, 15, 8);
        this.doc2.rect(152, 67, 15, 8);
        this.doc2.rect(170, 67, 15, 8);
        this.doc2.setDrawColor(0);
        this.doc2.setFillColor(180, 180, 180);
        this.doc2.rect(188, 67, 15, 8, "F");
        this.doc2.setFontSize(10);
        // doc.setFontStyle("normal");
        this.doc2.text(`${parseFloat(this.impressao.valores.D3P).toFixed(2)}`, 26, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.MP120).toFixed(2)}`, 45, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.MP210).toFixed(2)}`, 64, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DP210).toFixed(2)}`, 83, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DP450).toFixed(2)}`, 102, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DSTAY).toFixed(2)}`, 121, 71, null, null, "center");
        // doc.text(`${parseFloat(this.impressao.valores.DPE365).toFixed(2)}`, 127, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DPE450).toFixed(2)}`, 140, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DAOL).toFixed(2)}`, 159, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DACAB).toFixed(2)}`, 177, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.MGT).toFixed(2)}`, 195, 71, null, null, "center");

        this.doc2.setFontSize(7);
        this.doc2.text(`TOP: ${this.impressao.valores.D3Ptop}%`, 27, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.MP120top}%`, 46, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.MP210top}%`, 65, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DP210top}%`, 84, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DP450top}%`, 103, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DSTAYtop}%`, 122, 74, null, null, "center");
        // doc.text(`TOP: ${this.impressao.valores.DPE365top}%`, 127, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DPE450top}%`, 141, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DAOLtop}%`, 160, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DACABtop}%`, 178, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.MGTtop}%`, 196, 74, null, null, "center");
      } else {
        this.doc2.text(`D3P${this.impressao.temG ? 'G' : ''}`, 26, 66, null, null, "center");
        this.doc2.text(`MP120${this.impressao.temG ? 'G' : ''}`, 47, 66, null, null, "center");
        this.doc2.text(`DP210${this.impressao.temG ? 'G' : ''}`, 68, 66, null, null, "center");
        this.doc2.text(`DP450${this.impressao.temG ? 'G' : ''}`, 89, 66, null, null, "center");
        this.doc2.text(`DSTAY${this.impressao.temG ? 'G' : ''}`, 110, 66, null, null, "center");
        this.doc2.text(`DPE365${this.impressao.temG ? 'G' : ''}`, 131, 66, null, null, "center");
        this.doc2.text(`DAOL${this.impressao.temG ? 'G' : ''}`, 152, 66, null, null, "center");
        this.doc2.text(`DACAB${this.impressao.temG ? 'G' : ''}`, 173, 66, null, null, "center");
        this.doc2.text(`MGTe`, 194, 66, null, null, "center");

        this.doc2.rect(18, 67, 17, 8);
        this.doc2.rect(39, 67, 17, 8);
        this.doc2.rect(60, 67, 17, 8);
        this.doc2.rect(81, 67, 17, 8);
        this.doc2.rect(102, 67, 17, 8);
        this.doc2.rect(123, 67, 17, 8);
        this.doc2.rect(144, 67, 17, 8);
        this.doc2.rect(165, 67, 17, 8);

        this.doc2.setDrawColor(0);
        this.doc2.setFillColor(180, 180, 180);
        this.doc2.rect(186, 67, 17, 8, "F");
        this.doc2.setFontSize(10);
        // doc.setFontStyle("normal");

        this.doc2.text(`${parseFloat(this.impressao.valores.D3P).toFixed(2)}`, 26, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.MP120).toFixed(2)}`, 47, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DP210).toFixed(2)}`, 68, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DP450).toFixed(2)}`, 89, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DSTAY).toFixed(2)}`, 110, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DPE365).toFixed(2)}`, 131, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DAOL).toFixed(2)}`, 152, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.DACAB).toFixed(2)}`, 173, 71, null, null, "center");
        this.doc2.text(`${parseFloat(this.impressao.valores.MGT).toFixed(2)}`, 194, 71, null, null, "center");

        this.doc2.setFontSize(7);

        this.doc2.text(`TOP: ${this.impressao.valores.D3Ptop}%`, 26, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.MP120top}%`, 47, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DP210top}%`, 68, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DP450top}%`, 89, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DSTAYtop}%`, 110, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DPE365top}%`, 131, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DAOLtop}%`, 152, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.DACABtop}%`, 173, 74, null, null, "center");
        this.doc2.text(`TOP: ${this.impressao.valores.MGTtop}%`, 194, 74, null, null, "center");
      }


      this.doc2.setFontSize(10);
      this.doc2.setFontStyle("bold");
      this.doc2.text("Avós Paternos ", 45, 79, null, null, "left");
      this.doc2.rect(18, 80, 77, 10);
      this.doc2.text("Avós Maternos ", 150, 79, null, null, "left");
      this.doc2.rect(126, 80, 77, 10);
      this.doc2.setFontStyle("normal");
      this.doc2.setFontSize(9);
      if (this.impressao.avoPaterno == null) {
        this.doc2.text('---', 55, 88, null, null, "center");
      } else {
        this.doc2.text(`${this.impressao.avoPaterno.serie ? this.impressao.avoPaterno.serie : '---'} / ${this.impressao.avoPaterno.rgn ? this.impressao.avoPaterno.rgn : '---'} / ${this.impressao.avoPaterno.rgd ? this.impressao.avoPaterno.rgd : '---'} - ${this.impressao.avoPaterno.nome}`, 56, 84, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avoPaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avoPaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avoPaterno.top}%</p>`, 40, 81, null, null, "center");
      }

      if (this.impressao.avoMaterno == null) {
        this.doc2.text('---', 168, 88, null, null, "center");
      } else {
        this.doc2.setFontStyle("normal");
        this.doc2.setFontSize(9);
        this.doc2.text(`${this.impressao.avoMaterno.serie ? this.impressao.avoMaterno.serie : '---'} / ${this.impressao.avoMaterno.rgn ? this.impressao.avoMaterno.rgn : '---'} / ${this.impressao.avoMaterno.rgd ? this.impressao.avoMaterno.rgd : '---'} - ${this.impressao.avoMaterno.nome}`, 164, 84, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avoMaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avoMaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avoMaterno.top}%</p>`, 147, 81, null, null, "center");
      }

      this.doc2.setFontStyle("bold");
      this.doc2.setFontSize(10);
      this.doc2.text("Pai", 108, 89, null, null, "right");
      this.doc2.rect(32, 90, 76, 14);
      this.doc2.text("Mãe", 111, 89, null, null, "left");
      this.doc2.rect(110, 90, 76, 14);
      this.doc2.setFontStyle("normal");
      this.doc2.setFontSize(9);

      if (this.impressao.pai == null) {
        this.doc2.text('---', 72, 99, null, null, "center");
      } else {
        this.doc2.text(`${this.impressao.pai.serie ? this.impressao.pai.serie : '---'} / ${this.impressao.pai.rgn ? this.impressao.pai.rgn : '---'} / ${this.impressao.pai.rgd ? this.impressao.pai.rgd : '---'} - ${this.impressao.pai.nome}`, 70, 94, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.pai.nomecaracteristica}:</b> ${parseFloat(this.impressao.pai.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.pai.top}%</p>`, 56, 91, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.pai.raca}</p>`, 42, 95, null, null, "center");
      }

      if (this.impressao.mae == null) {
        this.doc2.text('---', 150, 99, null, null, "center");
      } else {
        this.doc2.setFontStyle("normal");
        this.doc2.setFontSize(9);
        this.doc2.text(`${this.impressao.mae.serie ? this.impressao.mae.serie : '---'} / ${this.impressao.mae.rgn ? this.impressao.mae.rgn : '---'} / ${this.impressao.mae.rgd ? this.impressao.mae.rgd : '---'} - ${this.impressao.mae.nome}`, 149, 94, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.mae.nomecaracteristica}:</b> ${parseFloat(this.impressao.mae.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.mae.top}%</p>`, 134, 91, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.mae.raca}</p>`, 120, 95, null, null, "center");
      }


      this.doc2.rect(18, 104, 77, 10);
      this.doc2.rect(126, 104, 77, 10);
      this.doc2.setFontStyle("normal");
      this.doc2.setFontSize(9);
      if (this.impressao.avomPaterno == null) {
        this.doc2.text('---', 55, 111, null, null, "center");
      } else {
        this.doc2.text(`${this.impressao.avomPaterno.serie ? this.impressao.avomPaterno.serie : '---'} / ${this.impressao.avomPaterno.rgn ? this.impressao.avomPaterno.rgn : '---'} / ${this.impressao.avomPaterno.rgd ? this.impressao.avomPaterno.rgd : '---'} - ${this.impressao.avomPaterno.nome}`, 56, 108, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avomPaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avomPaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avomPaterno.top}%</p>`, 40, 105, null, null, "center");
      }

      if (this.impressao.avomMaterno == null) {
        this.doc2.text('---', 168, 111, null, null, "right");

      } else {
        this.doc2.setFontStyle("normal");
        this.doc2.setFontSize(9);
        this.doc2.text(`${this.impressao.avomMaterno.serie ? this.impressao.avomMaterno.serie : '---'} / ${this.impressao.avomMaterno.rgn ? this.impressao.avomMaterno.rgn : '---'} / ${this.impressao.avomMaterno.rgd ? this.impressao.avomMaterno.rgd : '---'} - ${this.impressao.avomMaterno.nome}`, 164, 108, null, null, "center");
        this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avomMaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avomMaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avomMaterno.top}%</p>`, 147, 105, null, null, "center");
      }


      this.doc2.rect(18, 115, 185, 19);
      this.doc2.text("________________________________________", 109, 125, null, null, "center");
      this.doc2.setFontStyle("italic");
      this.doc2.text(`${this.animalSelecionado.Categoria != 'CL' ? 'Raysildo Barbosa Lôbo' : 'Letícia Mendes de Castro'}`, 111, 129, null, null, "center");
      this.doc2.setFontStyle("bold");
      this.doc2.fromHTML(`<p style="font-size:10"><b>Responsável Técnico</b></p>`, 82, 126, null, null, "center");
      this.doc2.fromHTML(`<p style="font-size:10"><b>${this.animalSelecionado.Categoria != 'CL' ? 'CRMV: 4 - 1967' : 'CRMV: GO 04943'}</b></p>`, 115, 126, null, null, "center");
      // this.doc2.text("Responsável Técnico", 90, 136, null, null, "center");
      // this.doc2.text(`${this.animalSelecionado.Categoria != 'CL' ? 'CRMV/CREA: 4 - 1967' : 'CRMV: GO 04943'}`, 135, 136, null, null, "center");
      this.doc2.setFontSize(8);
      this.doc2.fromHTML(`<p style="font-size:10"><b>Data de Emissão: </b> ${this.datePipe.transform(this.impressao.dataEmissao, 'dd/MM/yyyy')}</p>`, 40, 130, null, null, "center");
      this.doc2.fromHTML(`<p style="font-size:10"><b>Local de Emissão: </b> Ribeirão Preto / SP</p>`, 135, 130, null, null, "center");
      // this.doc2.text(":", 50, 140, null, null, "center");
      // this.doc2.text("Local de Emissão:", 145, 140, null, null, "center");
      // this.doc2.setFontStyle("normal");
      // this.doc2.text(``, 70, 140, null, null, "center");
      // this.doc2.text("Ribeirão Preto / SP", 170, 140, null, null, "center");
      this.doc2.setFontSize(6);
      this.doc2.setFontStyle("bold");
      this.doc2.text("A RAÇA OU O PERCENTUAL DA COMPOSIÇÃO RACIAL DESTE ANIMAL SOMENTE SERÁ COMPROVADA POR MEIO DOS CERTIFICADOS DE REGISTRO GENEALÓGICO  ", 110, 141, null, null, "center");
      this.doc2.text("OU CERTIFICADO DE CONTROLE DE GENEALOGIA, EMITIDOS PELA RESPECTIVA ASSOCIAÇÃO DE CRIADORES DA RAÇA.", 110, 144, null, null, "center");
      //  
      contador = contador + 1;
      if (contador == total)
        this.doc2.save('certificado-ancp.pdf');
      else
        this.doc2.addPage();

      this.imprimirPorFazenda(fazendas, contador, total, contFazenda);
    })
  }

  imprimirAnimalPorFazenda2(animaisFazendaAtual, animais, contador, total, contFazenda) {

    console.log(JSON.stringify(animais));
    this.app.loading = true;

    this.toastr.info('Animais da fazenda ' + animais[0].NFA + ' estão sendo gerados para impressão..');
    this.animalService.imprimir(animais).then(data => {
      if (data === true) {
        this.startServiceCheckPDF(animais[0].NFA, animaisFazendaAtual, animais, contador, total, contFazenda);
      } else {
        this.toastr.info(`PDF da fazenda ${animais[0].NFA} pronto a ser impresso`);
        this.app.loading = false;
        window.open(this.app.URL + '/pdf/' + data, '_blank');
        contFazenda = contFazenda + 1;
        contador = contador + 1;
        this.imprimirPorFazenda2(animaisFazendaAtual, contador, total, contFazenda);
      }

    }, error => {
      this.startServiceCheckPDF(animais[0].NFA, animaisFazendaAtual, animais, contador, total, contFazenda);
    })


    // this.showTable = true;
    // this.toastr.info(`Imprimindo dados da fazenda ${nomeFazenda} - ${contador + 1}/${total} `);
    // this.animalSelecionado = animal;
    // this.animalService.retornarDetalhesImpressao(animal.CGA).subscribe((data: any) => {


    //   this.toastr.success(`Dados Carregados! ${contador + 1 == total ? 'Gerando impressão...' : `${contador + 1}/${total}`}`);
    //   if (data.impresso.UltimaImpressao != null && data.impresso.UltimaImpressao != undefined) {
    //     var dt = new Date(data.impresso.UltimaImpressao);
    //     dt.setHours(5);
    //     data.impresso.UltimaImpressao = dt;
    //   }
    //   this.impressao.ceip = data.impresso.Numero + ' / ' + data.impresso.NumeroCEIP
    //   this.impressao.temG = data.cpmvp;
    //   this.impressao.animal.Serie = animal.Serie;
    //   this.impressao.animal.nomecaracteristica = animal.nomecaracteristica;
    //   this.impressao.animal.RGD = animal.RGD;
    //   this.impressao.animal.RGN = animal.RGN;
    //   this.impressao.animal.nome = animal.NOME;
    //   this.impressao.animal.raca = animal.Raca + ' ' + animal.Variedade;
    //   this.impressao.animal.dataNascimento = animal.DataNascimento;
    //   this.impressao.animal.sexo = animal.Sexo;
    //   this.impressao.animal.baseGenetica = data.baseGenetica;
    //   this.impressao.animal.sistemaAvaliacao = data.sistemaAvaliacao;
    //   this.impressao.valores = data.valores;
    //   this.impressao.dataEmissao = data.impresso.UltimaImpressao;

    //   this.impressao.pai = (data.pai != null) ? {
    //     serie: data.pai.SERIE,
    //     nomecaracteristica: data.pai.nomecaracteristica,
    //     rgn: data.pai.RGN,
    //     rgd: data.pai.RGD,
    //     nome: data.pai.NOME,
    //     mgte: data.pai.MGT,
    //     top: data.pai.TOP,
    //     cat: data.pai.CAT,
    //     raca: data.pai.raca ? data.pai.raca.DS_RACA + ' ' + (data.pai.variedade ? data.pai.variedade.DS_VARIEDADE : '') : '-'
    //   } : null;

    //   this.impressao.mae = (data.mae != null) ? {
    //     serie: data.mae.SERIE,
    //     rgn: data.mae.RGN,
    //     nomecaracteristica: data.mae.nomecaracteristica,
    //     rgd: data.mae.RGD,
    //     nome: data.mae.NOME,
    //     mgte: data.mae.MGT,
    //     top: data.mae.TOP,
    //     cat: data.mae.CAT,
    //     raca: data.mae.raca ? data.mae.raca.DS_RACA + ' ' + (data.mae.variedade ? data.mae.variedade.DS_VARIEDADE : '') : '-'
    //   } : null;

    //   this.impressao.avoPaterno = (data.paiPai != null) ? {
    //     serie: data.paiPai.SERIE,
    //     rgn: data.paiPai.RGN,
    //     rgd: data.paiPai.RGD,
    //     nomecaracteristica: data.paiPai.nomecaracteristica,
    //     nome: data.paiPai.NOME,
    //     mgte: data.paiPai.MGT,
    //     top: data.paiPai.TOP,
    //     cat: data.paiPai.CAT,
    //     raca: data.paiPai.raca ? data.paiPai.raca.DS_RACA : '-'
    //   } : null;

    //   this.impressao.avomPaterno = (data.maePai != null) ? {
    //     serie: data.maePai.SERIE,
    //     rgn: data.maePai.RGN,
    //     rgd: data.maePai.RGD,
    //     nomecaracteristica: data.maePai.nomecaracteristica,
    //     nome: data.maePai.NOME,
    //     mgte: data.maePai.MGT,
    //     top: data.maePai.TOP,
    //     cat: data.maePai.CAT,
    //     raca: data.maePai.raca ? data.maePai.raca.DS_RACA : '-'
    //   } : null;

    //   this.impressao.avoMaterno = (data.paiMae != null) ? {
    //     serie: data.paiMae.SERIE,
    //     rgn: data.paiMae.RGN,
    //     rgd: data.paiMae.RGD,
    //     nome: data.paiMae.NOME,
    //     nomecaracteristica: data.paiMae.nomecaracteristica,
    //     mgte: data.paiMae.MGT,
    //     top: data.paiMae.TOP,
    //     cat: data.paiMae.CAT,
    //     raca: data.paiMae.raca ? data.paiMae.raca.DS_RACA : '-'
    //   } : null;

    //   this.impressao.avomMaterno = (data.maeMae != null) ? {
    //     serie: data.maeMae.SERIE,
    //     rgn: data.maeMae.RGN,
    //     rgd: data.maeMae.RGD,
    //     nomecaracteristica: data.maeMae.nomecaracteristica,
    //     nome: data.maeMae.NOME,
    //     mgte: data.maeMae.MGT,
    //     top: data.maeMae.TOP,
    //     cat: data.maeMae.CAT,
    //     raca: data.maeMae.raca ? data.maeMae.raca.DS_RACA : '-'
    //   } : null;

    //   this.showTable = false;
    //   this.app.loading = false;

    //   // var doc = new jsPDF();
    //   this.doc2.setFont("Verdana, Geneva, Tahoma, sans-serif");
    //   this.doc2.setFontSize(10);
    //   this.doc2.setFontStyle("bold");
    //   this.doc2.text("CEIP: ", 95, 40, null, null, "center");
    //   this.doc2.rect(100, 35, 30, 7);
    //   //doc.setFontStyle("normal");
    //   this.doc2.text(this.impressao.ceip, 110, 40, null, null, "center");
    //   this.doc2.rect(18, 45, 185, 17);
    //   this.doc2.setFontStyle("bold");
    //   this.doc2.text("Animal: ", 20, 50, null, null, "left");
    //   this.doc2.text("Data de Nascimento: ", 20, 55, null, null, "left");
    //   this.doc2.text("Base Genética Utilizada: ", 20, 60, null, null, "left");
    //   this.doc2.text("Raça ou Composição Racial: ", 115, 50, null, null, "left");
    //   this.doc2.text("Sexo: ", 115, 55, null, null, "left");
    //   this.doc2.text("Sistema de Avaliação: ", 115, 60, null, null, "left");
    //   this.doc2.setFontStyle("normal");
    //   this.doc2.text(`${this.impressao.animal.Serie ? this.impressao.animal.Serie : '---'} / ${this.impressao.animal.RGN ? this.impressao.animal.RGN : '---'} / ${this.impressao.animal.RGD ? this.impressao.animal.RGD : '---'} / ${this.impressao.animal.nome ? this.impressao.animal.nome : '---'}`, 33, 50, null, null, "left");
    //   this.doc2.text(`${this.datePipe.transform(this.impressao.animal.dataNascimento, 'dd/MM/yyyy')}`, 52, 55, null, null, "left");
    //   this.doc2.text(`${this.impressao.animal.baseGenetica}`, 58, 60, null, null, "left");
    //   this.doc2.text(`${this.impressao.animal.raca}`, 159, 50, null, null, "left");
    //   this.doc2.text(`${this.impressao.animal.sexo}`, 124, 55, null, null, "left");
    //   this.doc2.text(`${this.impressao.animal.sistemaAvaliacao}`, 149, 60, null, null, "left");

    //   this.doc2.setFontStyle("bold");
    //   this.doc2.setFontSize(8);
    //   if (this.animalSelecionado.Categoria == 'CL') {
    //     this.doc2.text(`D3P${this.impressao.temG ? 'G' : ''}`, 26, 66, null, null, "center");
    //     this.doc2.text(`MP120${this.impressao.temG ? 'G' : ''}`, 45, 66, null, null, "center");
    //     this.doc2.text(`MP210${this.impressao.temG ? 'G' : ''}`, 64, 66, null, null, "center");
    //     this.doc2.text(`DP210${this.impressao.temG ? 'G' : ''}`, 83, 66, null, null, "center");
    //     this.doc2.text(`DP450${this.impressao.temG ? 'G' : ''}`, 102, 66, null, null, "center");
    //     this.doc2.text(`DSTAY${this.impressao.temG ? 'G' : ''}`, 121, 66, null, null, "center");
    //     // doc.text(`DPE365${this.impressao.temG ? 'G' : ''}`, 127, 66, null, null, "center");
    //     this.doc2.text(`DPE450${this.impressao.temG ? 'G' : ''}`, 140, 66, null, null, "center");
    //     this.doc2.text(`DAOL${this.impressao.temG ? 'G' : ''}`, 159, 66, null, null, "center");
    //     this.doc2.text(`DACAB${this.impressao.temG ? 'G' : ''}`, 177, 66, null, null, "center");
    //     this.doc2.text(`IGE`, 195, 66, null, null, "center");
    //     this.doc2.rect(19, 67, 15, 8);
    //     this.doc2.rect(38, 67, 15, 8);
    //     this.doc2.rect(57, 67, 15, 8);
    //     this.doc2.rect(76, 67, 15, 8);
    //     this.doc2.rect(95, 67, 15, 8);
    //     this.doc2.rect(114, 67, 15, 8);
    //     // doc.rect(120, 67, 14, 8);
    //     this.doc2.rect(133, 67, 15, 8);
    //     this.doc2.rect(152, 67, 15, 8);
    //     this.doc2.rect(170, 67, 15, 8);
    //     this.doc2.setDrawColor(0);
    //     this.doc2.setFillColor(180, 180, 180);
    //     this.doc2.rect(188, 67, 15, 8, "F");
    //     this.doc2.setFontSize(10);
    //     // doc.setFontStyle("normal");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.D3P).toFixed(2)}`, 26, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.MP120).toFixed(2)}`, 45, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.MP210).toFixed(2)}`, 64, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.DP210).toFixed(2)}`, 83, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.DP450).toFixed(2)}`, 102, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.DSTAY).toFixed(2)}`, 121, 71, null, null, "center");
    //     // doc.text(`${parseFloat(this.impressao.valores.DPE365).toFixed(2)}`, 127, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.DPE450).toFixed(2)}`, 140, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.DAOL).toFixed(2)}`, 159, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.DACAB).toFixed(2)}`, 177, 71, null, null, "center");
    //     this.doc2.text(`${parseFloat(this.impressao.valores.MGT).toFixed(2)}`, 195, 71, null, null, "center");

    //     this.doc2.setFontSize(7);
    //     this.doc2.text(`TOP: ${this.impressao.valores.D3Ptop}%`, 27, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.MP120top}%`, 46, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.MP210top}%`, 65, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.DP210top}%`, 84, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.DP450top}%`, 103, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.DSTAYtop}%`, 122, 74, null, null, "center");
    //     // doc.text(`TOP: ${this.impressao.valores.DPE365top}%`, 127, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.DPE450top}%`, 141, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.DAOLtop}%`, 160, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.DACABtop}%`, 178, 74, null, null, "center");
    //     this.doc2.text(`TOP: ${this.impressao.valores.MGTtop}%`, 196, 74, null, null, "center");
    //   } else {
    //     this.doc2.text(`DIPP${this.impressao.temG ? 'G' : ''}`, 25, 66, null, null, "center");
    //     this.doc2.text(`D3P${this.impressao.temG ? 'G' : ''}`, 42, 66, null, null, "center");
    //     this.doc2.text(`MP120${this.impressao.temG ? 'G' : ''}`, 59, 66, null, null, "center");
    //     this.doc2.text(`MP210${this.impressao.temG ? 'G' : ''}`, 76, 66, null, null, "center");
    //     this.doc2.text(`DP210${this.impressao.temG ? 'G' : ''}`, 93, 66, null, null, "center");
    //     this.doc2.text(`DP450${this.impressao.temG ? 'G' : ''}`, 110, 66, null, null, "center");
    //     this.doc2.text(`DSTAY${this.impressao.temG ? 'G' : ''}`, 127, 66, null, null, "center");
    //     this.doc2.text(`DPE365${this.impressao.temG ? 'G' : ''}`, 145, 66, null, null, "center");
    //     this.doc2.text(`DPE450${this.impressao.temG ? 'G' : ''}`, 162, 66, null, null, "center");
    //     this.doc2.text(`DAOL${this.impressao.temG ? 'G' : ''}`, 179, 66, null, null, "center");
    //     this.doc2.text(`MGTe`, 196, 66, null, null, "center");
    //     this.doc2.rect(18, 67, 14, 8);
    //     this.doc2.rect(35, 67, 14, 8);
    //     this.doc2.rect(52, 67, 14, 8);
    //     this.doc2.rect(69, 67, 14, 8);
    //     this.doc2.rect(86, 67, 14, 8);
    //     this.doc2.rect(103, 67, 14, 8);
    //     this.doc2.rect(120, 67, 14, 8);
    //     this.doc2.rect(138, 67, 14, 8);
    //     this.doc2.rect(155, 67, 14, 8);
    //     this.doc2.rect(172, 67, 14, 8);
    //     this.doc2.setDrawColor(0);
    //     this.doc2.setFillColor(180, 180, 180);
    //     this.doc2.rect(189, 67, 14, 8, "F");
    //     this.doc2.setFontSize(10);
    //     // this.doc2.setFontStyle("normal");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.D3P).toFixed(2)}` : `${parseFloat(this.impressao.valores.DIPP).toFixed(2)}`, 25, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.MP120).toFixed(2)}` : `${parseFloat(this.impressao.valores.D3P).toFixed(2)}`, 42, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.MP210).toFixed(2)}` : `${parseFloat(this.impressao.valores.MP120).toFixed(2)}`, 59, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.DP210).toFixed(2)}` : `${parseFloat(this.impressao.valores.MP210).toFixed(2)}`, 76, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.DP450).toFixed(2)}` : `${parseFloat(this.impressao.valores.DP210).toFixed(2)}`, 93, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.DSTAY).toFixed(2)}` : `${parseFloat(this.impressao.valores.DP450).toFixed(2)}`, 110, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.DPE365).toFixed(2)}` : `${parseFloat(this.impressao.valores.DSTAY).toFixed(2)}`, 127, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.DPE450).toFixed(2)}` : `${parseFloat(this.impressao.valores.DPE365).toFixed(2)}`, 145, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.DAOL).toFixed(2)}` : `${parseFloat(this.impressao.valores.DPE450).toFixed(2)}`, 162, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.DACAB).toFixed(2)}` : `${parseFloat(this.impressao.valores.DAOL).toFixed(2)}`, 179, 71, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `${parseFloat(this.impressao.valores.MGT).toFixed(2)}` : `${parseFloat(this.impressao.valores.MGT).toFixed(2)}`, 196, 71, null, null, "center");

    //     this.doc2.setFontSize(7);
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.D3Ptop}%` : `TOP: ${this.impressao.valores.DIPPtop}%`, 25, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.MP120top}%` : `TOP: ${this.impressao.valores.D3Ptop}%`, 42, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.MP210top}%` : `TOP: ${this.impressao.valores.MP120top}%`, 59, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.DP210top}%` : `TOP: ${this.impressao.valores.MP210top}%`, 76, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.DP450top}%` : `TOP: ${this.impressao.valores.DP210top}%`, 93, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.DSTAYtop}%` : `TOP: ${this.impressao.valores.DP450top}%`, 110, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.DPE365top}%` : `TOP: ${this.impressao.valores.DSTAYtop}%`, 127, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.DPE450top}%` : `TOP: ${this.impressao.valores.DPE365top}%`, 145, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.DAOLtop}%` : `TOP: ${this.impressao.valores.DPE450top}%`, 162, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.DACABtop}%` : `TOP: ${this.impressao.valores.DAOLtop}%`, 179, 74, null, null, "center");
    //     this.doc2.text(this.animalSelecionado.Categoria == 'CL' ? `TOP: ${this.impressao.valores.MGTtop}%` : `TOP: ${this.impressao.valores.MGTtop}%`, 196, 74, null, null, "center");
    //   }


    //   this.doc2.setFontSize(10);
    //   this.doc2.setFontStyle("bold");
    //   this.doc2.text("Avós Paternos ", 45, 79, null, null, "left");
    //   this.doc2.rect(18, 80, 77, 10);
    //   this.doc2.text("Avós Maternos ", 150, 79, null, null, "left");
    //   this.doc2.rect(126, 80, 77, 10);
    //   this.doc2.setFontStyle("normal");
    //   this.doc2.setFontSize(9);
    //   if (this.impressao.avoPaterno == null) {
    //     this.doc2.text('---', 55, 88, null, null, "center");
    //   } else {
    //     this.doc2.text(`${this.impressao.avoPaterno.serie ? this.impressao.avoPaterno.serie : '---'} / ${this.impressao.avoPaterno.rgn ? this.impressao.avoPaterno.rgn : '---'} / ${this.impressao.avoPaterno.rgd ? this.impressao.avoPaterno.rgd : '---'} - ${this.impressao.avoPaterno.nome}`, 56, 84, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avoPaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avoPaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avoPaterno.top}%</p>`, 40, 81, null, null, "center");
    //   }

    //   if (this.impressao.avoMaterno == null) {
    //     this.doc2.text('---', 168, 88, null, null, "center");
    //   } else {
    //     this.doc2.setFontStyle("normal");
    //     this.doc2.setFontSize(9);
    //     this.doc2.text(`${this.impressao.avoMaterno.serie ? this.impressao.avoMaterno.serie : '---'} / ${this.impressao.avoMaterno.rgn ? this.impressao.avoMaterno.rgn : '---'} / ${this.impressao.avoMaterno.rgd ? this.impressao.avoMaterno.rgd : '---'} - ${this.impressao.avoMaterno.nome}`, 164, 84, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avoMaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avoMaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avoMaterno.top}%</p>`, 147, 81, null, null, "center");
    //   }

    //   this.doc2.setFontStyle("bold");
    //   this.doc2.setFontSize(10);
    //   this.doc2.text("Pai", 108, 89, null, null, "right");
    //   this.doc2.rect(32, 90, 76, 14);
    //   this.doc2.text("Mãe", 111, 89, null, null, "left");
    //   this.doc2.rect(110, 90, 76, 14);
    //   this.doc2.setFontStyle("normal");
    //   this.doc2.setFontSize(9);

    //   if (this.impressao.pai == null) {
    //     this.doc2.text('---', 72, 99, null, null, "center");
    //   } else {
    //     this.doc2.text(`${this.impressao.pai.serie ? this.impressao.pai.serie : '---'} / ${this.impressao.pai.rgn ? this.impressao.pai.rgn : '---'} / ${this.impressao.pai.rgd ? this.impressao.pai.rgd : '---'} - ${this.impressao.pai.nome}`, 70, 94, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.pai.nomecaracteristica}:</b> ${parseFloat(this.impressao.pai.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.pai.top}%</p>`, 56, 91, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.pai.raca}</p>`, 42, 95, null, null, "center");
    //   }

    //   if (this.impressao.mae == null) {
    //     this.doc2.text('---', 150, 99, null, null, "center");
    //   } else {
    //     this.doc2.setFontStyle("normal");
    //     this.doc2.setFontSize(9);
    //     this.doc2.text(`${this.impressao.mae.serie ? this.impressao.mae.serie : '---'} / ${this.impressao.mae.rgn ? this.impressao.mae.rgn : '---'} / ${this.impressao.mae.rgd ? this.impressao.mae.rgd : '---'} - ${this.impressao.mae.nome}`, 149, 94, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.mae.nomecaracteristica}:</b> ${parseFloat(this.impressao.mae.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.mae.top}%</p>`, 134, 91, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>Raça ou Composição Racial:</b> ${this.impressao.mae.raca}</p>`, 120, 95, null, null, "center");
    //   }


    //   this.doc2.rect(18, 104, 77, 10);
    //   this.doc2.rect(126, 104, 77, 10);
    //   this.doc2.setFontStyle("normal");
    //   this.doc2.setFontSize(9);
    //   if (this.impressao.avomPaterno == null) {
    //     this.doc2.text('---', 55, 111, null, null, "center");
    //   } else {
    //     this.doc2.text(`${this.impressao.avomPaterno.serie ? this.impressao.avomPaterno.serie : '---'} / ${this.impressao.avomPaterno.rgn ? this.impressao.avomPaterno.rgn : '---'} / ${this.impressao.avomPaterno.rgd ? this.impressao.avomPaterno.rgd : '---'} - ${this.impressao.avomPaterno.nome}`, 56, 108, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avomPaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avomPaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avomPaterno.top}%</p>`, 40, 105, null, null, "center");
    //   }

    //   if (this.impressao.avomMaterno == null) {
    //     this.doc2.text('---', 168, 111, null, null, "right");

    //   } else {
    //     this.doc2.setFontStyle("normal");
    //     this.doc2.setFontSize(9);
    //     this.doc2.text(`${this.impressao.avomMaterno.serie ? this.impressao.avomMaterno.serie : '---'} / ${this.impressao.avomMaterno.rgn ? this.impressao.avomMaterno.rgn : '---'} / ${this.impressao.avomMaterno.rgd ? this.impressao.avomMaterno.rgd : '---'} - ${this.impressao.avomMaterno.nome}`, 164, 108, null, null, "center");
    //     this.doc2.fromHTML(`<p style="font-size:12"><b>${this.impressao.avomMaterno.nomecaracteristica}:</b> ${parseFloat(this.impressao.avomMaterno.mgte).toFixed(2)} / <b>TOP: </b>${this.impressao.avomMaterno.top}%</p>`, 147, 105, null, null, "center");
    //   }


    //   this.doc2.rect(18, 115, 185, 19);
    //   this.doc2.text("________________________________________", 109, 125, null, null, "center");
    //   this.doc2.setFontStyle("italic");
    //   this.doc2.text(`${this.animalSelecionado.Categoria != 'CL' ? 'Raysildo Barbosa Lôbo' : 'Letícia Mendes de Castro'}`, 111, 129, null, null, "center");
    //   this.doc2.setFontStyle("bold");
    //   this.doc2.fromHTML(`<p style="font-size:10"><b>Responsável Técnico</b></p>`, 82, 126, null, null, "center");
    //   this.doc2.fromHTML(`<p style="font-size:10"><b>${this.animalSelecionado.Categoria != 'CL' ? 'CRMV: 4 - 1967' : 'CRMV: GO 04943'}</b></p>`, 115, 126, null, null, "center");
    //   // this.doc2.text("Responsável Técnico", 90, 136, null, null, "center");
    //   // this.doc2.text(`${this.animalSelecionado.Categoria != 'CL' ? 'CRMV/CREA: 4 - 1967' : 'CRMV: GO 04943'}`, 135, 136, null, null, "center");
    //   this.doc2.setFontSize(8);
    //   this.doc2.fromHTML(`<p style="font-size:10"><b>Data de Emissão: </b> ${this.datePipe.transform(this.impressao.dataEmissao, 'dd/MM/yyyy')}</p>`, 40, 130, null, null, "center");
    //   this.doc2.fromHTML(`<p style="font-size:10"><b>Local de Emissão: </b> Ribeirão Preto / SP</p>`, 135, 130, null, null, "center");
    //   // this.doc2.text(":", 50, 140, null, null, "center");
    //   // this.doc2.text("Local de Emissão:", 145, 140, null, null, "center");
    //   // this.doc2.setFontStyle("normal");
    //   // this.doc2.text(``, 70, 140, null, null, "center");
    //   // this.doc2.text("Ribeirão Preto / SP", 170, 140, null, null, "center");
    //   this.doc2.setFontSize(6);
    //   this.doc2.setFontStyle("bold");
    //   this.doc2.text("A RAÇA OU O PERCENTUAL DA COMPOSIÇÃO RACIAL DESTE ANIMAL SOMENTE SERÁ COMPROVADA POR MEIO DOS CERTIFICADOS DE REGISTRO GENEALÓGICO  ", 110, 141, null, null, "center");
    //   this.doc2.text("OU CERTIFICADO DE CONTROLE DE GENEALOGIA, EMITIDOS PELA RESPECTIVA ASSOCIAÇÃO DE CRIADORES DA RAÇA.", 110, 144, null, null, "center");
    //   //  
    //   contador = contador + 1;
    //   if (contador == total)
    //     this.doc2.save('certificado-ancp.pdf');
    //   else
    //     this.doc2.addPage();

    //   this.imprimirPorFazenda(fazendas, contador, total, contFazenda);

    // setTimeout(() => {
    //   const div = document.body.offsetHeight > 680 ? document.getElementById('contentToConvert') : document.getElementById('contentToConvertSmall');
    //   // const div = document.getElementById('contentToConvert');
    //   const options = {
    //     background: 'white',
    //     scale: 3
    //   };
    //   console.log('0', this.impressao);
    //   html2canvas(div, options).then((canvas) => {
    //     console.log('1', this.impressao);
    //     var img = canvas.toDataURL("image/PNG");
    //     // Add image Canvas to PDF
    //     const bufferX = 15;
    //     const bufferY = 35;
    //     const imgProps = (<any>this.doc2).getImageProperties(img);
    //     const pdfWidth = (this.doc2.internal.pageSize.getWidth() - 2 * bufferX) + bufferX;
    //     const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) + bufferY;
    //     if (contador == 0)
    //       this.doc2.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //     else {
    //       this.doc2.addPage();
    //       this.doc2.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
    //     }
    //     contador = contador + 1;
    //     return this.doc2;
    //   }).then((doc) => {
    //     if (contador == total)
    //       doc.save('certificado-ancp-' + nomeFazenda + '.pdf');

    //     this.imprimirPorFazenda(fazendas, contador, total, contFazenda);
    //   });
    // }, 12000)
    // })
  }


  buscarAnimais() {
    this.app.loading = true;
    this.animalService.retornarAnimaisSolicitadosCEIP().subscribe((data: any[]) => {
      this.animaisTotal = data;
      this.app.loading = false;
      this.animaisTotal.forEach(element => {

        if (element.DataVistoria != null && element.DataVistoria != undefined) {
          var dt = new Date(element.DataVistoria);
          dt.setHours(4);
          element.DataVistoria = dt;
        }

        if (element.DataSolicitacao != null && element.DataSolicitacao != undefined) {
          var dt = new Date(element.DataSolicitacao);
          dt.setHours(4);
          element.DataSolicitacao = dt;
        }

        if (element.DataNascimento != null && element.DataNascimento != undefined) {
          var dt = new Date(element.DataNascimento);
          dt.setHours(4);
          element.DataNascimento = dt;
          // element.DataNascimento = element.DataNascimento.substring(8,10) + '/' + element.DataNascimento.substring(5,7) + '/' + element.DataNascimento.substring(0,3);
        }

        if (element.UltimaImpressao != null && element.UltimaImpressao != undefined) {
          var dt = new Date(element.UltimaImpressao);
          dt.setHours(4);
          element.UltimaImpressao = dt;
        }

        element['checked'] = false;
        element['able'] = false;
        if (element.DataLimite != null && element.DataLimite != undefined) {
          var dt = new Date(element.DataLimite);
          dt.setHours(8);
          element.DataLimite = dt;
        }
        element['limite'] = element.DataLimite;
        // if (element.limite != undefined) {
        //   element.able = new Date() < element.limite;
        // }


        // const d = new Date(element.DataNascimento);
        // const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
        // const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);
        // element['dataFormatada'] = `${da}/${mo}/${ye}`;
        element['dataFormatada'] = element.DataNascimento;
      });

      this.gerenciarAnimais();
      //this.animais = data;
      this.animalSelecionado = this.animais[0];
    }, (erro) => {
      this.app.loading = false;
      alert('erro');
    })
  }

  public gerarRelatorioExcel(tipo): void {
    this.gerarRelatorioExcelAsync(tipo)
  }

  async gerarRelatorioExcelAsync(tipo) {

    try {
      this.app.loading = true;
      const data = await this.farmerService.accredited(tipo)
      const avaliacaoAnimal = await this.farmerService.getAnimaisAvaliados(tipo)

      const sheetProdutores = this.createSheetProdutores(data)
      const sheetDistribuicao = this.createSheetDistribuicao(data)
      const sheetAvaliacaoAnimal = this.createSheetCaracteristicas(avaliacaoAnimal)


      const wb: XLSX.WorkBook = { Sheets: { 'PRODUTORES': sheetProdutores, 'DISTRIBUICAO': sheetDistribuicao, 'AVALIACAO': sheetAvaliacaoAnimal }, SheetNames: ['PRODUTORES', 'DISTRIBUICAO', 'AVALIACAO'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, 'produtores_ceip.xlsx');
    } catch (error) {

    }
    finally {
      this.app.loading = false;
    }
  }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';



  public createSheetProdutores(data): XLSX.WorkSheet {

    const ws: XLSX.WorkSheet = {
      "!ref": "A1",
      "!cols": [
        {
          width: 8,
        },
        {
          width: 26
        },
        {
          width: 61
        },
      ],
      "!rows": [{ hpx: 20 },],
      A1: {
        t: 's', v: 'Listagem completa e atualizada com todos os produtores credenciados, em ordem de credenciamento',
      },
      "!merges": [
        { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } } /* A1:A3*/
      ]
    }

    XLSX.utils.sheet_add_aoa(ws, [
      ["UF", "Nº do Credenciamento CEIP", "Produtor Credenciado",]], { origin: { c: 0, r: 1 } })
    const produtores = data.map(i => { return { uf: i.ESTADO, credenciamento: i.N_CREDENCIAMENTO, produtor: i.PRODUTOR_CREDENCIADO } })
    XLSX.utils.sheet_add_json(ws, produtores, { origin: -1, skipHeader: true })

    return ws
  }

  public createSheetDistribuicao(data): XLSX.WorkSheet {

    const ws: XLSX.WorkSheet = {
      "!ref": "A1;A2;G2",
      "!cols": [
        {
          width: 26,
        },
        {
          width: 56
        },
        {
          width: 42
        },
        {
          width: 9
        }
      ],
      "!rows": [{ hpx: 30 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 30 }],
      A1: {
        t: 's', v: `Nº total de animais, machos e fêmeas avaliados, candidatos ao CEIP, certificados e
        percentagem de animais certificados no Projeto`,
      },
      A2: {
        t: 's', v: "Dados Totais"
      },
      D2: {
        t: "s", v: "Animais Avaliados"
      },
      G2: {
        t: "s", v: "Candidatos ao CEIP"
      },
      J2: {
        t: "s", v: "Animais Certificados"
      },
      M2: {
        t: "s", v: "Animais Certificados(%)"
      },
      A6: {
        t: "s", v: `Nº total de animais, machos e fêmeas avaliados, candidatos ao CEIP, certificados e
        percentagem de animais certificados no Projeto e por propriedade`
      },
      A7: {
        t: "s", v: "UF"
      },
      B7: {
        t: "s", v: "Nº do Credenciamento CEIP"
      },
      C7: {
        t: "s", v: "Produtor Credenciado"
      },
      D7: {
        t: "s", v: "Animais Avaliados"
      },
      G7: {
        t: "s", v: "Candidatos ao CEIP"
      },
      J7: {
        t: "s", v: "Animais Certificados"
      },
      M7: {
        t: "s", v: "Animais Certificados(%)"
      },
      "!merges": [ //coordenadas das celulas mescladas
        { s: { c: 0, r: 0 }, e: { c: 14, r: 0 } }, /* A1:O1 */
        { s: { c: 0, r: 1 }, e: { c: 2, r: 3 } }, /* A2:C4 */
        { s: { c: 3, r: 1 }, e: { c: 5, r: 1 } }, /* D2:F2 */
        { s: { c: 6, r: 1 }, e: { c: 8, r: 1 } }, /* G2:I2 */
        { s: { c: 9, r: 1 }, e: { c: 11, r: 1 } }, /* J2:L2 */
        { s: { c: 12, r: 1 }, e: { c: 14, r: 1 } }, /* M2:O2 */
        { s: { c: 0, r: 5 }, e: { c: 14, r: 5 } }, /* A6:O6 */
        { s: { c: 0, r: 6 }, e: { c: 0, r: 7 } }, /* A6:A7 */
        { s: { c: 1, r: 6 }, e: { c: 1, r: 7 } }, /* B6:B7 */
        { s: { c: 2, r: 6 }, e: { c: 2, r: 7 } }, /* C6:C7 */
        { s: { c: 3, r: 6 }, e: { c: 5, r: 6 } }, /* D2:F2 */
        { s: { c: 6, r: 6 }, e: { c: 8, r: 6 } }, /* G2:I2 */
        { s: { c: 9, r: 6 }, e: { c: 11, r: 6 } }, /* J2:L2 */
        { s: { c: 12, r: 6 }, e: { c: 14, r: 6 } }, /* M2:O2 */
      ]
    }

    //calcula e insere os totais
    const totais = new Array(12).fill(0);

    const mapped = data.map(e => {
      totais[0] += e.ANIMAIS
      totais[1] += e.ANIMAIS_MACHOS
      totais[2] += e.ANIMAIS_FEMEAS
      totais[3] += e.ANIMAIS_CANDIDATOS
      totais[4] += e.ANIMAIS_CANDIDATOS_MACHOS
      totais[5] += e.ANIMAIS_CANDIDATOS_FEMEAS
      totais[6] += e.ANIMAIS_CERTIFICADOS
      totais[7] += e.ANIMAIS_CERTIFICADOS_MACHOS
      totais[8] += e.ANIMAIS_CERTIFICADOS_FEMEAS

      //já monta obj de resultados
      const r = {
        estado: e.ESTADO,
        credenciamento: e.N_CREDENCIAMENTO,
        produtor: e.PRODUTOR_CREDENCIADO,
        animais: e.ANIMAIS,
        animaisMachos: e.ANIMAIS_MACHOS,
        animaisFemeas: e.ANIMAIS_FEMEAS,
        candidatos: e.ANIMAIS_CANDIDATOS,
        candidatosMachos: e.ANIMAIS_CANDIDATOS_MACHOS,
        candidatosFemeas: e.ANIMAIS_CANDIDATOS_FEMEAS,
        cert: e.ANIMAIS_CERTIFICADOS,
        certMachos: e.ANIMAIS_CERTIFICADOS_MACHOS,
        certFemeas: e.ANIMAIS_CERTIFICADOS_FEMEAS,
        percAnimais: "",
        percMachos: "",
        percFemeas: "",
      }

      if (r.candidatos && r.cert) {
        r.percAnimais += (r.cert / r.candidatos).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      }
      if (r.certMachos && r.candidatosMachos) {
        r.percMachos += (r.certMachos / r.candidatosMachos).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      }
      if (r.certFemeas && r.candidatosFemeas) {
        r.percFemeas += (r.certFemeas / r.candidatosFemeas).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      }

      return r;
    })

    //obtem porcentagem de certificados totais
    if (totais[3] && totais[6]) {
      totais[9] += (totais[3] / totais[6]).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
    if (totais[4] && totais[7]) {
      totais[10] += (totais[4] / totais[7]).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }
    if (totais[5] && totais[8]) {
      totais[11] += (totais[5] / totais[8]).toLocaleString('pt-BR', { minimumFractionDigits: 2 })
    }

    XLSX.utils.sheet_add_aoa(ws, [
      ["Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea"],
      totais],
      { origin: { c: 3, r: 2 } })
    XLSX.utils.sheet_add_aoa(ws, [["Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea", "Total", "Macho", "Femea"]],
      { origin: { c: 3, r: 7 } })

    //insere as informações
    XLSX.utils.sheet_add_json(ws, mapped, { origin: { c: 0, r: 8 }, skipHeader: true })

    return ws;

    // const wb: XLSX.WorkBook = { Sheets: { 'GERAL': ws }, SheetNames: ['GERAL'] };
    // const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // this.saveExcelFile(excelBuffer, 'distribuicao_animais_ceip.xlsx');
  }

  public createSheetCaracteristicas(data): XLSX.WorkSheet {

    const ws: XLSX.WorkSheet = {
      "!ref": "A1;A2;G2;E2;G2;C3;E3;G3;I3;K3M3;O3",
      "!cols": [
        {
          width: 31,
        },
      ],
      "!rows": [{ hpx: 31 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 15 }, { hpx: 30 }],
      A1: {
        t: 's', v: `Média das DEP e do índice específico de cada Projeto para o total de animais avaliados, total
        de animais certificados e machos e fêmeas certificados`,
        s: { foreground: [{ color: "#0055dd" }] }
      },
      B2: {
        t: 's',
        v: 'DEP'
      },
      A5: {
        t: 's',
        v: 'Dados Totais'
      },
      C2: {
        t: 's',
        v: '   Animais Avaliados'
      },
      E2: {
        t: 's',
        v: '   Candidatos ao CEIP'
      },
      K2: {
        t: 's',
        v: '   Animais Certificados'
      },
      C3: { t: 's', v: 'Total' },
      E3: { t: 's', v: 'Total' },
      G3: { t: 's', v: 'Machos' },
      I3: { t: 's', v: 'Femeas' },
      K3: { t: 's', v: 'Total' },
      M3: { t: 's', v: 'Machos' },
      O3: { t: 's', v: 'Femeas' },
      "!merges": [ //coordenadas das celulas mescladas
        { s: { c: 0, r: 0 }, e: { c: 15, r: 0 } }, /* A1:P1 */
        { s: { c: 0, r: 1 }, e: { c: 0, r: 3 } }, /* A2:A4 */
        { s: { c: 1, r: 1 }, e: { c: 1, r: 3 } }, /* A2:A4 */
        { s: { c: 0, r: 4 }, e: { c: 0, r: 14 } },
        { s: { c: 2, r: 1 }, e: { c: 3, r: 1 } },
        { s: { c: 4, r: 1 }, e: { c: 9, r: 1 } },
        { s: { c: 10, r: 1 }, e: { c: 15, r: 1 } },
        { s: { c: 2, r: 2 }, e: { c: 3, r: 2 } },
        { s: { c: 4, r: 2 }, e: { c: 5, r: 2 } },
        { s: { c: 6, r: 2 }, e: { c: 7, r: 2 } },
        { s: { c: 8, r: 2 }, e: { c: 9, r: 2 } },
        { s: { c: 10, r: 2 }, e: { c: 11, r: 2 } },
        { s: { c: 12, r: 2 }, e: { c: 13, r: 2 } },
        { s: { c: 14, r: 2 }, e: { c: 15, r: 2 } },
      ]
    }

    XLSX.utils.sheet_add_aoa(ws, [['Media', 'Desvio', 'Media', 'Desvio', 'Media', 'Desvio', 'Media', 'Desvio', 'Media', 'Desvio', 'Media', 'Desvio', 'Media', 'Desvio',]], { origin: { c: 2, r: 3 }, })
    //insere as informações
    XLSX.utils.sheet_add_json(ws, data['animaisAvaliados'], { origin: { c: 1, r: 4 }, skipHeader: true })
    XLSX.utils.sheet_add_aoa(ws, data['avaliados_total'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 4, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['avaliados_machos'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 6, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['avaliados_femeas'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 8, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['certificados_total'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 10, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['certificados_machos'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 12, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, data['certificados_femeas'].map(i => [i['Media'], i['Desvio']]), { origin: { c: 14, r: 4 } })
    XLSX.utils.sheet_add_aoa(ws, [[' *Mérito Genético Total Econômico']], { origin: -1 })
    return ws
  }



}
