import { Component, OnInit } from '@angular/core';
import { ApiService } from '../app.api-service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from '../app.component';
import { UserService } from '../services/user-service';

@Component({
  selector: 'app-usuario-confirmacao',
  templateUrl: './usuario-confirmacao.component.html',
  styleUrls: ['./usuario-confirmacao.component.css']
})
export class UsuarioConfirmacaoComponent implements OnInit {

  div: number = 0;
  confirmCodeDiv: boolean = false;
  userLogin: any = {
    name: '',
    email: '',
    password: ''
  };
  error = '';
  success = '';
  errorAccount = '';
  token: string = '';
  email: string = '';

  constructor(private userService: UserService, private router: Router, private app: AppComponent, private route: ActivatedRoute) {
    
    localStorage.removeItem('ancp-net-token');

    this.route.params.subscribe(params => this.token = params['token']);
    this.route.params.subscribe(params => this.email = params['email']);
    
    if (!this.token || !this.email){
      alert('Faça login no ANCP Net');
        window.location.href = 'http://ancp.org.br';
    }else{
      this.authenticateCode()
    }

  }

  ngOnInit() {
    this.app.isPrivate = false;
  }

  authenticateCode(){
    this.app.loading = true;
    console.log(this.email);
    console.log(this.token);
    this.userService.authenticateCode(this.email, this.token).subscribe((data: any) => {
      this.app.loading = false;
      if (data.status){
        localStorage.setItem('ancp-net-token', JSON.stringify({email: this.email, token: this.token}));
        setTimeout(() => {
          this.router.navigateByUrl(data.modulo ? data.modulo : '/');
        }, 1000)
      }else{
        alert('Faça login no ANCP Net');
        window.location.href = 'http://ancp.org.br';
      }
    })
  }

}
