import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { UserService } from '../services/user-service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  private: any;
  dashboard: any;

  constructor(private app: AppComponent, private router: Router, private admin: AdminComponent, private userService: UserService) {
    this.app.isPrivate = true;
  }

  ngOnInit() {
    this.admin.loadModules();
    this.getDashboard();
  }

  getPrivate() {
    return this.app.isPrivate;
  }

  getDashboard() {
    this.userService.getDashboard().subscribe((data: any) => {
      this.dashboard = data;
    })
  }
}
