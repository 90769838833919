import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";

@Injectable()
export class ConfigService {

  constructor(private http: HttpClient, private app: AppComponent) {

  }

  /*  */
  save(api, object): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    if (user)
      return this.http.post(this.app.URL+"/api/"+api, object, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL+"/api/not-found");
  }

  getAll(api): Observable<any> {
    var user = JSON.parse(localStorage.getItem('ancp-authentication'));
    var token = localStorage.getItem('ancp-token');
    if (user)
      return this.http.get(this.app.URL+"/api/"+api, {
        headers: new HttpHeaders({
          "authorization": token
        })
      });
    else
      return this.http.get(this.app.URL+"/api/not-found");
  }

}
