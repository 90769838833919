import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { FarmerService } from '../services/farmer-service';
import { UserService } from '../services/user-service';
import { ConfigService } from '../services/config-service';
@Component({
  selector: 'app-manutencao-online-animais',
  templateUrl: './manutencao-online-animais.component.html',
  styleUrls: ['./manutencao-online-animais.component.css']
})
export class ManutencaoOnlineAnimaisComponent implements OnInit {

  /* general configs */
  user: any;  
  error: string;
  success: string;
  tab: string = '';
  showList: boolean = false;
  status: any[] = [{key: true, value: 'Ativo'},{key: false, value: 'Inativo'}];

  constructor(private app: AppComponent, private router: Router, private admin: AdminComponent, 
    private configService: ConfigService, private userService: UserService) {
    this.app.isPrivate = true;
  }
  ngOnInit() {
    
    this.getCombos();
    this.getCronogramas();
    this.getFonteIndicacoes();
    this.getParceiros();
    this.getRacas();
    this.getSoftwares();
    this.getTipoEnderecos();

    // this.admin.loadModules();
    this.admin._page = 'Manutenção Online de Animais';
  }

  /* Racas */
  raca: any = {
    name: '',
    status: false
  }
  racas: any[] = [];

  getRacas(){
    this.configService.getAll('racas').subscribe((data: any) => {
      this.racas = data.data;
      this.showList = true;
    })
  }

  saveRaca(){
    this.configService.save('racas', this.raca).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else
        this.getRacas();
    })
  }

  cleanRaca(){
    this.raca = {
      name: '',
      status: false
    }
  }

  openDetailsRaca(raca){
    this.raca = raca;
    this.showList = false;
  }

  /* Softwares */
  software: any = {
    name: '',
    status: false
  }
  softwares: any[] = [];

  getSoftwares(){
    this.configService.getAll('softwares').subscribe((data: any) => {
      this.softwares = data.data;
      this.showList = true;
    })
  }

  saveSoftware(){
    this.configService.save('softwares', this.software).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else
        this.getSoftwares();
    })
  }

  cleanSoftware(){
    this.software = {
      name: '',
      status: false
    }
  }

  openDetailsSoftware(software){
    this.software = software;
    this.showList = false;
  }

  /* FonteIndicacaos */
  fonteIndicacao: any = {
    name: '',
    status: false
  }
  fonteIndicacoes: any[] = [];
  
  getFonteIndicacoes(){
    this.configService.getAll('fonteIndicacoes').subscribe((data: any) => {
      this.fonteIndicacoes = data.data;
      this.showList = true;
    })
  }

  saveFonteIndicacao(){
    this.configService.save('fonteIndicacoes', this.fonteIndicacao).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else
        this.getFonteIndicacoes();
    })
  }

  cleanFonteIndicacao(){
    this.fonteIndicacao = {
      name: '',
      status: false
    }
  }

  openDetailsFonteIndicacao(fonteIndicacao){
    this.fonteIndicacao = fonteIndicacao;
    this.showList = false;
  }

  /* Parceiros */
  parceiro: any = {
    name: '',
    status: false
  }
  parceiros: any[] = [];
  
  getParceiros(){
    this.configService.getAll('parceiros').subscribe((data: any) => {
      this.parceiros = data.data;
      this.showList = true;
    })
  }

  saveParceiro(){
    this.configService.save('parceiros', this.parceiro).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else
        this.getParceiros();
    })
  }

  cleanParceiro(){
    this.parceiro = {
      name: '',
      status: false
    }
  }

  openDetailsParceiro(parceiro){
    this.parceiro = parceiro;
    this.showList = false;
  }

  /* TipoEnderecos */
  tipoEndereco: any = {
    name: '',
    status: false
  }
  tipoEnderecos: any[] = [];
  
  getTipoEnderecos(){
    this.configService.getAll('tipoEnderecos').subscribe((data: any) => {
      this.tipoEnderecos = data.data;
      this.showList = true;
    })
  }

  saveTipoEndereco(){
    this.configService.save('tipoEnderecos', this.tipoEndereco).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else
        this.getTipoEnderecos();
    })
  }

  cleanTipoEndereco(){
    this.tipoEndereco = {
      name: '',
      status: false
    }
  }

  openDetailsTipoEndereco(tipoEndereco){
    this.tipoEndereco = tipoEndereco;
    this.showList = false;
  }

  /* Cronogramas */
  cronograma: any = {
    name: '',
    status: false
  }
  cronogramas: any[] = [];
  
  getCronogramas(){
    this.configService.getAll('cronogramas').subscribe((data: any) => {
      this.cronogramas = data.data;
      this.showList = true;
    })
  }

  saveCronograma(){
    this.configService.save('cronogramas', this.cronograma).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else
        this.getCronogramas();
    })
  }

  cleanCronograma(){
    this.cronograma = {
      name: '',
      status: false
    }
  }

  openDetailsCronograma(cronograma){
    this.cronograma = cronograma;
    this.showList = false;
  }

  /* Combos */
  combo: any = {
    name: '',
    status: false
  }
  combos: any[] = [];

  getCombos(){
    this.configService.getAll('combos').subscribe((data: any) => {
      this.combos = data.data;
      this.showList = true;
    })
  }

  saveCombo(){
    this.configService.save('combos', this.combo).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else
        this.getCombos();
    })
  }

  cleanCombo(){
    this.combo = {
      name: '',
      status: false
    }
  }

  openDetailsCombo(combo){
    this.combo = combo;
    this.showList = false;
  }
}
