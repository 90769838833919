import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { ApiService } from '../app.api-service';
import { Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { UserService } from '../services/user-service';
import { ModuleService } from '../services/module-service';

@Component({
  selector: 'app-acesso',
  templateUrl: './acesso.component.html',
  styleUrls: ['./acesso.component.css']
})
export class AcessoComponent implements OnInit {

  private: any;
  user: any;
  users: any[];
  modules: any[];
  error: string;
  success: string;

  constructor(private app: AppComponent, private router: Router, private admin: AdminComponent, 
    private userService: UserService, private moduleService: ModuleService) {
    this.app.isPrivate = true;
  }

  ngOnInit() {
    this.getAllUsers();
    this.getAllModules();
    this.admin.loadModules();
    this.admin._page = 'Acessos por Usuário';
  }

  findUser(id) {
    var _user = this.users.filter(u => {
      return u._id == id;
    })
    this.user = _user[0];
    this.error = '';
    this.success = '';
  }

  getAllUsers() {
    this.userService.getAll().subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else {
        this.users = data.list;
      }
    })
  }

  getAllModules() {
    this.moduleService.getAll().subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else {
        this.modules = data.list;
      }
    })
  }

  getModulesByUserID() {
    if (!this.user) {
      this.error = 'You need to select a user';
    } else {

      this.moduleService.getModulesByUserID(this.user._id).subscribe((data: any) => {
        if (!data.status)
          this.error = data.message;
        else {
          this.error = '';
          this.success = '';
          this.modules.forEach(item => {
            item['checked'] = false;
            var moduleFound = data.list.filter(mod => {
              return mod._id == item._id;
            })
            if (moduleFound.length > 0)
              item['checked'] = true;
          })
        }
      })
    }
  }

  saveModules() {
    var modulesChecked = this.modules.filter(mod => {
      return mod.checked;
    });

    this.moduleService.saveModules(this.user._id, modulesChecked.map(mod => { return mod._id; })).subscribe((data: any) => {
      if (data.status) {
        this.error = '';
        this.success = data.message;
        this.user = undefined;

      } else {
        this.error = data.message;
      }
    });
  }
}
