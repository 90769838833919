import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { AdminComponent } from '../admin/admin.component';
import { FarmerService } from '../services/farmer-service';
import { UserService } from '../services/user-service';
import { ConfigService } from '../services/config-service';
import { LogService } from '../services/log-service';
import { HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-fazenda',
  templateUrl: './fazenda.component.html',
  styleUrls: ['./fazenda.component.css']
})
export class FazendaComponent implements OnInit {

  racas: any[];
  softwares: any[];
  fontesIndicacoes: any[];
  parceiros: any[];
  tiposEnderecos: any[];
  cronogramas: any[];
  combos: any[];

  private: any;
  user: any;
  userSelected: any;
  users: any[];
  farmers: any[];
  logs: any[];
  actions: any[];

  errorFilter: string;
  error: string;
  success: string;
  tab: string = 'Filtro';
  showList: boolean = true;

  statusFilter: any[] = [
    {
      key: 1,
      value: 'Aguardando Aprovação'
    },
    {
      key: 2,
      value: 'Ativo'
    },
    {
      key: 3,
      value: 'Inativo'
    }
  ]

  filter: any = {
    nfa: '',
    atividade: '',
    criador: '',
    nome: '',
    racaId: '0',
    tipoEnderecoId: '0',
    cidade: '',//
    uf: '',//
    pais: '',//
    ordenarPor: '0',//
    cronogramaId: '0',
    parceiroId: '0',
    ingressoDe: '',
    ingressoAte: '',
    implantacaoDe: '',
    implantacaoAte: '',
    saidaDe: '',
    saidaAte: '',
    tempoSemImplantacao: '',//
    status: '0'
  }

  farmer: any = {
    nfa: '',
    dataAtualizacao: '',
    dataIngresso: '',
    dataImplantacao: '',
    dataSaida: '',
    razaoSocial: '',
    nomeFantasia: '',
    cpf: '',
    cnpj: '',
    cep: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    site: '',
    indicacao: '',
    ownerId: '',
    softwareId: null,
    fonteIndicacaoId: null,
    parceiroId: null,
    tipoEnderecoId: null,
    cronogramaId: null,
    comboId: null,
    racas: '',
    responsaveis: '',
    addresses: []
  }

  address = {
    tipoEnderecoId: '0',
    cidade: '',
    cep: '',
    estado: '',
    cxPostal: '',
    endereco: '',
    coordenadas: '',
    pais: '',
    fax: '',
    bairro: '',
    telefone: ''
  }

  //NEW
  selectedFile: File = null;
  fd = new FormData();

  createFormData(event) {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    if (this.selectedFile != null){
      let formData = new FormData();
      const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
      formData.append('file', this.selectedFile, this.farmer._id + '.jpg');
  
     
        this.farmerService.uploadImage(formData, this.farmer._id).subscribe(result => {
          if (result.status) {
            this.getFarmers();
          }
        });
    }
    
  }
  //ENDNEW

  constructor(private app: AppComponent, private router: Router, private admin: AdminComponent,
    private farmerService: FarmerService, private userService: UserService, private configService: ConfigService,
    private logService: LogService) {
    this.app.isPrivate = true;
  }

  ngOnInit() {
    this.getCombos();
    this.getCronogramas();
    this.getFontesIndicacoes();
    this.getParceiros();
    this.getRacas();
    this.getSoftwares();
    this.getTiposEnderecos();

    this.getAllUsers();
    this.getFarmers();
    this.getNfa();
    this.admin.loadModules();
    this.admin._page = 'Fazendas';
  }

  filterFarmer() {
    this.farmerService.filter(this.filter).subscribe((data: any) => {
      if (data.status) {
        this.farmers = data.list;
        this.tab = 'Fazendas Filtradas';
      } else {
        this.errorFilter = data.message;
      }
    })
  }

  cleanAddress() {
    this.address = {
      tipoEnderecoId: '0',
      cidade: '',
      cep: '',
      estado: '',
      cxPostal: '',
      endereco: '',
      coordenadas: '',
      pais: '',
      fax: '',
      bairro: '',
      telefone: ''
    }
  }

  saveAddress() {
    //NEW
    this.address['tipoEndereco'] = this.tiposEnderecos.filter(tipo => {
      return tipo._id == this.address.tipoEnderecoId;
    });
    //ENDNEW
    this.farmer.addresses.push(this.address);
    this.cleanAddress();

  }

  findUser(id) {
    var _user = this.users.filter(u => {
      return u._id == id;
    });
    this.user = _user.length > 0 ? _user[0] : 0;
    this.error = '';
    this.success = '';
  }

  getAllUsers() {
    this.userService.getAll().subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else {
        this.users = data.list;
      }
    });
  }

  getActionsByFarmerId() {
    this.logService.getByFarmerId(this.farmer._id).subscribe((data: any) => {
      this.actions = data.list;
    })
  }

  getFarmers() {
    this.farmerService.getAll().subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else {
        this.farmers = data.list;
      }
    })
  }

  getFarmersByUserID() {
    if (!this.user) {
      this.error = 'Você precisa selecionar um usuário';
    }
    else {
      this.farmerService.getFarmersByUserID(this.user._id).subscribe((data: any) => {
        if (!data.status)
          this.error = data.message;
        else {
          this.error = '';
          this.success = '';
          this.farmers.forEach(item => {
            item['checked'] = false;
            var farmerFound = data.list.filter(far => {
              return far._id == item._id;
            })
            if (farmerFound.length > 0)
              item['checked'] = true;
          })
        }
      })
    }
  }

  getUsersByFarmerID() {
    this.farmerService.getUsersByFarmerID(this.farmer._id).subscribe((data: any) => {
      if (!data.status)
        this.error = data.message;
      else {
        this.error = '';
        this.success = '';
        this.farmer.responsaveis = data.list;
      }
    })
  }

  saveUser() {
    this.userSelected = this.users.filter(u => {
      return u._id == this.userSelected;
    })
    this.farmer.responsaveis.push(this.userSelected[0]);
  }

  removeUser(userId) {
    var users = [];
    this.farmer.responsaveis.forEach(us => {
      if (us._id != userId)
        users.push(us);
    });
    this.farmer.responsaveis = users;
  }

  changeStatus(farmer, status) {
    farmer.status = status;
    farmer.isAdmin = true;//NEW
    this.farmerService.save(farmer).subscribe((data: any) => {
      if (data.status) {
        this.showList = true;
      } else {
        this.error = data.message;
      }
    });
  }

  changeDetailsFarmer(newFarmer) {

    this.farmerService.changeDetailsFarmer(newFarmer).subscribe((data: any) => {
      if (data.status) {
        this.getFarmers();
        this.showList = true;
        this.success = data.message;
      } else {
        this.error = data.message;
      }
    })
  }

  cancelChangeDetails(removeEdit) {
    this.farmerService.cancelEdit(removeEdit._id).subscribe((data: any) => {
      if (data.status) {
        this.error = '';
        this.success = data.message;
        this.showList = true;
        this.getFarmers();
      } else {
        this.success = '';
        this.error = data.message;
      }
    })
  }

  saveFarmersUsers() {
    var farmersChecked = this.farmers.filter(far => {
      return far.checked;
    });
    this.farmerService.saveFarmersToUser(this.user._id, farmersChecked.map(mod => { return mod._id; })).subscribe((data: any) => {
      if (data.status) {
        this.error = '';
        this.success = data.message;
        this.user = undefined;
        this.tab = 'Gerenciar Fazendas';
        setTimeout(() => {
          this.tab = 'Gerenciar Fazendeiros';
        }, 100);
      } else {
        this.error = data.message;
      }
    });
  }

  save() {
    if (Array.isArray(this.racas)) {
      let _racasChecked = this.racas.filter(ra => {
        return ra.checked;
      });
      this.farmer.racas = _racasChecked.map(mod => { return mod._id; });
    }


    this.farmer.responsaveis = this.farmer.responsaveis.map(mod => { return mod._id; });
    this.farmer.isAdmin = true;
    this.farmerService.save(this.farmer).subscribe((data: any) => {
      if (data.status) {
        this.upload();
        this.error = '';
        this.success = data.message;
        this.showList = true;
        this.cleanFarmer();
        this.getFarmers();
      } else {
        this.error = data.message;
      }
    })
  }

  cleanFarmer() {
    this.farmer = {
      dataAtualizacao: '',
      dataIngresso: '',
      dataImplantacao: '',
      dataSaida: '',
      razaoSocial: '',
      nomeFantasia: '',
      cpf: '',
      cnpj: '',
      cep: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      site: '',
      indicacao: '',
      ownerId: '',
      softwareId: null,
      fonteIndicacaoId: null,
      parceiroId: null,
      tipoEnderecoId: null,
      cronogramaId: null,
      comboId: null,
      racas: '',
      responsaveis: '',
      addresses: []
    }
    this.getNfa();
    this.racas.forEach(raca => {
      raca['checked'] = false;
    });
    this.logs = [];
  }

  getLogsFarmers() {
    this.farmerService.getPendingsByFarmerID(this.farmer._id).subscribe((data: any) => {
      this.logs = data.list;
    })
  }

  openDetails(farmer) {
    this.farmer = farmer;
    this.farmer.ownerId = this.farmer.ownerId._id;
    this.farmer.dataIngresso = this.farmer.dataIngresso ? this.farmer.dataIngresso.slice(0,10) : '';
    this.farmer.dataAtualizacao = this.farmer.dataAtualizacao ? this.farmer.dataAtualizacao.slice(0,10) : '';
    this.farmer.image = this.farmer.image ? (this.app.URL + '/' + this.farmer.image).replace('\/public', '') : '';
    this.showList = false;
    this.racas.forEach(ra1 => {
      farmer.racas.forEach(ra2 => {
        if (ra1._id == ra2)
          ra1.checked = true;
      });
    });

    //NEW
    this.farmer.addresses.forEach(addr => {
      addr.tipoEndereco = this.tiposEnderecos.filter(tipo => {
        return tipo._id == addr.tipoEnderecoId;
      })
    });
    //ENDNEW

    this.success = '';
    this.error = '';
    this.getLogsFarmers();
    this.getUsersByFarmerID();
    this.getActionsByFarmerId();
  }

  getRacas() {
    this.configService.getAll('racas').subscribe((data: any) => {
      this.racas = data.data;
      this.racas.forEach(raca => {
        raca['checked'] = false;
      });
    })
  }

  getSoftwares() {
    this.configService.getAll('softwares').subscribe((data: any) => {
      this.softwares = data.data;
    })
  }

  getFontesIndicacoes() {
    this.configService.getAll('fonteIndicacoes').subscribe((data: any) => {
      this.fontesIndicacoes = data.data;
    })
  }

  getParceiros() {
    this.configService.getAll('parceiros').subscribe((data: any) => {
      this.parceiros = data.data;
    })
  }

  getTiposEnderecos() {
    this.configService.getAll('tipoEnderecos').subscribe((data: any) => {
      this.tiposEnderecos = data.data;
    })
  }

  getCronogramas() {
    this.configService.getAll('cronogramas').subscribe((data: any) => {
      this.cronogramas = data.data;
    })
  }

  getCombos() {
    this.configService.getAll('combos').subscribe((data: any) => {
      this.combos = data.data;
    })
  }

  getNfa() {
    this.farmerService.getNfa().subscribe((data: any) => {
      this.farmer.nfa = data.data;
    })
  }
}
