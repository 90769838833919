import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { AppComponent } from "../app.component";

@Injectable()
export class LogService {

  constructor(private http: HttpClient, private app: AppComponent) {

  }

  getByFarmerId(farmerId): Observable<any> {
    return this.http.get(this.app.URL+"/api/farmers/"+farmerId+"/logs");    
  }

}
