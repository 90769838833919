import { Component, OnInit } from '@angular/core';
import { ApiService } from '../app.api-service';
import { Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { UserService } from '../services/user-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  div: number = 0;
  confirmCodeDiv: boolean = false;
  userLogin: any = {
    name: '',
    email: '',
    password: ''
  };
  error = '';
  success = '';
  errorAccount = '';
  user: any;
  isAdmin: boolean = false;

  constructor(private userService: UserService, private router: Router, private app: AppComponent) {

  }

  ngOnInit() {
    this.app.isPrivate = false;
    this.user = JSON.parse(localStorage.getItem('ancp-net-token'));
    if (this.user)
      this.checkAvaliador();
    else
      window.location.href = 'http://ancp.org.br';
  }

  sair(){
    localStorage.removeItem('ancp-net-token');
    window.location.href = 'http://ancp.org.br';
  }

  fake() {
    this.app.loading = true;
    setTimeout(() => {
      this.app.loading = false;
    }, 5000);
  }

  checkAdmin() {
    this.userService.checkAdmin(this.user.token).subscribe(data => {
      this.isAdmin = data;
      if (!this.isAdmin){        
        localStorage.setItem('ancp-isFazenda','true');
        this.router.navigateByUrl('/ceip');
      }
      
    }, err => {
      window.location.href = "http://www.ancp.org.br/";
    });
  }

  checkAvaliador() {
    this.app.loading = true;
    this.userService.checkAvaliador(this.user.token).subscribe(data => {
      this.app.loading = false;      
      if (data){
        localStorage.setItem('ancp-isAvaliador','true');
        this.router.navigateByUrl('/ceip-avaliador-fazenda');
      }        
      else
        this.checkAdmin();
    }, err => {
      this.app.loading = false;
      window.location.href = "http://www.ancp.org.br/";
    });
  }

  login() {
    this.app.loading = true;
    this.userService.signIn(this.userLogin).subscribe((data: any) => {
      this.app.loading = false;
      if (!data.status) {
        this.error = data.message;
        this.success = '';

      }
      else {
        localStorage.setItem('ancp-token', data.access_token);
        localStorage.setItem('ancp-user', data.objeto.name);
        localStorage.setItem('ancp-authentication', JSON.stringify({ token: data.access_token, user: data.objeto }));

        this.app.isPrivate = true;
        this.router.navigateByUrl('/dashboard');
      }
    }, (erro: any) => {
      this.app.loading = false;
      this.error = 'Error internal';
    })
  }

  rememberPassword() {
    if (!this.userLogin.email) {
      this.error = 'Email is required';
    } else {
      this.app.loading = true;
      this.userService.rememberPassword(this.userLogin.email).subscribe((data: any) => {
        if (!data.status) {
          this.error = data.message;
        } else {
          this.div = 1; this.confirmCodeDiv = true; this.error = '';
        }
        this.app.loading = false;
      }, (erro: any) => {
        this.app.loading = false;
        this.error = 'Error internal';
      })
    }
  }

  confirmatingCode() {
    if (!this.userLogin.code || !this.userLogin.password) {
      this.error = 'Code and Password are required';
    } else {
      this.app.loading = true;
      this.userService.confirmCode(this.userLogin).subscribe((data: any) => {
        this.app.loading = false;
        if (!data.status) {
          this.error = data.message;
        } else {
          this.div = 0; this.confirmCodeDiv = false; this.error = ''; this.userLogin.password = '';
          this.success = data.message;
        }
      }, (erro: any) => {
        this.app.loading = false;
        this.error = 'Error internal';
      })
    }
  }

  register() {
    this.userService.register(this.userLogin).subscribe((data: any) => {
      if (!data.status) {
        this.errorAccount = data.message;
      } else {
        this.userLogin.password = '';
        this.div = 0;
        this.success = 'Seu cadastro foi realizado com sucesso! Foi enviado um e-mail para você ativar seu cadastro.'
      }
    }, (erro: any) => {
      this.error = 'Error internal';
    })
  }

}
